

  /*-----Back to Top-----*/
  //@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700;800&display=swap');

  #back-to-top {
	color: $white;
	position: fixed;
	bottom: 20px;
	right: 30px;
	z-index: 999;
	display: none;
	text-align: center;
	border-radius: 5px;
	-o-border-radius: 2px;
	height: 40px;
	width: 40px;
	line-height: 2.8;
	background-repeat: no-repeat;
	background-position: center;
	transition: background-color 0.1s linear;
	box-shadow: $special-shadow;

	i {
	  font-size: 16px;
	}
  }

  .tabs-menu {
	.nav {
		display: inline-flex!important;
	}
  }

  .tabs-menu ul li {
	a {
	  padding: 5px 20px;
	  display: block;
	  margin: 3px;
	  border-radius: 4px;
	  font-size: 12px;
	  text-transform: uppercase;
	  width: 100px;
	}

	.active {
	  color: $white !important;
	  box-shadow: $special-shadow;
	}
  }


  .tabs-menu-body {
	padding: 15px;
	border-radius: 0 0 5px 5px;

	.price-card {
		border-radius: 0!important;
		border-top-right-radius: 30px!important;
		border-top-left-radius: 1rem!important;
		border-bottom-left-radius: 30px!important;
	}

	.price-card-border {
		border: 2px solid $primaryTextActiveColor!important;
	}

	.card {
		box-shadow: $special-shadow!important;
		transition: all 0.2s;

		&:hover, &:focus {

			box-shadow: 0 0 12px rgba(33,33,33,.2)!important;

			.plan-cost {
				color: #007bff;
			}

			.prepaid-plan {
				.plan-cost {
					color:rgb(102, 102, 102);
				}

				.plan-title {
					color: #007bff;
				}
			}
		}

		.plan {
			min-height: 380px;
		}

		.plan-featured {
			font-size: 12px;
			font-weight: 700;
			color: #fff;
			position: absolute;
			top: 0;
			left: 0;
			height: 25px;
			border-bottom-right-radius: 1rem;
			border-top-left-radius: 0.8rem;
			min-width: 40%;
			padding-top: 3px;
			text-align: center;
			background-color: $primaryTextActiveColor;
		}

		.plan-cost {
			font-size: 40px;
			font-weight: 800;
			color: rgb(102, 102, 102);
			font-family: 'Open Sans', sans-serif;
		}

		.plan-currency-sign {
			font-size: 20px;
			margin-bottom: 20px;
			position: absolute;
			top: 25px;
			color: #ccc;
			font-weight: 800;
			font-family: 'Poppins', sans-serif;
		}

		.divider {
			background-color: rgb(230, 230, 230);
			border-radius: 5px;
			height: 3px;
			width: 40px;
		}

		.plan-title {
			font-size: 24px;
			font-weight: 800;
			font-family: 'Open Sans';
			margin-bottom: 1rem;
		}

		ul li {
			margin-bottom: 0.3rem;

			i {
				margin-right: 0.3rem;
			}
		}

		.action-button {

			button {
				width: 145px;
			}

			a {
				width: 145px;
			}
		}

		.prepaid-plan {
			min-height: auto!important;

			.plan-title {
				margin-bottom: 0!important;
			}

			.plan-cost {
				float: right;
				padding-left: 2px!important;
				padding-right: 2px!important;
				font-size: 24px!important;
				font-family: "Open Sans", sans-serif;
			}

			.prepaid-currency-sign {
				font-size: 10px!important;
				float: right;
				vertical-align: super;
				color: #ccc;
				margin-top: 6px;
				font-weight: 800;
				font-family: 'Poppins', sans-serif;
			}

			.plan-note {
				margin-top: -5px;
			}

			.action-button {
				position: unset;
			}

		}
	}

	p:last-child {
	  margin-bottom: 0;
	}
  }

  .tab-menu-heading {
	padding: 20px;
	border-bottom: 0;
	border-radius: 5px 5px 0 0;
  }


  #back-to-top:hover {
	background: #fff;
	line-height: 3;
	border-color: #fff;

	i {
		color: #007bff;
	}
  }
