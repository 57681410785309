@charset "UTF-8";
/* ==========================================================================           
 *
 *    LOGIN PAGE
 *
 * ========================================================================== */
#login-google {
  background: #DB4437;
  color: #FFF;
  border-radius: 3px;
}
#login-google:hover, #login-google:focus {
  background-color: #1e1e2d;
}

#login-facebook {
  background: #4267B2;
  color: #FFF;
  border-radius: 3px;
}
#login-facebook:hover, #login-facebook:focus {
  background-color: #1e1e2d;
}

#login-twitter {
  background: #1DA1F2;
  color: #FFF;
  border-radius: 3px;
}
#login-twitter:hover, #login-twitter:focus {
  background-color: #1e1e2d;
}

#login-linkedin {
  background: #0e76a8;
  color: #FFF;
  border-radius: 3px;
}
#login-linkedin:hover, #login-linkedin:focus {
  background-color: #1e1e2d;
}

#login-footer {
  bottom: 0;
  left: 32.5%;
}

.invalid-feedback {
  font-size: 10px !important;
  color: #ff3547 !important;
}

#login-background {
  background-position: center;
  vertical-align: middle;
}
#login-background .login-bg {
  background-image: url(/img/files/login-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

.frontend-body {
  background: #fff;
}

.blue-background {
  background: #f5f9fc;
}

/* ==========================================================================           
 *
 *    MAIN SECTION STYLES
 *
 * ========================================================================== */
#main-wrapper {
  /*------------------------------------------*/
  /*    Navigation
  /*------------------------------------------*/
}
#main-wrapper #special-image-margin {
  margin-top: -8rem;
}
#main-wrapper #home-img {
  position: absolute;
  right: 90%;
  top: 15%;
  width: 30%;
}
#main-wrapper .gradient {
  background-image: linear-gradient(45deg, #ff61d3 10%, #ff61d3 36%, #b318ff 47%, #00f0ff 67%, #00f0ff 76%, #1e43ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
#main-wrapper .gradient.fixed-height {
  height: 1.2em;
}
#main-wrapper .navbar {
  background: transparent;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 50px;
  z-index: 100;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 800;
  -webkit-transition: all ease-in 0.2s;
  transition: all ease-in 0.2s;
}
#main-wrapper .navbar-nav {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
#main-wrapper .frontend-buttons {
  margin-left: 4rem;
}
#main-wrapper #login-button {
  padding-left: 2rem;
  padding-right: 2rem;
  background: #1e1e2d;
}
#main-wrapper #login-button:hover, #main-wrapper #login-button:focus {
  background: #007BFF;
}
#main-wrapper .navbar-nav li.nav-item a.nav-link {
  text-transform: uppercase;
  padding: 0px 15px;
  color: #FFF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}
#main-wrapper .navbar-nav li.nav-item a.nav-link .active {
  color: #007BFF !important;
}
#main-wrapper .navbar-nav li.nav-item a.nav-link:hover, #main-wrapper .navbar-nav li.nav-item a.nav-link:focus {
  color: #007BFF;
}
#main-wrapper .dark-color {
  color: #1e1e2d !important;
}
#main-wrapper .navbar-nav li.nav-item a.active {
  color: #007BFF !important;
}
#main-wrapper .navbar-nav li.nav-item .action-button {
  background: #007BFF;
  outline: none;
  text-decoration: none;
  min-width: 125px;
  color: #FFF;
  padding: 0.375rem 0.75rem;
  border-radius: 35px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}
#main-wrapper .navbar-nav li.nav-item .action-button:hover, #main-wrapper .navbar-nav li.nav-item .action-button:focus {
  background: #1e1e2d;
}
#main-wrapper .register-button,
#main-wrapper .dashboard-button {
  background: #ffab00 !important;
}
#main-wrapper .register-button:hover, #main-wrapper .register-button:focus,
#main-wrapper .dashboard-button:hover,
#main-wrapper .dashboard-button:focus {
  background: #1e1e2d !important;
}
#main-wrapper .minimize-navbar {
  line-height: 60px;
  background: rgb(255, 255, 255);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
#main-wrapper #main-background {
  background: #000;
}
#main-wrapper #bg-shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -10;
}
#main-wrapper .central-banner {
  position: absolute;
  width: 100%;
  top: 23%;
}
#main-wrapper .central-banner .filepond--drop-label {
  height: 120px !important;
}
#main-wrapper .text-container h1 {
  line-height: 1.2em;
  margin: 0;
  font-weight: 800;
  font-size: 70px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
#main-wrapper .text-container h1 span {
  color: #007BFF;
}
#main-wrapper .text-container h2 {
  font-weight: 800;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
#main-wrapper .text-container h2 span {
  color: #007BFF;
}
#main-wrapper .text-container p {
  font-family: "Poppins", sans-serif;
  color: rgb(94, 114, 144);
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-weight: 700;
}
#main-wrapper .text-container a {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  padding-left: 2rem;
  padding-right: 2rem;
}
#main-wrapper .text-container a:hover, #main-wrapper .text-container a:focus {
  background: #1e1e2d;
  border-color: #1e1e2d;
}

.special-action-button {
  box-shadow: 0 5px 10px rgba(60, 66, 150, 0.3764705882) !important;
  border-radius: 35px !important;
  transition: all 0.3s ease !important;
}
.special-action-button:focus, .special-action-button:hover {
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14) !important;
}

.frontend-button {
  background-color: #1e1e2d !important;
  border-color: #1e1e2d !important;
}

.vertical-center {
  align-items: center;
}

/* ==========================================================================           
 *
 *    FEATURES SECTION STYLES
 *
 * ========================================================================== */
#features-wrapper {
  background: #fff;
  padding: 36px 0 112px 0;
  position: relative;
  z-index: -1;
}
#features-wrapper #side-image {
  position: absolute;
  left: 86%;
  top: 5%;
  width: 30%;
}
#features-wrapper .feature-text {
  align-content: center;
  align-items: center;
  display: grid;
  padding: 1.9rem;
}
#features-wrapper .feature-text h4 {
  font-size: 20px;
  line-height: 1.5em;
  font-family: "Poppins", sans-serif;
  color: rgb(94, 114, 144);
  font-weight: 700;
}
#features-wrapper .feature-text h4 span {
  font-weight: 800;
}
#features-wrapper .feature-text p {
  font-size: 16px;
  line-height: 2em;
}
#features-wrapper .title h6 {
  font-size: 16px;
  font-weight: 800;
}
#features-wrapper .title h6 span {
  color: #007BFF;
}
#features-wrapper .title p {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}
#features-wrapper .mdi {
  line-height: 1;
}
#features-wrapper #solutions-list .solution {
  background-color: #FFF;
  height: 100%;
  border-radius: 10px;
  position: relative;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 20px 40px -12px rgba(0, 0, 0, 0.1);
}
#features-wrapper #solutions-list .solution:hover i, #features-wrapper #solutions-list .solution:focus i {
  color: #ff9d00;
}
#features-wrapper #solutions-list .solution:hover {
  box-shadow: none !important;
}
#features-wrapper #solutions-list .solution-content {
  padding: 20px 32px;
}
#features-wrapper #solutions-list .solution-content h5 {
  color: #007BFF;
  font-size: 14px;
  font-weight: 700;
}
#features-wrapper #solutions-list .solution-content i {
  font-size: 32px;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}
#features-wrapper #solutions-list .solution-content p {
  font-size: 13px;
  margin-bottom: 3rem;
}

/* ==========================================================================           
 *
 *    BANNER STYLES
 *
 * ========================================================================== */
#banner-wrapper {
  background: #edf8fd;
  padding: 90px 0 90px 0;
}
#banner-wrapper #banner-text {
  padding-left: 3rem;
  padding-right: 3rem;
}
#banner-wrapper .banner-text-inner {
  margin-bottom: 2rem;
}
#banner-wrapper h4 {
  font-size: 24px;
  font-weight: 800;
  color: white;
  margin-bottom: 5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}
#banner-wrapper h5 {
  font-weight: 800;
  font-size: 20px;
  color: white;
}

/* ==========================================================================           
 *
 *    PRICES SECTION STYLES
 *
 * ========================================================================== */
#prices-wrapper {
  padding: 0 0 64px 0;
  background-color: #FFF;
}
#prices-wrapper .title h6 {
  font-size: 16px;
  font-weight: 800;
}
#prices-wrapper .title h6 span {
  color: #007BFF;
}
#prices-wrapper .title p {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}
#prices-wrapper .tabs-menu ul {
  padding: 5px 7px;
  background: #f5f9fc;
  border-radius: 1.5rem;
}
#prices-wrapper .tabs-menu ul li a {
  width: auto;
  text-transform: none;
}
#prices-wrapper .card {
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14) !important;
  transition: all 0.2s;
}
#prices-wrapper .card:hover, #prices-wrapper .card:focus {
  box-shadow: 0 0 12px rgba(33, 33, 33, 0.2) !important;
}
#prices-wrapper .card:hover .plan-cost, #prices-wrapper .card:focus .plan-cost {
  color: #007BFF;
}
#prices-wrapper .card:hover .prepaid-plan .plan-cost, #prices-wrapper .card:focus .prepaid-plan .plan-cost {
  color: rgb(102, 102, 102);
}
#prices-wrapper .card:hover .prepaid-plan .plan-title, #prices-wrapper .card:focus .prepaid-plan .plan-title {
  color: #007BFF;
}
#prices-wrapper .card .plan {
  min-height: 380px;
}
#prices-wrapper .card .plan-cost {
  font-size: 40px;
  font-weight: 800;
  color: rgb(102, 102, 102);
  padding-left: 17px;
  padding-right: 5px;
  font-family: "Open Sans", sans-serif;
}
#prices-wrapper .card .welcome-plan-currency-sign {
  font-size: 20px;
  margin-bottom: 20px;
  position: absolute;
  top: 25px;
  color: #ccc;
  font-weight: 800;
  font-family: "Poppins", sans-serif;
}
#prices-wrapper .card .divider {
  background-color: rgb(230, 230, 230);
  border-radius: 5px;
  height: 3px;
  width: 40px;
}
#prices-wrapper .card .plan-title {
  font-size: 24px;
  font-weight: 800;
  font-family: "Open Sans";
  margin-bottom: 1rem;
}
#prices-wrapper .card ul li {
  margin-bottom: 0.3rem;
}
#prices-wrapper .card ul li i {
  margin-right: 0.3rem;
}

.special-card {
  border-radius: 1.5rem !important;
}

/* ==========================================================================           
 *
 *    CUSTOMER FEEDBACKS SECTION STYLES
 *
 * ========================================================================== */
#feedbacks-wrapper {
  background: #fff;
  padding: 0 0 128px 0;
  /*------------------------------------------*/
  /*    Feedback Commenter 
  /*------------------------------------------*/
  /*------------------------------------------*/
  /*    Feedback Comment
  /*------------------------------------------*/
}
#feedbacks-wrapper .container {
  position: relative;
}
#feedbacks-wrapper .row {
  display: block;
}
#feedbacks-wrapper .row .title h6 {
  font-size: 16px;
  font-weight: 800;
  color: #1e1e2d;
}
#feedbacks-wrapper .row .title h6 span {
  color: #007BFF;
}
#feedbacks-wrapper .row .title p {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}
#feedbacks-wrapper #feedbacks .slick-slide img {
  display: inline-block;
}
#feedbacks-wrapper #feedbacks .slick-dots {
  position: relative;
}
#feedbacks-wrapper #feedbacks .slick-dots li button:before {
  color: #007BFF;
}
#feedbacks-wrapper #feedbacks .slick-dots li.slick-active button:before {
  color: #007BFF;
}
#feedbacks-wrapper #feedbacks .slick-slide:hover {
  cursor: pointer;
}
#feedbacks-wrapper .feedback .feedback-reviewer {
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 700;
  color: #007BFF;
}
#feedbacks-wrapper .feedback img {
  padding: 3px;
  border: 1px solid rgba(94, 109, 129, 0.3);
  width: 70px;
  height: 70px;
}
#feedbacks-wrapper .feedback .feedback-image {
  text-align: center;
  padding-left: 25px;
}
#feedbacks-wrapper .feedback .feedback-image .small-quote {
  background-color: #FFA838;
}
#feedbacks-wrapper .feedback .feedback-image span {
  position: relative;
  top: -30px;
  left: -25px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #007BFF;
  color: #FFF;
  line-height: 20px;
  font-size: 9px;
}
#feedbacks-wrapper .feedback {
  padding-left: 1rem;
  padding-right: 1rem;
}
#feedbacks-wrapper .feedback .comment {
  font-size: 14px;
  font-style: italic;
}
#feedbacks-wrapper .feedback .comment span {
  font-size: 10px;
}
#feedbacks-wrapper .feedback span {
  font-size: 12px;
  color: rgba(94, 109, 129, 0.8);
}

.offers-prev,
.offers-next {
  position: absolute;
  top: 65%;
  font-size: 10px;
  cursor: pointer;
}

.offers-prev i,
.offers-next i {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #FFF;
  text-align: center;
  color: #007BFF;
  line-height: 40px;
  transition: all ease 0.3s;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.08), 0 5px 5px 0 rgba(0, 0, 0, 0.1), 0 5px 2px -5px rgba(0, 0, 0, 0.16);
}
.offers-prev i:hover, .offers-prev i:focus,
.offers-next i:hover,
.offers-next i:focus {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 5px 5px 0 rgba(0, 0, 0, 0.1), 0 5px 2px -5px rgba(0, 0, 0, 0.16);
  color: #FFA838;
}

.offers-prev {
  left: -50px;
}

.offers-next {
  right: -50px;
}

/* ==========================================================================           
 *
 *    Contact Us SECTION STYLES
 *
 * ========================================================================== */
.row .title h6 {
  font-size: 16px;
  font-weight: 800;
  color: #1e1e2d;
}
.row .title h6 span {
  color: #007BFF;
}
.row .title p {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

#contact-wrapper {
  background: #FFF;
  padding: 0 0 128px 0;
  background-image: url(/img/files/world.png);
  background-position: center center;
  background-repeat: no-repeat;
}
#contact-wrapper #contact-row {
  display: block;
}
#contact-wrapper .row label {
  font-family: "Lato", sans-serif;
}
#contact-wrapper .row button {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  background-color: #007BFF;
  padding-left: 2rem;
  padding-right: 2rem;
  text-transform: none;
  font-weight: 700;
}
#contact-wrapper .row button:hover {
  background: #1e1e2d;
  border-color: #1e1e2d;
}

#contact-wrapper .form-control {
  background: #EDF8FD !important;
}

/* ==========================================================================           
 *
 *    BLOG SECTION STYLES
 *
 * ========================================================================== */
.download-page {
  background: #f5f9fc !important;
}

#blog-wrapper {
  background: #FFF;
  padding: 0 0 90px 0;
}
#blog-wrapper .container {
  position: relative;
}
#blog-wrapper .blog {
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 295px;
}
#blog-wrapper .slick-slider .slick-track {
  height: 350px !important;
}
#blog-wrapper .blog-box {
  min-height: 270px;
  padding: 0.5rem;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.08), 0 3px 3px 0 rgba(0, 0, 0, 0.1), 0 3px 2.5px -5px rgba(0, 0, 0, 0.16);
  transition: all 0.5s;
}
#blog-wrapper .blog-box .blog-img img {
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.2s;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}
#blog-wrapper .blog-box .blog-info {
  margin-top: 0.7rem;
  padding-left: 0.5rem;
}
#blog-wrapper .blog-box .blog-info h5 {
  font-size: 14px;
  font-weight: 700;
}
#blog-wrapper .blog-box .blog-info h6 {
  font-size: 12px;
}
#blog-wrapper .blog-box:hover {
  box-shadow: 0 9px 17px 0 rgba(0, 0, 0, 0.08), 0 9px 10px 0 rgba(0, 0, 0, 0.1), 0 9px 4.5px -9px rgba(0, 0, 0, 0.16);
}
#blog-wrapper .blog-view {
  max-height: 300px;
  margin-bottom: 4rem;
}
#blog-wrapper .blog-view img {
  max-height: 300px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  width: 100%;
}
#blog-wrapper #blogs .slick-slide img {
  display: inline-block;
}
#blog-wrapper #blogs .slick-dots {
  position: relative;
}
#blog-wrapper #blogs .slick-dots li button:before {
  color: #007BFF;
}
#blog-wrapper #blogs .slick-dots li.slick-active button:before {
  color: #007BFF;
}
#blog-wrapper #blogs .slick-slide:hover {
  cursor: pointer;
}
#blog-wrapper .blogs-prev,
#blog-wrapper .blogs-next {
  position: absolute;
  top: 63%;
  font-size: 10px;
  cursor: pointer;
}
#blog-wrapper .blogs-prev i,
#blog-wrapper .blogs-next i {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #FFF;
  text-align: center;
  color: #007BFF;
  line-height: 40px;
  transition: all ease 0.3s;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.08), 0 5px 5px 0 rgba(0, 0, 0, 0.1), 0 5px 2px -5px rgba(0, 0, 0, 0.16);
}
#blog-wrapper .blogs-prev i:hover, #blog-wrapper .blogs-prev i:focus,
#blog-wrapper .blogs-next i:hover,
#blog-wrapper .blogs-next i:focus {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 5px 5px 0 rgba(0, 0, 0, 0.1), 0 5px 2px -5px rgba(0, 0, 0, 0.16);
  color: #FFA838;
}
#blog-wrapper .blogs-prev {
  left: -50px;
}
#blog-wrapper .blogs-next {
  right: -50px;
}

/* ==========================================================================           
 *
 *    FOOTER STYLES
 *
 * ========================================================================== */
#welcome-footer {
  background-color: #000;
}
#welcome-footer #footer {
  padding: 96px 0 64px 0;
}
#welcome-footer h5 {
  font-size: 14px;
  font-weight: 800;
}
#welcome-footer h6 {
  font-family: "Lato", sans-serif;
  color: #BEC0C2;
  line-height: 1.8em;
  font-size: 12px;
}
#welcome-footer ul {
  text-align: left;
  font-size: 12px;
}
#welcome-footer ul li {
  border-bottom: none;
}
#welcome-footer ul li a {
  font-family: "Lato", sans-serif;
  color: #BEC0C2;
  line-height: 1.8em;
  outline: none;
}
#welcome-footer ul li a:hover, #welcome-footer ul li a:focus {
  color: #007BFF;
  text-decoration: none;
}
#welcome-footer #footer-logo {
  text-align: left;
  padding-right: 48px;
}
#welcome-footer #footer-logo img {
  margin-top: -25px;
  margin-left: -20px;
  margin-bottom: 0.5rem;
}
#welcome-footer #footer-logo p {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  line-height: 2em;
  color: #BEC0C2;
}
#welcome-footer #footer-logo p a {
  font-size: 12px;
  color: #007BFF;
}
#welcome-footer #footer-logo p a:hover, #welcome-footer #footer-logo p a:hover i {
  color: #1c5c7f;
  text-decoration: none;
}
#welcome-footer #footer-logo p i {
  margin-left: 4px;
  color: #007BFF;
  font-size: 10px;
}
#welcome-footer #footer-links {
  float: left;
  text-align: left;
  display: flex;
}
#welcome-footer #footer-links h5 {
  color: #FFF;
}
#welcome-footer #back-to-top {
  line-height: 2.5;
}

/*------------------------------------------*/
/*    Connection Link Icons
/*------------------------------------------*/
#welcome-footer ul#footer-icons {
  padding-top: 8px;
  margin-bottom: 1.5rem;
}

#welcome-footer ul#footer-icons li.list-inline-item {
  margin-right: 0px;
}

#welcome-footer .footer-icon {
  position: relative;
  text-align: center;
  color: #FFF;
  width: 35px;
  height: 35px;
  font-size: 16px;
  margin-right: 5px;
  cursor: pointer;
  line-height: 35px;
  border-radius: 3px;
  -moz-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

#welcome-footer #footer-icons .fa-facebook {
  background-color: #3b5998;
}

#welcome-footer #footer-icons .fa-twitter {
  background-color: #55acee;
}

#welcome-footer #footer-icons .fa-linkedin {
  background-color: #007bb5;
}

#welcome-footer #footer-icons .fa-instagram {
  background: linear-gradient(29.61deg, #f38334 0%, #da2e7d 50.39%, #6b54c6 100%);
}

#welcome-footer #footer-icons .fa-youtube {
  background-color: #FF0000;
}

#welcome-footer #footer-icons .fa-facebook:hover {
  background-color: #3b5998;
  color: #FFF;
}

#welcome-footer #footer-icons .fa-twitter:hover {
  background-color: #55acee;
  color: #FFF;
}

#welcome-footer #footer-icons .fa-linkedin:hover {
  background-color: #007bb5;
  color: #FFF;
}

#welcome-footer #footer-icons .fa-instagram:hover {
  background-color: #e95950;
  color: #FFF;
}

#welcome-footer #footer-icons .fa-youtube:hover {
  background-color: #FF0000;
  color: #FFF;
}

/*------------------------------------------*/
/*    Copyright Styles 
/*------------------------------------------*/
#welcome-footer #copyright {
  font-family: "Lato", sans-serif;
  border-top: 1px solid #2b3340;
  height: 64px;
  padding: 24px 0px 0px 0px;
}

#welcome-footer #copyright p {
  font-size: 12px;
  line-height: 1em;
  color: rgba(247, 247, 247, 0.9);
  font-weight: 700;
}

#welcome-footer #copyright p a {
  color: #007BFF;
  font-size: 12px;
}

#welcome-footer #copyright p a:hover {
  color: #1c5c7f;
  outline: none;
  text-decoration: none;
}

#welcome-footer #copyright span {
  color: #898989;
  font-size: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

#welcome-footer #copyright .copyright-left {
  float: left;
}

#welcome-footer #copyright .copyright-right {
  float: right;
  text-align: right;
}

/* ==========================================================================           
 *
 *    GDPR Cookie
 *
 * ========================================================================== */
.js-cookie-consent {
  position: fixed;
  font-size: 14px;
  bottom: 0;
  padding: 2rem;
  text-align: justify;
  width: 100%;
  z-index: 9999;
  background-color: #fff;
  color: #1e1e2d;
  box-shadow: 0 2px 16px rgba(179, 186, 212, 0.7) !important;
}
.js-cookie-consent .js-cookie-consent-agree {
  margin-left: 0.7rem;
  margin-top: 0.5rem;
  font-size: 10px;
  min-width: 80px;
  border-radius: 0.25rem;
  padding: 0.375rem 25px;
  text-transform: uppercase;
  color: #fff !important;
  background-color: #007BFF;
  border-color: #007BFF;
}
.js-cookie-consent .js-cookie-consent-agree:hover, .js-cookie-consent .js-cookie-consent-agree:focus {
  background-color: #1e1e2d;
  border-color: #1e1e2d;
}

/* ==========================================================================           
 *
 *    LOCALE STYLES
 *
 * ========================================================================== */
#frontend-local {
  float: left;
}
#frontend-local .nav-link.icon .header-icon {
  color: #FFF;
  padding-left: 0;
}
#frontend-local .dropdown-menu {
  margin-top: 10px;
  min-width: 8rem;
  border-radius: 7px;
}
#frontend-local .dropdown-menu .dropdown-item:hover {
  color: #007BFF;
}

section .alert {
  right: 1rem;
  margin-top: 0;
  color: #FFF !important;
  font-family: "Poppins", sans-serif;
  float: right;
}

/* ==========================================================================           
*
*    FAQ STYLES
*
* ========================================================================== */
#faq-wrapper {
  background: #edf8fd;
  padding: 0 0 90px 0;
}
#faq-wrapper #accordion {
  width: 100%;
}
#faq-wrapper #accordion .card {
  border-radius: 5px !important;
  box-shadow: inset 0 1px 0.5px hsla(0deg, 0%, 100%, 0.075), 0 0.3px 0.4px rgba(0, 0, 0, 0.025), 0 0.9px 1.5px rgba(0, 0, 0, 0.05), 0 3.5px 6px rgba(0, 0, 0, 0.1);
  border: none !important;
  border-bottom: none !important;
  overflow: visible;
  margin-bottom: 0.5rem;
  transition: box-shadow 0.2s ease-in-out !important;
}
#faq-wrapper #accordion .card:hover, #faq-wrapper #accordion .card:focus {
  box-shadow: 0 0.9px 1.5px rgba(0, 0, 0, 0.03), 0 3.1px 5.5px rgba(0, 0, 0, 0.08), 0 14px 25px rgba(0, 0, 0, 0.12);
}
#faq-wrapper #accordion .card .card-header {
  border-bottom: none;
  transition: box-shadow 0.2s ease-in-out !important;
}
#faq-wrapper #accordion .card .card-header h5 {
  width: 100%;
}
#faq-wrapper #accordion .card .card-header h5 span {
  text-align: left;
}
#faq-wrapper #accordion .card .card-header .btn-link {
  font-size: 14px;
  color: #1e1e2d;
  font-weight: 600;
  width: 100%;
  outline: none;
  border: none;
  text-decoration: none;
}
#faq-wrapper #accordion .card .card-header .btn-link:hover, #faq-wrapper #accordion .card .card-header .btn-link:focus {
  color: #007BFF;
  text-decoration: none !important;
  outline: none !important;
}
#faq-wrapper #accordion .card .card-body {
  font-size: 14px;
  padding-left: 2.2rem;
}

/* ==========================================================================           
 *
 *    GOOGLE ADS STYLES
 *
 * ========================================================================== */
.google-ads-728 {
  width: 100%;
  max-width: 728px;
  height: auto;
}

.google-ads-300 {
  width: 100%;
  max-width: 480px;
  height: auto;
}

/* ==========================================================================           
 *
 *    DOWNLOAD STYLES
 *
 * ========================================================================== */
#download-password-box .input-group-btn .btn-primary {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  padding: 0.73rem 15px !important;
  z-index: 100;
}
#download-password-box input {
  border-radius: 0.5rem !important;
}

#download-background {
  background-image: url(/img/files/world.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

/*------ Alignments -------*/
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-success {
  background-color: #38cb89 !important;
}

a.bg-success:hover, a.bg-success:focus {
  background-color: #15ca7c !important;
}

button.bg-success:hover, button.bg-success:focus {
  background-color: #15ca7c !important;
}

.bg-info {
  background-color: #007BFF !important;
}

.bg-dark {
  background-color: #1e1e2d !important;
}

a.bg-info:hover, a.bg-info:focus {
  background-color: #1594ef !important;
}

button.bg-info:hover, button.bg-info:focus {
  background-color: #1594ef !important;
}

.bg-warning {
  background-color: #ffab00 !important;
}

a.bg-warning:hover, a.bg-warning:focus {
  background-color: #c29d0b !important;
}

button.bg-warning:hover, button.bg-warning:focus {
  background-color: #c29d0b !important;
}

.bg-danger {
  background-color: #ef4b4b !important;
}

a.bg-danger:hover, a.bg-danger:focus {
  background-color: #a11918 !important;
}

button.bg-danger:hover, button.bg-danger:focus {
  background-color: #a11918 !important;
}

.bg-light {
  background-color: #f0f0f2 !important;
}

a.bg-light:hover, a.bg-light:focus {
  background-color: #f0f0f2 !important;
}

button.bg-light:hover, button.bg-light:focus {
  background-color: #f0f0f2 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient-success {
  background-image: linear-gradient(to left, #43c47a 0%, #10ae23 100%);
}

.bg-gradient-info {
  background-image: linear-gradient(to left, #8caef3 0%, #5b7fff 100%);
}

.bg-gradient-warning {
  background-image: linear-gradient(to left, #f8be8d 0%, #ff9a44 100%);
}

.bg-gradient-teal {
  background-image: linear-gradient(to left, #60c1db 0%, #0698a7 100%);
}

.bg-gradient-blue {
  background-image: linear-gradient(to left, #07aedb 0%, #055ee9 100%);
}

.bg-gradient-danger {
  background-image: linear-gradient(to left, #ff90a0 0%, #fc6076 100%);
}

.bg-gray-100 {
  background-color: #f2f2f2;
}

.bg-gray-200 {
  background-color: #e6e6e6;
}

.bg-gray-300 {
  background-color: #d9d9d9;
}

.bg-gray-400 {
  background-color: #cccccc;
}

.bg-gray-500 {
  background-color: #bfbfbf;
}

.bg-gray-600 {
  background-color: #b3b3b3;
}

.bg-gray-700 {
  background-color: #a9a9a9;
}

.bg-gray-800 {
  background-color: #a6a6a6;
}

.bg-gray-900 {
  background-color: #999999;
}

.bg-white-1 {
  background-color: rgba(255, 255, 255, 0.1);
}

.bg-white-2 {
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-white-3 {
  background-color: rgba(255, 255, 255, 0.3);
}

.bg-white-4 {
  background-color: rgba(255, 255, 255, 0.4);
}

.bg-white-5 {
  background-color: rgba(255, 255, 255, 0.5);
}

.bg-white-6 {
  background-color: rgba(255, 255, 255, 0.6);
}

.bg-white-7 {
  background-color: rgba(255, 255, 255, 0.7);
}

.bg-white-8 {
  background-color: rgba(255, 255, 255, 0.8);
}

.bg-white-9 {
  background-color: rgba(255, 255, 255, 0.9);
}

.bg-black-1 {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-black-2 {
  background-color: rgba(0, 0, 0, 0.2);
}

.bg-black-3 {
  background-color: rgba(0, 0, 0, 0.3);
}

.bg-black-4 {
  background-color: rgba(0, 0, 0, 0.4);
}

.bg-black-5 {
  background-color: rgba(0, 0, 0, 0.5);
}

.bg-black-6 {
  background-color: rgba(0, 0, 0, 0.6);
}

.bg-black-7 {
  background-color: rgba(0, 0, 0, 0.7);
}

.bg-black-8 {
  background-color: rgba(0, 0, 0, 0.8);
}

.bg-black-9 {
  background-color: rgba(0, 0, 0, 0.9);
}

.border {
  border: 1px solid #ebecf1 !important;
}

.border-top {
  border-top: 1px solid #ebecf1 !important;
}

.border-right {
  border-right: 1px solid #ebecf1 !important;
}

.border-bottom {
  border-bottom: 1px solid #ebecf1 !important;
}

.border-left {
  border-left: 1px solid #ebecf1 !important;
}

.border-top-bottom {
  border-top: 1px solid #ebecf1 !important;
  border-bottom: 1px solid #ebecf1 !important;
}

.border-left-right {
  border-right: 1px solid #ebecf1 !important;
  border-left: 1px solid #ebecf1 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-top-bottom-0 {
  border-top: 0px solid #ebecf1 !important;
  border-bottom: 0px solid #ebecf1 !important;
}

.border-left-right-0 {
  border-right: 0px solid #ebecf1 !important;
  border-left: 0px solid #ebecf1 !important;
}

.border-success {
  border-color: #38cb89 !important;
}

.border-info {
  border-color: #5b7fff !important;
}

.border-warning {
  border-color: #ffab00 !important;
}

.border-danger {
  border-color: #dc0441 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-transparent {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.border-dark-transparent {
  border-color: rgba(0, 0, 0, 0.15) !important;
}

.border-light2 {
  border-color: #ebecf1 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-wd-1 {
  border-width: 1px !important;
}

.border-wd-2 {
  border-width: 2px !important;
}

.border-wd-3 {
  border-width: 3px !important;
}

.border-wd-4 {
  border-width: 4px !important;
}

.border-wd-5 {
  border-width: 5px !important;
}

.rounded {
  border-radius: 3px !important;
}

.rounded-top {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.rounded-right {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rounded-left {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rounded-circle {
  border-radius: 50% !important;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.clearfix .fab {
  font-size: 25px;
}
.clearfix h5 {
  font-size: 20px;
  font-weight: bold;
}

i.fa.round {
  border-radius: 50%;
  padding: 5px;
  width: 15%;
}

.list-icon span p {
  border-radius: 15px;
  width: 50%;
  margin: 0px auto;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1, .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1, .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1, .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1, .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2, .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2, .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2, .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2, .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 0.75rem !important;
  }
  .mt-sm-3, .my-sm-3 {
    margin-top: 0.75rem !important;
  }
  .mr-sm-3, .mx-sm-3 {
    margin-right: 0.75rem !important;
  }
  .mb-sm-3, .my-sm-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-sm-3, .mx-sm-3 {
    margin-left: 0.75rem !important;
  }
  .m-sm-4 {
    margin: 1rem !important;
  }
  .mt-sm-4, .my-sm-4 {
    margin-top: 1rem !important;
  }
  .mr-sm-4, .mx-sm-4 {
    margin-right: 1rem !important;
  }
  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-4, .mx-sm-4 {
    margin-left: 1rem !important;
  }
  .m-sm-5 {
    margin: 1.5rem !important;
  }
  .mt-sm-5, .my-sm-5 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-5, .mx-sm-5 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-5, .my-sm-5 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-5, .mx-sm-5 {
    margin-left: 1.5rem !important;
  }
  .m-sm-6 {
    margin: 2rem !important;
  }
  .mt-sm-6, .my-sm-6 {
    margin-top: 2rem !important;
  }
  .mr-sm-6, .mx-sm-6 {
    margin-right: 2rem !important;
  }
  .mb-sm-6, .my-sm-6 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-6, .mx-sm-6 {
    margin-left: 2rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .mt-sm-7, .my-sm-7 {
    margin-top: 3rem !important;
  }
  .mr-sm-7, .mx-sm-7 {
    margin-right: 3rem !important;
  }
  .mb-sm-7, .my-sm-7 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-7, .mx-sm-7 {
    margin-left: 3rem !important;
  }
  .m-sm-8 {
    margin: 4rem !important;
  }
  .mt-sm-8, .my-sm-8 {
    margin-top: 4rem !important;
  }
  .mr-sm-8, .mx-sm-8 {
    margin-right: 4rem !important;
  }
  .mb-sm-8, .my-sm-8 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-8, .mx-sm-8 {
    margin-left: 4rem !important;
  }
  .m-sm-9 {
    margin: 6rem !important;
  }
  .mt-sm-9, .my-sm-9 {
    margin-top: 6rem !important;
  }
  .mr-sm-9, .mx-sm-9 {
    margin-right: 6rem !important;
  }
  .mb-sm-9, .my-sm-9 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-9, .mx-sm-9 {
    margin-left: 6rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1, .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1, .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1, .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1, .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2, .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2, .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2, .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2, .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 0.75rem !important;
  }
  .pt-sm-3, .py-sm-3 {
    padding-top: 0.75rem !important;
  }
  .pr-sm-3, .px-sm-3 {
    padding-right: 0.75rem !important;
  }
  .pb-sm-3, .py-sm-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-sm-3, .px-sm-3 {
    padding-left: 0.75rem !important;
  }
  .p-sm-4 {
    padding: 1rem !important;
  }
  .pt-sm-4, .py-sm-4 {
    padding-top: 1rem !important;
  }
  .pr-sm-4, .px-sm-4 {
    padding-right: 1rem !important;
  }
  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-4, .px-sm-4 {
    padding-left: 1rem !important;
  }
  .p-sm-5 {
    padding: 1.5rem !important;
  }
  .pt-sm-5, .py-sm-5 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-5, .px-sm-5 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-5, .py-sm-5 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-5, .px-sm-5 {
    padding-left: 1.5rem !important;
  }
  .p-sm-6 {
    padding: 2rem !important;
  }
  .pt-sm-6, .py-sm-6 {
    padding-top: 2rem !important;
  }
  .pr-sm-6, .px-sm-6 {
    padding-right: 2rem !important;
  }
  .pb-sm-6, .py-sm-6 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-6, .px-sm-6 {
    padding-left: 2rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .pt-sm-7, .py-sm-7 {
    padding-top: 3rem !important;
  }
  .pr-sm-7, .px-sm-7 {
    padding-right: 3rem !important;
  }
  .pb-sm-7, .py-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-7, .px-sm-7 {
    padding-left: 3rem !important;
  }
  .p-sm-8 {
    padding: 4rem !important;
  }
  .pt-sm-8, .py-sm-8 {
    padding-top: 4rem !important;
  }
  .pr-sm-8, .px-sm-8 {
    padding-right: 4rem !important;
  }
  .pb-sm-8, .py-sm-8 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-8, .px-sm-8 {
    padding-left: 4rem !important;
  }
  .p-sm-9 {
    padding: 6rem !important;
  }
  .pt-sm-9, .py-sm-9 {
    padding-top: 6rem !important;
  }
  .pr-sm-9, .px-sm-9 {
    padding-right: 6rem !important;
  }
  .pb-sm-9, .py-sm-9 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-9, .px-sm-9 {
    padding-left: 6rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .page-content {
    margin: 1.5rem 0;
  }
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1, .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1, .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1, .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1, .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2, .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2, .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2, .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2, .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .mt-md-3, .my-md-3 {
    margin-top: 0.75rem !important;
  }
  .mr-md-3, .mx-md-3 {
    margin-right: 0.75rem !important;
  }
  .mb-md-3, .my-md-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-md-3, .mx-md-3 {
    margin-left: 0.75rem !important;
  }
  .m-md-4 {
    margin: 1rem !important;
  }
  .mt-md-4, .my-md-4 {
    margin-top: 1rem !important;
  }
  .mr-md-4, .mx-md-4 {
    margin-right: 1rem !important;
  }
  .mb-md-4, .my-md-4 {
    margin-bottom: 1rem !important;
  }
  .ml-md-4, .mx-md-4 {
    margin-left: 1rem !important;
  }
  .m-md-5 {
    margin: 1.5rem !important;
  }
  .mt-md-5, .my-md-5 {
    margin-top: 1.5rem !important;
  }
  .mr-md-5, .mx-md-5 {
    margin-right: 1.5rem !important;
  }
  .mb-md-5, .my-md-5 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-5, .mx-md-5 {
    margin-left: 1.5rem !important;
  }
  .m-md-6 {
    margin: 2rem !important;
  }
  .mt-md-6, .my-md-6 {
    margin-top: 2rem !important;
  }
  .mr-md-6, .mx-md-6 {
    margin-right: 2rem !important;
  }
  .mb-md-6, .my-md-6 {
    margin-bottom: 2rem !important;
  }
  .ml-md-6, .mx-md-6 {
    margin-left: 2rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .mt-md-7, .my-md-7 {
    margin-top: 3rem !important;
  }
  .mr-md-7, .mx-md-7 {
    margin-right: 3rem !important;
  }
  .mb-md-7, .my-md-7 {
    margin-bottom: 3rem !important;
  }
  .ml-md-7, .mx-md-7 {
    margin-left: 3rem !important;
  }
  .m-md-8 {
    margin: 4rem !important;
  }
  .mt-md-8, .my-md-8 {
    margin-top: 4rem !important;
  }
  .mr-md-8, .mx-md-8 {
    margin-right: 4rem !important;
  }
  .mb-md-8, .my-md-8 {
    margin-bottom: 4rem !important;
  }
  .ml-md-8, .mx-md-8 {
    margin-left: 4rem !important;
  }
  .m-md-9 {
    margin: 6rem !important;
  }
  .mt-md-9, .my-md-9 {
    margin-top: 6rem !important;
  }
  .mr-md-9, .mx-md-9 {
    margin-right: 6rem !important;
  }
  .mb-md-9, .my-md-9 {
    margin-bottom: 6rem !important;
  }
  .ml-md-9, .mx-md-9 {
    margin-left: 6rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1, .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1, .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1, .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1, .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2, .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2, .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2, .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2, .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .pt-md-3, .py-md-3 {
    padding-top: 0.75rem !important;
  }
  .pr-md-3, .px-md-3 {
    padding-right: 0.75rem !important;
  }
  .pb-md-3, .py-md-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-md-3, .px-md-3 {
    padding-left: 0.75rem !important;
  }
  .p-md-4 {
    padding: 1rem !important;
  }
  .pt-md-4, .py-md-4 {
    padding-top: 1rem !important;
  }
  .pr-md-4, .px-md-4 {
    padding-right: 1rem !important;
  }
  .pb-md-4, .py-md-4 {
    padding-bottom: 1rem !important;
  }
  .pl-md-4, .px-md-4 {
    padding-left: 1rem !important;
  }
  .p-md-5 {
    padding: 1.5rem !important;
  }
  .pt-md-5, .py-md-5 {
    padding-top: 1.5rem !important;
  }
  .pr-md-5, .px-md-5 {
    padding-right: 1.5rem !important;
  }
  .pb-md-5, .py-md-5 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-5, .px-md-5 {
    padding-left: 1.5rem !important;
  }
  .p-md-6 {
    padding: 2rem !important;
  }
  .pt-md-6, .py-md-6 {
    padding-top: 2rem !important;
  }
  .pr-md-6, .px-md-6 {
    padding-right: 2rem !important;
  }
  .pb-md-6, .py-md-6 {
    padding-bottom: 2rem !important;
  }
  .pl-md-6, .px-md-6 {
    padding-left: 2rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .pt-md-7, .py-md-7 {
    padding-top: 3rem !important;
  }
  .pr-md-7, .px-md-7 {
    padding-right: 3rem !important;
  }
  .pb-md-7, .py-md-7 {
    padding-bottom: 3rem !important;
  }
  .pl-md-7, .px-md-7 {
    padding-left: 3rem !important;
  }
  .p-md-8 {
    padding: 4rem !important;
  }
  .pt-md-8, .py-md-8 {
    padding-top: 4rem !important;
  }
  .pr-md-8, .px-md-8 {
    padding-right: 4rem !important;
  }
  .pb-md-8, .py-md-8 {
    padding-bottom: 4rem !important;
  }
  .pl-md-8, .px-md-8 {
    padding-left: 4rem !important;
  }
  .p-md-9 {
    padding: 6rem !important;
  }
  .pt-md-9, .py-md-9 {
    padding-top: 6rem !important;
  }
  .pr-md-9, .px-md-9 {
    padding-right: 6rem !important;
  }
  .pb-md-9, .py-md-9 {
    padding-bottom: 6rem !important;
  }
  .pl-md-9, .px-md-9 {
    padding-left: 6rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1, .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1, .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1, .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1, .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2, .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2, .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2, .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2, .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .mt-lg-3, .my-lg-3 {
    margin-top: 0.75rem !important;
  }
  .mr-lg-3, .mx-lg-3 {
    margin-right: 0.75rem !important;
  }
  .mb-lg-3, .my-lg-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-lg-3, .mx-lg-3 {
    margin-left: 0.75rem !important;
  }
  .m-lg-4 {
    margin: 1rem !important;
  }
  .mt-lg-4, .my-lg-4 {
    margin-top: 1rem !important;
  }
  .mr-lg-4, .mx-lg-4 {
    margin-right: 1rem !important;
  }
  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-4, .mx-lg-4 {
    margin-left: 1rem !important;
  }
  .m-lg-5 {
    margin: 1.5rem !important;
  }
  .mt-lg-5, .my-lg-5 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-5, .mx-lg-5 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-5, .my-lg-5 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-5, .mx-lg-5 {
    margin-left: 1.5rem !important;
  }
  .m-lg-6 {
    margin: 2rem !important;
  }
  .mt-lg-6, .my-lg-6 {
    margin-top: 2rem !important;
  }
  .mr-lg-6, .mx-lg-6 {
    margin-right: 2rem !important;
  }
  .mb-lg-6, .my-lg-6 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-6, .mx-lg-6 {
    margin-left: 2rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .mt-lg-7, .my-lg-7 {
    margin-top: 3rem !important;
  }
  .mr-lg-7, .mx-lg-7 {
    margin-right: 3rem !important;
  }
  .mb-lg-7, .my-lg-7 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-7, .mx-lg-7 {
    margin-left: 3rem !important;
  }
  .m-lg-8 {
    margin: 4rem !important;
  }
  .mt-lg-8, .my-lg-8 {
    margin-top: 4rem !important;
  }
  .mr-lg-8, .mx-lg-8 {
    margin-right: 4rem !important;
  }
  .mb-lg-8, .my-lg-8 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-8, .mx-lg-8 {
    margin-left: 4rem !important;
  }
  .m-lg-9 {
    margin: 6rem !important;
  }
  .mt-lg-9, .my-lg-9 {
    margin-top: 6rem !important;
  }
  .mr-lg-9, .mx-lg-9 {
    margin-right: 6rem !important;
  }
  .mb-lg-9, .my-lg-9 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-9, .mx-lg-9 {
    margin-left: 6rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1, .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1, .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1, .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1, .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2, .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2, .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2, .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2, .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .pt-lg-3, .py-lg-3 {
    padding-top: 0.75rem !important;
  }
  .pr-lg-3, .px-lg-3 {
    padding-right: 0.75rem !important;
  }
  .pb-lg-3, .py-lg-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-lg-3, .px-lg-3 {
    padding-left: 0.75rem !important;
  }
  .p-lg-4 {
    padding: 1rem !important;
  }
  .pt-lg-4, .py-lg-4 {
    padding-top: 1rem !important;
  }
  .pr-lg-4, .px-lg-4 {
    padding-right: 1rem !important;
  }
  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-4, .px-lg-4 {
    padding-left: 1rem !important;
  }
  .p-lg-5 {
    padding: 1.5rem !important;
  }
  .pt-lg-5, .py-lg-5 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-5, .px-lg-5 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-5, .py-lg-5 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-5, .px-lg-5 {
    padding-left: 1.5rem !important;
  }
  .p-lg-6 {
    padding: 2rem !important;
  }
  .pt-lg-6, .py-lg-6 {
    padding-top: 2rem !important;
  }
  .pr-lg-6, .px-lg-6 {
    padding-right: 2rem !important;
  }
  .pb-lg-6, .py-lg-6 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-6, .px-lg-6 {
    padding-left: 2rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .pt-lg-7, .py-lg-7 {
    padding-top: 3rem !important;
  }
  .pr-lg-7, .px-lg-7 {
    padding-right: 3rem !important;
  }
  .pb-lg-7, .py-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-7, .px-lg-7 {
    padding-left: 3rem !important;
  }
  .p-lg-8 {
    padding: 4rem !important;
  }
  .pt-lg-8, .py-lg-8 {
    padding-top: 4rem !important;
  }
  .pr-lg-8, .px-lg-8 {
    padding-right: 4rem !important;
  }
  .pb-lg-8, .py-lg-8 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-8, .px-lg-8 {
    padding-left: 4rem !important;
  }
  .p-lg-9 {
    padding: 6rem !important;
  }
  .pt-lg-9, .py-lg-9 {
    padding-top: 6rem !important;
  }
  .pr-lg-9, .px-lg-9 {
    padding-right: 6rem !important;
  }
  .pb-lg-9, .py-lg-9 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-9, .px-lg-9 {
    padding-left: 6rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1280px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1, .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1, .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1, .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1, .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2, .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2, .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2, .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2, .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .mt-xl-3, .my-xl-3 {
    margin-top: 0.75rem !important;
  }
  .mr-xl-3, .mx-xl-3 {
    margin-right: 0.75rem !important;
  }
  .mb-xl-3, .my-xl-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xl-3, .mx-xl-3 {
    margin-left: 0.75rem !important;
  }
  .m-xl-4 {
    margin: 1rem !important;
  }
  .mt-xl-4, .my-xl-4 {
    margin-top: 1rem !important;
  }
  .mr-xl-4, .mx-xl-4 {
    margin-right: 1rem !important;
  }
  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-4, .mx-xl-4 {
    margin-left: 1rem !important;
  }
  .m-xl-5 {
    margin: 1.5rem !important;
  }
  .mt-xl-5, .my-xl-5 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-5, .mx-xl-5 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-5, .my-xl-5 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-5, .mx-xl-5 {
    margin-left: 1.5rem !important;
  }
  .m-xl-6 {
    margin: 2rem !important;
  }
  .mt-xl-6, .my-xl-6 {
    margin-top: 2rem !important;
  }
  .mr-xl-6, .mx-xl-6 {
    margin-right: 2rem !important;
  }
  .mb-xl-6, .my-xl-6 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-6, .mx-xl-6 {
    margin-left: 2rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .mt-xl-7, .my-xl-7 {
    margin-top: 3rem !important;
  }
  .mr-xl-7, .mx-xl-7 {
    margin-right: 3rem !important;
  }
  .mb-xl-7, .my-xl-7 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-7, .mx-xl-7 {
    margin-left: 3rem !important;
  }
  .m-xl-8 {
    margin: 4rem !important;
  }
  .mt-xl-8, .my-xl-8 {
    margin-top: 4rem !important;
  }
  .mr-xl-8, .mx-xl-8 {
    margin-right: 4rem !important;
  }
  .mb-xl-8, .my-xl-8 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-8, .mx-xl-8 {
    margin-left: 4rem !important;
  }
  .m-xl-9 {
    margin: 6rem !important;
  }
  .mt-xl-9, .my-xl-9 {
    margin-top: 6rem !important;
  }
  .mr-xl-9, .mx-xl-9 {
    margin-right: 6rem !important;
  }
  .mb-xl-9, .my-xl-9 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-9, .mx-xl-9 {
    margin-left: 6rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1, .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1, .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1, .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1, .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2, .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2, .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2, .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2, .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .pt-xl-3, .py-xl-3 {
    padding-top: 0.75rem !important;
  }
  .pr-xl-3, .px-xl-3 {
    padding-right: 0.75rem !important;
  }
  .pb-xl-3, .py-xl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xl-3, .px-xl-3 {
    padding-left: 0.75rem !important;
  }
  .p-xl-4 {
    padding: 1rem !important;
  }
  .pt-xl-4, .py-xl-4 {
    padding-top: 1rem !important;
  }
  .pr-xl-4, .px-xl-4 {
    padding-right: 1rem !important;
  }
  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-4, .px-xl-4 {
    padding-left: 1rem !important;
  }
  .p-xl-5 {
    padding: 1.5rem !important;
  }
  .pt-xl-5, .py-xl-5 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-5, .px-xl-5 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-5, .py-xl-5 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-5, .px-xl-5 {
    padding-left: 1.5rem !important;
  }
  .p-xl-6 {
    padding: 2rem !important;
  }
  .pt-xl-6, .py-xl-6 {
    padding-top: 2rem !important;
  }
  .pr-xl-6, .px-xl-6 {
    padding-right: 2rem !important;
  }
  .pb-xl-6, .py-xl-6 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-6, .px-xl-6 {
    padding-left: 2rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .pt-xl-7, .py-xl-7 {
    padding-top: 3rem !important;
  }
  .pr-xl-7, .px-xl-7 {
    padding-right: 3rem !important;
  }
  .pb-xl-7, .py-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-7, .px-xl-7 {
    padding-left: 3rem !important;
  }
  .p-xl-8 {
    padding: 4rem !important;
  }
  .pt-xl-8, .py-xl-8 {
    padding-top: 4rem !important;
  }
  .pr-xl-8, .px-xl-8 {
    padding-right: 4rem !important;
  }
  .pb-xl-8, .py-xl-8 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-8, .px-xl-8 {
    padding-left: 4rem !important;
  }
  .p-xl-9 {
    padding: 6rem !important;
  }
  .pt-xl-9, .py-xl-9 {
    padding-top: 6rem !important;
  }
  .pr-xl-9, .px-xl-9 {
    padding-right: 6rem !important;
  }
  .pb-xl-9, .py-xl-9 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-9, .px-xl-9 {
    padding-left: 6rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

/*------ Widths, Margins & Paddings -------*/
.wd-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.w-150 {
  width: 150px !important;
}

.w-auto {
  width: auto !important;
}

.w-0 {
  width: 0 !important;
}

.w-1 {
  width: 0.25rem !important;
}

.w-2 {
  width: 0.5rem !important;
}

.fw-2 {
  width: 20px !important;
}

.w-3 {
  width: 0.75rem !important;
}

.w-4 {
  width: 1rem !important;
}

.w-5 {
  width: 1.5rem !important;
}

.w-6 {
  width: 2rem !important;
}

.w-7 {
  width: 3rem !important;
}

.w-8 {
  width: 4rem !important;
}

.w-9 {
  width: 6rem !important;
}

.w-100h {
  width: 100px !important;
}

.w-110 {
  width: 110px !important;
}

.w-200 {
  width: 200px !important;
}

.w-250 {
  width: 250px !important;
}

.w-300 {
  width: 300px !important;
}

.w-350 {
  width: 350px !important;
}

.w-400 {
  width: 400px !important;
}

.w-450 {
  width: 450px !important;
}

.w-500 {
  width: 500px !important;
}

.ht-5 {
  height: 5% !important;
}

.h-10 {
  height: 10% !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-50 {
  height: 50% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

.h-100vh {
  height: 100vh !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-0 {
  height: 0 !important;
}

.h-1 {
  height: 0.25rem !important;
}

.h-15 {
  height: 0.35rem !important;
}

.h-2 {
  height: 0.5rem !important;
}

.h-3 {
  height: 0.75rem !important;
}

.h-4 {
  height: 1rem !important;
}

.h-5 {
  height: 1.5rem !important;
}

.h-6 {
  height: 2rem !important;
}

.h-7 {
  height: 3rem !important;
}

.h-8 {
  height: 4rem !important;
}

.h-9 {
  height: 6rem !important;
}

.h-100h {
  height: 100px !important;
}

.h-130 {
  height: 130px !important;
}

.h-140 {
  height: 140px !important;
}

.h-150 {
  height: 150px !important;
}

.h-160 {
  height: 160px !important;
}

.h-170 {
  height: 170px !important;
}

.h-180 {
  height: 180px !important;
}

.h-200 {
  height: 200px !important;
}

.h-250 {
  height: 250px !important;
}

.h-300 {
  height: 300px !important;
}

.h-350 {
  height: 350px !important;
}

.h-370 {
  height: 370px !important;
}

.h-400 {
  height: 400px !important;
}

.h-420 {
  height: 420px !important;
}

.h-450 {
  height: 450px !important;
}

.h-500 {
  height: 500px !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-m10 {
  margin-top: -10rem !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1, .my-1 {
  margin-top: 0.25rem !important;
}

.mr-1, .mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1, .mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2, .my-2 {
  margin-top: 0.5rem !important;
}

.mr-2, .mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2, .mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.mt-3, .my-3 {
  margin-top: 0.75rem !important;
}

.mr-3, .mx-3 {
  margin-right: 0.75rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3, .mx-3 {
  margin-left: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1rem !important;
}

.m-5 {
  margin: 1.5rem !important;
}

.mt-5, .my-5 {
  margin-top: 1.5rem !important;
}

.mt-5-7, .my-5-7 {
  margin-top: 1.7rem !important;
}

.mr-5, .mx-5 {
  margin-right: 1.5rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 1.5rem !important;
}

.ml-5, .mx-5 {
  margin-left: 1.5rem !important;
}

.m-6 {
  margin: 2rem !important;
}

.mt-6, .my-6 {
  margin-top: 2rem !important;
}

.mr-6, .mx-6 {
  margin-right: 2rem !important;
}

.mb-6, .my-6 {
  margin-bottom: 2rem !important;
}

.ml-6, .mx-6 {
  margin-left: 2rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7, .my-7 {
  margin-top: 3rem !important;
}

.mr-7, .mx-7 {
  margin-right: 3rem !important;
}

.mb-7, .my-7 {
  margin-bottom: 3rem !important;
}

.ml-7, .mx-7 {
  margin-left: 3rem !important;
}

.m-8 {
  margin: 4rem !important;
}

.mt-8, .my-8 {
  margin-top: 4rem !important;
}

.mr-8, .mx-8 {
  margin-right: 4rem !important;
}

.mb-8, .my-8 {
  margin-bottom: 4rem !important;
}

.ml-8, .mx-8 {
  margin-left: 4rem !important;
}

.m-9 {
  margin: 6rem !important;
}

.mt-9, .my-9 {
  margin-top: 6rem !important;
}

.mr-9, .mx-9 {
  margin-right: 6rem !important;
}

.mb-9, .my-9 {
  margin-bottom: 6rem !important;
}

.ml-9, .mx-9 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem;
}

.pt-1, .py-1 {
  padding-top: 0.25rem !important;
}

.pr-1, .px-1 {
  padding-right: 0.25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1, .px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2, .py-2 {
  padding-top: 0.5rem !important;
}

.pr-2, .px-2 {
  padding-right: 0.5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2, .px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.pt-3, .py-3 {
  padding-top: 0.75rem !important;
}

.pr-3, .px-3 {
  padding-right: 0.75rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 0.75rem !important;
}

.pl-3, .px-3 {
  padding-left: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4, .py-4 {
  padding-top: 1rem !important;
}

.pr-4, .px-4 {
  padding-right: 1rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1rem !important;
}

.pl-4, .px-4 {
  padding-left: 1rem !important;
}

.p-5 {
  padding: 1.5rem !important;
}

.pt-5, .py-5 {
  padding-top: 1.5rem !important;
}

.pr-5, .px-5 {
  padding-right: 1.5rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 1.5rem !important;
}

.pl-5, .px-5 {
  padding-left: 1.5rem !important;
}

.p-6 {
  padding: 2rem !important;
}

.pt-6, .py-6 {
  padding-top: 2rem !important;
}

.pr-6, .px-6 {
  padding-right: 2rem !important;
}

.pb-6, .py-6 {
  padding-bottom: 2rem !important;
}

.pl-6, .px-6 {
  padding-left: 2rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.pt-7, .py-7 {
  padding-top: 3rem !important;
}

.pr-7, .px-7 {
  padding-right: 3rem !important;
}

.pb-7, .py-7 {
  padding-bottom: 3rem !important;
}

.pl-7, .px-7 {
  padding-left: 3rem !important;
}

.p-8 {
  padding: 4rem !important;
}

.pt-8, .py-8 {
  padding-top: 4rem !important;
}

.pr-8, .px-8 {
  padding-right: 4rem !important;
}

.pb-8, .py-8 {
  padding-bottom: 4rem !important;
}

.pl-8, .px-8 {
  padding-left: 4rem !important;
}

.p-9 {
  padding: 6rem !important;
}

.pt-9, .py-9 {
  padding-top: 6rem !important;
}

.pr-9, .px-9 {
  padding-right: 6rem !important;
}

.pt-10 {
  padding-top: 8rem !important;
}

.pr-10 {
  padding-right: 8rem !important;
}

.pl-10 {
  padding-left: 8rem !important;
}

.pb-9, .py-9 {
  padding-bottom: 6rem !important;
}

.pl-9, .px-9 {
  padding-left: 6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

/*------- Alignments & values-------*/
.text-monospace {
  font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-normal1 {
  font-weight: 500 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #f0f0f0 !important;
}

.text-success {
  color: #38cb89 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #15ca7c !important;
}

.text-info {
  color: #007BFF !important;
}

a.text-info:hover, a.text-info:focus {
  color: #1e1e2d !important;
}

.text-warning {
  color: #ffab00 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #c29d0b !important;
}

.text-danger {
  color: #ef4b4b !important;
  font-size: 12px;
  margin-top: 5px;
}

.is-danger {
  border: 1px solid #ef4b4b !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #df0812 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #1e1e2d !important;
}

.text-highlight {
  color: #007BFF;
}

a.text-dark:hover, a.text-dark:focus {
  color: #384b6e !important;
}

.text-body {
  color: #495057 !important;
}

.text-muted {
  color: #728096 !important;
}

.text-required {
  font-size: 8px;
  position: absolute;
  top: 1px;
  margin-left: 2px;
  color: red;
}

.icon-muted {
  color: #e2e2e9 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-white-80 {
  color: rgba(255, 255, 255, 0.8) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .container {
    max-width: none;
  }
  * {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  *::before, *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr, img {
    page-break-inside: avoid;
  }
  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }
  h2, h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body, .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table, .text-wrap table {
    border-collapse: collapse !important;
  }
  .table td, .text-wrap table td, .table th, .text-wrap table th {
    background-color: #fff !important;
  }
  .table-bordered th, .text-wrap table th, .table-bordered td, .text-wrap table td {
    border: 1px solid #ebecf1 !important;
  }
}
.background-white {
  background: #FFF !important;
}

.background-special {
  background: #f5f9fc !important;
}

.lead {
  line-height: 1.4;
}

.lead-3 {
  line-height: 1.3;
}

a {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip: ink;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
  color: inherit;
}

strong, b {
  font-weight: 600;
}

p, ul, ol {
  margin-bottom: 1em;
}

blockquote {
  margin-bottom: 1em;
  font-style: italic;
  color: #6e7687;
  padding-left: 2rem;
  border-left: 2px solid rgba(0, 40, 100, 0.12);
}
blockquote p {
  margin-bottom: 1rem;
}
blockquote cite {
  display: block;
  text-align: right;
}
blockquote cite:before {
  content: "â€” ";
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

img {
  max-width: 100%;
}

.text-wrap {
  font-size: 14px;
  line-height: 1.66;
}
.text-wrap > :first-child {
  margin-top: 0;
}
.text-wrap > :last-child {
  margin-bottom: 0;
}
.text-wrap > h1, .text-wrap > h2, .text-wrap > h3, .text-wrap > h4, .text-wrap > h5, .text-wrap > h6 {
  margin-top: 1em;
}

.section-nav {
  background-color: #f8f9fa;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  border: 1px solid #ebecf1;
  border-radius: 3px;
  list-style: none;
}
.section-nav:before {
  content: "Table of contents:";
  display: block;
  font-weight: 600;
}

.row-cards > .col, .row-cards > [class*=col-] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.row-deck > .col, .row-deck > [class*=col-] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.row-deck > .col .card, .row-deck > [class*=col-] .card {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.col-text {
  max-width: 48rem;
}

.col-login {
  max-width: 24rem;
}

.gutters-0 {
  margin-right: 0;
  margin-left: 0;
}
.gutters-0 > .col, .gutters-0 > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
.gutters-0 .card {
  margin-bottom: 0;
}

.gutters-xs {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}
.gutters-xs > .col, .gutters-xs > [class*=col-] {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}
.gutters-xs .card {
  margin-bottom: 0.5rem;
}

.gutters-sm {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
.gutters-sm > .col, .gutters-sm > [class*=col-] {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.gutters-sm .card {
  margin-bottom: 1rem;
}

.gutters-lg {
  margin-right: -1rem;
  margin-left: -1rem;
}
.gutters-lg > .col, .gutters-lg > [class*=col-] {
  padding-right: 1rem;
  padding-left: 1rem;
}
.gutters-lg .card {
  margin-bottom: 2rem;
}

.gutters-xl {
  margin-right: -1.5rem;
  margin-left: -1.5rem;
}
.gutters-xl > .col, .gutters-xl > [class*=col-] {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.gutters-xl .card {
  margin-bottom: 3rem;
}

.page {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
}

.page-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100%;
  margin: 0.75rem 0;
}

body.fixed-header .page {
  padding-top: 4.5rem;
}

@media (min-width: 1600px) {
  body.aside-opened .page {
    margin-right: 22rem;
  }
}
.page-main {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.pos-absolute {
  position: absolute;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.text-icon {
  color: #5c678f !important;
}

.divider {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 1rem 0;
}
.divider .divider-text {
  position: relative;
  display: inline-block;
  font-size: 12px;
  padding: 0 1rem;
  text-transform: uppercase;
}
.divider .divider-text:before {
  right: 100%;
}
.divider .divider-text:after {
  left: 100%;
}
.divider .divider-text:after, .divider .divider-text:before {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  border-top: 1px solid #dfe3e7;
}

/*-----Back to Top-----*/
#back-to-top {
  color: #fff;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 999;
  display: none;
  text-align: center;
  border-radius: 5px;
  -o-border-radius: 2px;
  height: 40px;
  width: 40px;
  line-height: 2.8;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color 0.1s linear;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}
#back-to-top i {
  font-size: 16px;
}

.tabs-menu .nav {
  display: inline-flex !important;
}

.tabs-menu ul li a {
  padding: 5px 20px;
  display: block;
  margin: 3px;
  border-radius: 4px;
  font-size: 12px;
  text-transform: uppercase;
  width: 100px;
}
.tabs-menu ul li .active {
  color: #fff !important;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}

.tabs-menu-body {
  padding: 15px;
  border-radius: 0 0 5px 5px;
}
.tabs-menu-body .price-card {
  border-radius: 0 !important;
  border-top-right-radius: 30px !important;
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 30px !important;
}
.tabs-menu-body .price-card-border {
  border: 2px solid #007BFF !important;
}
.tabs-menu-body .card {
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14) !important;
  transition: all 0.2s;
}
.tabs-menu-body .card:hover, .tabs-menu-body .card:focus {
  box-shadow: 0 0 12px rgba(33, 33, 33, 0.2) !important;
}
.tabs-menu-body .card:hover .plan-cost, .tabs-menu-body .card:focus .plan-cost {
  color: #007bff;
}
.tabs-menu-body .card:hover .prepaid-plan .plan-cost, .tabs-menu-body .card:focus .prepaid-plan .plan-cost {
  color: rgb(102, 102, 102);
}
.tabs-menu-body .card:hover .prepaid-plan .plan-title, .tabs-menu-body .card:focus .prepaid-plan .plan-title {
  color: #007bff;
}
.tabs-menu-body .card .plan {
  min-height: 380px;
}
.tabs-menu-body .card .plan-featured {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  border-bottom-right-radius: 1rem;
  border-top-left-radius: 0.8rem;
  min-width: 40%;
  padding-top: 3px;
  text-align: center;
  background-color: #007BFF;
}
.tabs-menu-body .card .plan-cost {
  font-size: 40px;
  font-weight: 800;
  color: rgb(102, 102, 102);
  font-family: "Open Sans", sans-serif;
}
.tabs-menu-body .card .plan-currency-sign {
  font-size: 20px;
  margin-bottom: 20px;
  position: absolute;
  top: 25px;
  color: #ccc;
  font-weight: 800;
  font-family: "Poppins", sans-serif;
}
.tabs-menu-body .card .divider {
  background-color: rgb(230, 230, 230);
  border-radius: 5px;
  height: 3px;
  width: 40px;
}
.tabs-menu-body .card .plan-title {
  font-size: 24px;
  font-weight: 800;
  font-family: "Open Sans";
  margin-bottom: 1rem;
}
.tabs-menu-body .card ul li {
  margin-bottom: 0.3rem;
}
.tabs-menu-body .card ul li i {
  margin-right: 0.3rem;
}
.tabs-menu-body .card .action-button button {
  width: 145px;
}
.tabs-menu-body .card .action-button a {
  width: 145px;
}
.tabs-menu-body .card .prepaid-plan {
  min-height: auto !important;
}
.tabs-menu-body .card .prepaid-plan .plan-title {
  margin-bottom: 0 !important;
}
.tabs-menu-body .card .prepaid-plan .plan-cost {
  float: right;
  padding-left: 2px !important;
  padding-right: 2px !important;
  font-size: 24px !important;
  font-family: "Open Sans", sans-serif;
}
.tabs-menu-body .card .prepaid-plan .prepaid-currency-sign {
  font-size: 10px !important;
  float: right;
  vertical-align: super;
  color: #ccc;
  margin-top: 6px;
  font-weight: 800;
  font-family: "Poppins", sans-serif;
}
.tabs-menu-body .card .prepaid-plan .plan-note {
  margin-top: -5px;
}
.tabs-menu-body .card .prepaid-plan .action-button {
  position: unset;
}
.tabs-menu-body p:last-child {
  margin-bottom: 0;
}

.tab-menu-heading {
  padding: 20px;
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
}

#back-to-top:hover {
  background: #fff;
  line-height: 3;
  border-color: #fff;
}
#back-to-top:hover i {
  color: #007bff;
}

/*------ Background colors -------*/
.bg-blue-lightest {
  background-color: #edf2fa !important;
}

a.bg-blue-lightest:hover, a.bg-blue-lightest:focus {
  background-color: #c5d5ef !important;
}

button.bg-blue-lightest:hover, button.bg-blue-lightest:focus {
  background-color: #c5d5ef !important;
}

.bg-blue-lighter {
  background-color: #c8d9f1 !important;
}

a.bg-blue-lighter:hover, a.bg-blue-lighter:focus {
  background-color: #9fbde7 !important;
}

button.bg-blue-lighter:hover, button.bg-blue-lighter:focus {
  background-color: #9fbde7 !important;
}

.bg-blue-light {
  background-color: #7ea5dd !important;
}

a.bg-blue-light:hover, a.bg-blue-light:focus {
  background-color: #5689d2 !important;
}

button.bg-blue-light:hover, button.bg-blue-light:focus {
  background-color: #5689d2 !important;
}

.bg-blue-dark {
  background-color: #3866a6 !important;
}

a.bg-blue-dark:hover, a.bg-blue-dark:focus {
  background-color: #2b4f80 !important;
}

button.bg-blue-dark:hover, button.bg-blue-dark:focus {
  background-color: #2b4f80 !important;
}

.bg-blue-darker {
  background-color: #1c3353 !important;
}

a.bg-blue-darker:hover, a.bg-blue-darker:focus {
  background-color: #0f1c2d !important;
}

button.bg-blue-darker:hover, button.bg-blue-darker:focus {
  background-color: #0f1c2d !important;
}

.bg-blue-darkest {
  background-color: #0e1929 !important;
}

a.bg-blue-darkest:hover, a.bg-blue-darkest:focus {
  background-color: #010203 !important;
}

.background-color {
  background-color: #f5f9fc;
}

.background-white {
  background-color: #FFF;
}

button.bg-blue-darkest:hover, button.bg-blue-darkest:focus {
  background-color: #010203 !important;
}

.bg-purssianblue {
  background-color: #362f71;
}

a.bg-purssianblue-lightest:hover, a.bg-purssianblue-lightest:focus {
  background-color: #3f3688 !important;
}

button.bg-purssianblue-lightest:hover, button.bg-purssianblue-lightest:focus {
  background-color: #3f3688 !important;
}

.bg-indigo-lightest {
  background-color: #f0f1fa !important;
}

a.bg-indigo-lightest:hover, a.bg-indigo-lightest:focus {
  background-color: #cacded !important;
}

button.bg-indigo-lightest:hover, button.bg-indigo-lightest:focus {
  background-color: #cacded !important;
}

.bg-indigo-lighter {
  background-color: #d1d5f0 !important;
}

a.bg-indigo-lighter:hover, a.bg-indigo-lighter:focus {
  background-color: #abb2e3 !important;
}

button.bg-indigo-lighter:hover, button.bg-indigo-lighter:focus {
  background-color: #abb2e3 !important;
}

.bg-indigo-light {
  background-color: #939edc !important;
}

a.bg-indigo-light:hover, a.bg-indigo-light:focus {
  background-color: #6c7bd0 !important;
}

button.bg-indigo-light:hover, button.bg-indigo-light:focus {
  background-color: #6c7bd0 !important;
}

.bg-indigo-dark {
  background-color: #515da4 !important;
}

a.bg-indigo-dark:hover, a.bg-indigo-dark:focus {
  background-color: #404a82 !important;
}

button.bg-indigo-dark:hover, button.bg-indigo-dark:focus {
  background-color: #404a82 !important;
}

.bg-indigo-darker {
  background-color: #282e52 !important;
}

a.bg-indigo-darker:hover, a.bg-indigo-darker:focus {
  background-color: #171b30 !important;
}

button.bg-indigo-darker:hover, button.bg-indigo-darker:focus {
  background-color: #171b30 !important;
}

.bg-indigo-darkest {
  background-color: #141729 !important;
}

a.bg-indigo-darkest:hover, a.bg-indigo-darkest:focus {
  background-color: #030407 !important;
}

button.bg-indigo-darkest:hover, button.bg-indigo-darkest:focus {
  background-color: #030407 !important;
}

.bg-purple-lightest {
  background-color: #f6effd !important;
}

a.bg-purple-lightest:hover, a.bg-purple-lightest:focus {
  background-color: #ddc2f7 !important;
}

button.bg-purple-lightest:hover, button.bg-purple-lightest:focus {
  background-color: #ddc2f7 !important;
}

.bg-purple-lighter {
  background-color: #e4cff9 !important;
}

a.bg-purple-lighter:hover, a.bg-purple-lighter:focus {
  background-color: #cba2f3 !important;
}

button.bg-purple-lighter:hover, button.bg-purple-lighter:focus {
  background-color: #cba2f3 !important;
}

.bg-purple-light {
  background-color: #c08ef0 !important;
}

a.bg-purple-light:hover, a.bg-purple-light:focus {
  background-color: #a761ea !important;
}

button.bg-purple-light:hover, button.bg-purple-light:focus {
  background-color: #a761ea !important;
}

.bg-purple-dark {
  background-color: #844bbb !important;
}

a.bg-purple-dark:hover, a.bg-purple-dark:focus {
  background-color: #6a3a99 !important;
}

button.bg-purple-dark:hover, button.bg-purple-dark:focus {
  background-color: #6a3a99 !important;
}

.bg-purple-darker {
  background-color: #42265e !important;
}

a.bg-purple-darker:hover, a.bg-purple-darker:focus {
  background-color: #29173a !important;
}

button.bg-purple-darker:hover, button.bg-purple-darker:focus {
  background-color: #29173a !important;
}

.bg-purple-darkest {
  background-color: #21132f !important;
}

a.bg-purple-darkest:hover, a.bg-purple-darkest:focus {
  background-color: #08040b !important;
}

button.bg-purple-darkest:hover, button.bg-purple-darkest:focus {
  background-color: #08040b !important;
}

.bg-pink-lightest {
  background-color: #fef0f5 !important;
}

a.bg-pink-lightest:hover, a.bg-pink-lightest:focus {
  background-color: #fbc0d5 !important;
}

button.bg-pink-lightest:hover, button.bg-pink-lightest:focus {
  background-color: #fbc0d5 !important;
}

.bg-pink-lighter {
  background-color: #fcd3e1 !important;
}

a.bg-pink-lighter:hover, a.bg-pink-lighter:focus {
  background-color: #f9a3c0 !important;
}

button.bg-pink-lighter:hover, button.bg-pink-lighter:focus {
  background-color: #f9a3c0 !important;
}

.bg-pink-light {
  background-color: #f999b9 !important;
}

a.bg-pink-light:hover, a.bg-pink-light:focus {
  background-color: #f66998 !important;
}

button.bg-pink-light:hover, button.bg-pink-light:focus {
  background-color: #f66998 !important;
}

.bg-pink-dark {
  background-color: #c5577c !important;
}

a.bg-pink-dark:hover, a.bg-pink-dark:focus {
  background-color: #ad3c62 !important;
}

button.bg-pink-dark:hover, button.bg-pink-dark:focus {
  background-color: #ad3c62 !important;
}

.bg-pink-darker {
  background-color: #622c3e !important;
}

a.bg-pink-darker:hover, a.bg-pink-darker:focus {
  background-color: #3f1c28 !important;
}

button.bg-pink-darker:hover, button.bg-pink-darker:focus {
  background-color: #3f1c28 !important;
}

.bg-pink-darkest {
  background-color: #31161f !important;
}

a.bg-pink-darkest:hover, a.bg-pink-darkest:focus {
  background-color: #0e0609 !important;
}

button.bg-pink-darkest:hover, button.bg-pink-darkest:focus {
  background-color: #0e0609 !important;
}

.bg-red-lightest {
  background-color: #fae9e9 !important;
}

a.bg-red-lightest:hover, a.bg-red-lightest:focus {
  background-color: #f1bfbf !important;
}

button.bg-red-lightest:hover, button.bg-red-lightest:focus {
  background-color: #f1bfbf !important;
}

.bg-red-lighter {
  background-color: #f0bcbc !important;
}

a.bg-red-lighter:hover, a.bg-red-lighter:focus {
  background-color: #e79292 !important;
}

button.bg-red-lighter:hover, button.bg-red-lighter:focus {
  background-color: #e79292 !important;
}

.bg-red-light {
  background-color: #dc6362 !important;
}

a.bg-red-light:hover, a.bg-red-light:focus {
  background-color: #d33a38 !important;
}

button.bg-red-light:hover, button.bg-red-light:focus {
  background-color: #d33a38 !important;
}

.bg-red-dark {
  background-color: #a41a19 !important;
}

a.bg-red-dark:hover, a.bg-red-dark:focus {
  background-color: #781312 !important;
}

button.bg-red-dark:hover, button.bg-red-dark:focus {
  background-color: #781312 !important;
}

.bg-red-darker {
  background-color: #520d0c !important;
}

a.bg-red-darker:hover, a.bg-red-darker:focus {
  background-color: #260605 !important;
}

button.bg-red-darker:hover, button.bg-red-darker:focus {
  background-color: #260605 !important;
}

.bg-red-darkest {
  background-color: #290606 !important;
}

a.bg-red-darkest:hover, a.bg-red-darkest:focus {
  background-color: black !important;
}

button.bg-red-darkest:hover, button.bg-red-darkest:focus {
  background-color: black !important;
}

.bg-orange-lightest {
  background-color: #fff !important;
}

a.bg-orange-lightest:hover, a.bg-orange-lightest:focus {
  background-color: peachpuff !important;
}

button.bg-orange-lightest:hover, button.bg-orange-lightest:focus {
  background-color: peachpuff !important;
}

.bg-orange-lighter {
  background-color: #fee0c7 !important;
}

a.bg-orange-lighter:hover, a.bg-orange-lighter:focus {
  background-color: #fdc495 !important;
}

button.bg-orange-lighter:hover, button.bg-orange-lighter:focus {
  background-color: #fdc495 !important;
}

.bg-orange-light {
  background-color: #feb67c !important;
}

a.bg-orange-light:hover, a.bg-orange-light:focus {
  background-color: #fe9a49 !important;
}

button.bg-orange-light:hover, button.bg-orange-light:focus {
  background-color: #fe9a49 !important;
}

.bg-orange-dark {
  background-color: #ca7836 !important;
}

a.bg-orange-dark:hover, a.bg-orange-dark:focus {
  background-color: #a2602b !important;
}

button.bg-orange-dark:hover, button.bg-orange-dark:focus {
  background-color: #a2602b !important;
}

.bg-orange-darker {
  background-color: #653c1b !important;
}

a.bg-orange-darker:hover, a.bg-orange-darker:focus {
  background-color: #3d2410 !important;
}

button.bg-orange-darker:hover, button.bg-orange-darker:focus {
  background-color: #3d2410 !important;
}

.bg-orange-darkest {
  background-color: #331e0e !important;
}

a.bg-orange-darkest:hover, a.bg-orange-darkest:focus {
  background-color: #0b0603 !important;
}

button.bg-orange-darkest:hover, button.bg-orange-darkest:focus {
  background-color: #0b0603 !important;
}

.bg-yellow-lightest {
  background-color: #fef9e7 !important;
}

a.bg-yellow-lightest:hover, a.bg-yellow-lightest:focus {
  background-color: #fcedb6 !important;
}

button.bg-yellow-lightest:hover, button.bg-yellow-lightest:focus {
  background-color: #fcedb6 !important;
}

.bg-yellow-lighter {
  background-color: #fbedb7 !important;
}

a.bg-yellow-lighter:hover, a.bg-yellow-lighter:focus {
  background-color: #f8e187 !important;
}

button.bg-yellow-lighter:hover, button.bg-yellow-lighter:focus {
  background-color: #f8e187 !important;
}

.bg-yellow-light {
  background-color: #f5d657 !important;
}

a.bg-yellow-light:hover, a.bg-yellow-light:focus {
  background-color: #f2ca27 !important;
}

button.bg-yellow-light:hover, button.bg-yellow-light:focus {
  background-color: #f2ca27 !important;
}

.bg-yellow-dark {
  background-color: #c19d0c !important;
}

a.bg-yellow-dark:hover, a.bg-yellow-dark:focus {
  background-color: #917609 !important;
}

button.bg-yellow-dark:hover, button.bg-yellow-dark:focus {
  background-color: #917609 !important;
}

.bg-yellow-darker {
  background-color: #604e06 !important;
}

a.bg-yellow-darker:hover, a.bg-yellow-darker:focus {
  background-color: #302703 !important;
}

button.bg-yellow-darker:hover, button.bg-yellow-darker:focus {
  background-color: #302703 !important;
}

.bg-yellow-darkest {
  background-color: #302703 !important;
}

a.bg-yellow-darkest:hover, a.bg-yellow-darkest:focus {
  background-color: black !important;
}

button.bg-yellow-darkest:hover, button.bg-yellow-darkest:focus {
  background-color: black !important;
}

.bg-green-lightest {
  background-color: #eff8e6 !important;
}

a.bg-green-lightest:hover, a.bg-green-lightest:focus {
  background-color: #d6edbe !important;
}

button.bg-green-lightest:hover, button.bg-green-lightest:focus {
  background-color: #d6edbe !important;
}

.bg-green-lighter {
  background-color: #cfeab3 !important;
}

a.bg-green-lighter:hover, a.bg-green-lighter:focus {
  background-color: #b6df8b !important;
}

button.bg-green-lighter:hover, button.bg-green-lighter:focus {
  background-color: #b6df8b !important;
}

.bg-green-light {
  background-color: #8ecf4d !important;
}

a.bg-green-light:hover, a.bg-green-light:focus {
  background-color: #75b831 !important;
}

button.bg-green-light:hover, button.bg-green-light:focus {
  background-color: #75b831 !important;
}

.bg-green-dark {
  background-color: #4b9500 !important;
}

a.bg-green-dark:hover, a.bg-green-dark:focus {
  background-color: #316200 !important;
}

button.bg-green-dark:hover, button.bg-green-dark:focus {
  background-color: #316200 !important;
}

.bg-green-darker {
  background-color: #264a00 !important;
}

a.bg-green-darker:hover, a.bg-green-darker:focus {
  background-color: #0c1700 !important;
}

button.bg-green-darker:hover, button.bg-green-darker:focus {
  background-color: #0c1700 !important;
}

.bg-green-darkest {
  background-color: #132500 !important;
}

a.bg-green-darkest:hover, a.bg-green-darkest:focus {
  background-color: black !important;
}

button.bg-green-darkest:hover, button.bg-green-darkest:focus {
  background-color: black !important;
}

.bg-teal-lightest {
  background-color: #eafaf8 !important;
}

a.bg-teal-lightest:hover, a.bg-teal-lightest:focus {
  background-color: #c1f0ea !important;
}

button.bg-teal-lightest:hover, button.bg-teal-lightest:focus {
  background-color: #c1f0ea !important;
}

.bg-teal-lighter {
  background-color: #bfefea !important;
}

a.bg-teal-lighter:hover, a.bg-teal-lighter:focus {
  background-color: #96e5dd !important;
}

button.bg-teal-lighter:hover, button.bg-teal-lighter:focus {
  background-color: #96e5dd !important;
}

.bg-teal-light {
  background-color: #6bdbcf !important;
}

a.bg-teal-light:hover, a.bg-teal-light:focus {
  background-color: #42d1c2 !important;
}

button.bg-teal-light:hover, button.bg-teal-light:focus {
  background-color: #42d1c2 !important;
}

.bg-teal-dark {
  background-color: #22a295 !important;
}

a.bg-teal-dark:hover, a.bg-teal-dark:focus {
  background-color: #19786e !important;
}

button.bg-teal-dark:hover, button.bg-teal-dark:focus {
  background-color: #19786e !important;
}

.bg-teal-darker {
  background-color: #11514a !important;
}

a.bg-teal-darker:hover, a.bg-teal-darker:focus {
  background-color: #082723 !important;
}

button.bg-teal-darker:hover, button.bg-teal-darker:focus {
  background-color: #082723 !important;
}

.bg-teal-darkest {
  background-color: #092925 !important;
}

a.bg-teal-darkest:hover, a.bg-teal-darkest:focus {
  background-color: black !important;
}

button.bg-teal-darkest:hover, button.bg-teal-darkest:focus {
  background-color: black !important;
}

.bg-cyan-lightest {
  background-color: #e8f6f8 !important;
}

a.bg-cyan-lightest:hover, a.bg-cyan-lightest:focus {
  background-color: #c1e7ec !important;
}

button.bg-cyan-lightest:hover, button.bg-cyan-lightest:focus {
  background-color: #c1e7ec !important;
}

.bg-cyan-lighter {
  background-color: #b9e3ea !important;
}

a.bg-cyan-lighter:hover, a.bg-cyan-lighter:focus {
  background-color: #92d3de !important;
}

button.bg-cyan-lighter:hover, button.bg-cyan-lighter:focus {
  background-color: #92d3de !important;
}

.bg-cyan-light {
  background-color: #5dbecd !important;
}

a.bg-cyan-light:hover, a.bg-cyan-light:focus {
  background-color: #3aabbd !important;
}

button.bg-cyan-light:hover, button.bg-cyan-light:focus {
  background-color: #3aabbd !important;
}

.bg-cyan-dark {
  background-color: #128293 !important;
}

a.bg-cyan-dark:hover, a.bg-cyan-dark:focus {
  background-color: #0c5a66 !important;
}

button.bg-cyan-dark:hover, button.bg-cyan-dark:focus {
  background-color: #0c5a66 !important;
}

.bg-cyan-darker {
  background-color: #09414a !important;
}

a.bg-cyan-darker:hover, a.bg-cyan-darker:focus {
  background-color: #03191d !important;
}

button.bg-cyan-darker:hover, button.bg-cyan-darker:focus {
  background-color: #03191d !important;
}

.bg-cyan-darkest {
  background-color: #052025 !important;
}

a.bg-cyan-darkest:hover, a.bg-cyan-darkest:focus {
  background-color: black !important;
}

button.bg-cyan-darkest:hover, button.bg-cyan-darkest:focus {
  background-color: black !important;
}

.bg-white-lightest {
  background-color: white !important;
}

a.bg-white-lightest:hover, a.bg-white-lightest:focus {
  background-color: #e6e5e5 !important;
}

button.bg-white-lightest:hover, button.bg-white-lightest:focus {
  background-color: #e6e5e5 !important;
}

.bg-white-lighter {
  background-color: white !important;
}

a.bg-white-lighter:hover, a.bg-white-lighter:focus {
  background-color: #e6e5e5 !important;
}

button.bg-white-lighter:hover, button.bg-white-lighter:focus {
  background-color: #e6e5e5 !important;
}

.bg-white-light {
  background-color: white !important;
}

a.bg-white-light:hover, a.bg-white-light:focus {
  background-color: #e6e5e5 !important;
}

button.bg-white-light:hover, button.bg-white-light:focus {
  background-color: #e6e5e5 !important;
}

.bg-white-dark {
  background-color: #cccccc !important;
}

a.bg-white-dark:hover, a.bg-white-dark:focus {
  background-color: #b3b2b2 !important;
}

button.bg-white-dark:hover, button.bg-white-dark:focus {
  background-color: #b3b2b2 !important;
}

.bg-white-darker {
  background-color: #666666 !important;
}

a.bg-white-darker:hover, a.bg-white-darker:focus {
  background-color: #4d4c4c !important;
}

button.bg-white-darker:hover, button.bg-white-darker:focus {
  background-color: #4d4c4c !important;
}

.bg-white-darkest {
  background-color: #333333 !important;
}

a.bg-white-darkest:hover, a.bg-white-darkest:focus {
  background-color: #1a1919 !important;
}

button.bg-white-darkest:hover, button.bg-white-darkest:focus {
  background-color: #1a1919 !important;
}

.bg-gray-lightest {
  background-color: #f3f4f5 !important;
}

a.bg-gray-lightest:hover, a.bg-gray-lightest:focus {
  background-color: #d7dbde !important;
}

button.bg-gray-lightest:hover, button.bg-gray-lightest:focus {
  background-color: #d7dbde !important;
}

.bg-gray-lighter {
  background-color: #dbdde0 !important;
}

a.bg-gray-lighter:hover, a.bg-gray-lighter:focus {
  background-color: #c0c3c8 !important;
}

button.bg-gray-lighter:hover, button.bg-gray-lighter:focus {
  background-color: #c0c3c8 !important;
}

.bg-gray-light {
  background-color: #aab0b6 !important;
}

a.bg-gray-light:hover, a.bg-gray-light:focus {
  background-color: #8f979e !important;
}

button.bg-gray-light:hover, button.bg-gray-light:focus {
  background-color: #8f979e !important;
}

.bg-gray-dark {
  background-color: #6b7278 !important;
  background-color: #343a40 !important;
}

a.bg-gray-dark:hover, a.bg-gray-dark:focus {
  background-color: #53585d !important;
  background-color: #1d2124 !important;
}

button.bg-gray-dark:hover, button.bg-gray-dark:focus {
  background-color: #53585d !important;
  background-color: #1d2124 !important;
}

.bg-gray-darker {
  background-color: #36393c !important;
}

a.bg-gray-darker:hover, a.bg-gray-darker:focus {
  background-color: #1e2021 !important;
}

button.bg-gray-darker:hover, button.bg-gray-darker:focus {
  background-color: #1e2021 !important;
}

.bg-gray-darkest {
  background-color: #1b1c1e !important;
}

a.bg-gray-darkest:hover, a.bg-gray-darkest:focus {
  background-color: #030303 !important;
}

button.bg-gray-darkest:hover, button.bg-gray-darkest:focus {
  background-color: #030303 !important;
}

.bg-gray-dark-lightest {
  background-color: #ebebec !important;
}

a.bg-gray-dark-lightest:hover, a.bg-gray-dark-lightest:focus {
  background-color: #d1d1d3 !important;
}

button.bg-gray-dark-lightest:hover, button.bg-gray-dark-lightest:focus {
  background-color: #d1d1d3 !important;
}

.bg-gray-dark-lighter {
  background-color: #c2c4c6 !important;
}

a.bg-gray-dark-lighter:hover, a.bg-gray-dark-lighter:focus {
  background-color: #a8abad !important;
}

button.bg-gray-dark-lighter:hover, button.bg-gray-dark-lighter:focus {
  background-color: #a8abad !important;
}

.bg-gray-dark-light {
  background-color: #717579 !important;
}

a.bg-gray-dark-light:hover, a.bg-gray-dark-light:focus {
  background-color: #585c5f !important;
}

button.bg-gray-dark-light:hover, button.bg-gray-dark-light:focus {
  background-color: #585c5f !important;
}

.bg-gray-dark-dark {
  background-color: #2a2e33 !important;
}

a.bg-gray-dark-dark:hover, a.bg-gray-dark-dark:focus {
  background-color: #131517 !important;
}

button.bg-gray-dark-dark:hover, button.bg-gray-dark-dark:focus {
  background-color: #131517 !important;
}

.bg-gray-dark-darker {
  background-color: #15171a !important;
}

a.bg-gray-dark-darker:hover, a.bg-gray-dark-darker:focus {
  background-color: black !important;
}

button.bg-gray-dark-darker:hover, button.bg-gray-dark-darker:focus {
  background-color: black !important;
}

.bg-gray-dark-darkest {
  background-color: #0a0c0d !important;
}

a.bg-gray-dark-darkest:hover, a.bg-gray-dark-darkest:focus {
  background-color: black !important;
}

button.bg-gray-dark-darkest:hover, button.bg-gray-dark-darkest:focus {
  background-color: black !important;
}

.bg-azure-lightest {
  background-color: #ecf7fe !important;
}

a.bg-azure-lightest:hover, a.bg-azure-lightest:focus {
  background-color: #bce3fb !important;
}

button.bg-azure-lightest:hover, button.bg-azure-lightest:focus {
  background-color: #bce3fb !important;
}

.bg-azure-lighter {
  background-color: #c7e6fb !important;
}

a.bg-azure-lighter:hover, a.bg-azure-lighter:focus {
  background-color: #97d1f8 !important;
}

button.bg-azure-lighter:hover, button.bg-azure-lighter:focus {
  background-color: #97d1f8 !important;
}

.bg-azure-light {
  background-color: #7dc4f6 !important;
}

a.bg-azure-light:hover, a.bg-azure-light:focus {
  background-color: #4daef3 !important;
}

button.bg-azure-light:hover, button.bg-azure-light:focus {
  background-color: #4daef3 !important;
}

.bg-azure-dark {
  background-color: #3788c2 !important;
}

a.bg-azure-dark:hover, a.bg-azure-dark:focus {
  background-color: #2c6c9a !important;
}

button.bg-azure-dark:hover, button.bg-azure-dark:focus {
  background-color: #2c6c9a !important;
}

.bg-azure-darker {
  background-color: #1c4461 !important;
}

a.bg-azure-darker:hover, a.bg-azure-darker:focus {
  background-color: #112839 !important;
}

button.bg-azure-darker:hover, button.bg-azure-darker:focus {
  background-color: #112839 !important;
}

.bg-azure-darkest {
  background-color: #0e2230 !important;
}

a.bg-azure-darkest:hover, a.bg-azure-darkest:focus {
  background-color: #020609 !important;
}

button.bg-azure-darkest:hover, button.bg-azure-darkest:focus {
  background-color: #020609 !important;
}

.bg-lime-lightest {
  background-color: #f2fbeb !important;
}

a.bg-lime-lightest:hover, a.bg-lime-lightest:focus {
  background-color: #d6f3c1 !important;
}

button.bg-lime-lightest:hover, button.bg-lime-lightest:focus {
  background-color: #d6f3c1 !important;
}

.bg-lime-lighter {
  background-color: #d7f2c2 !important;
}

a.bg-lime-lighter:hover, a.bg-lime-lighter:focus {
  background-color: #bbe998 !important;
}

button.bg-lime-lighter:hover, button.bg-lime-lighter:focus {
  background-color: #bbe998 !important;
}

.bg-lime-light {
  background-color: #a3e072 !important;
}

a.bg-lime-light:hover, a.bg-lime-light:focus {
  background-color: #88d748 !important;
}

button.bg-lime-light:hover, button.bg-lime-light:focus {
  background-color: #88d748 !important;
}

.bg-lime-dark {
  background-color: #62a82a !important;
}

a.bg-lime-dark:hover, a.bg-lime-dark:focus {
  background-color: #4a7f20 !important;
}

button.bg-lime-dark:hover, button.bg-lime-dark:focus {
  background-color: #4a7f20 !important;
}

.bg-lime-darker {
  background-color: #315415 !important;
}

a.bg-lime-darker:hover, a.bg-lime-darker:focus {
  background-color: #192b0b !important;
}

button.bg-lime-darker:hover, button.bg-lime-darker:focus {
  background-color: #192b0b !important;
}

.bg-lime-darkest {
  background-color: #192a0b !important;
}

a.bg-lime-darkest:hover, a.bg-lime-darkest:focus {
  background-color: #010200 !important;
}

button.bg-lime-darkest:hover, button.bg-lime-darkest:focus {
  background-color: #010200 !important;
}

.display-1 i, .display-2 i, .display-3 i, .display-4 i {
  vertical-align: baseline;
  font-size: 0.815em;
}

.text-inherit {
  color: inherit !important;
}

.text-default {
  color: #4e5761 !important;
}

.text-muted-dark {
  color: #6e7687 !important;
}

.tracking-tight {
  letter-spacing: -0.05em !important;
}

.tracking-normal {
  letter-spacing: 0 !important;
}

.tracking-wide {
  letter-spacing: 0.05em !important;
}

.leading-none {
  line-height: 1 !important;
}

.leading-tight {
  line-height: 1.25 !important;
}

.leading-normal {
  line-height: 1.5 !important;
}

.leading-loose {
  line-height: 2 !important;
}

.bg-blue {
  background-color: #083dc1 !important;
  color: #fff !important;
}

a.bg-blue:hover, a.bg-blue:focus {
  background-color: #1747bd !important;
}

button.bg-blue:hover, button.bg-blue:focus {
  background-color: #1747bd !important;
}

.text-blue {
  color: #083dc1 !important;
}

.bg-indigo {
  background-color: #5323a7 !important;
  color: #fff !important;
}

a.bg-indigo:hover, a.bg-indigo:focus {
  background-color: #3f51c1 !important;
}

button.bg-indigo:hover, button.bg-indigo:focus {
  background-color: #3f51c1 !important;
}

.text-indigo {
  color: #5323a7 !important;
}

.bg-purple {
  background-color: #920bfa !important;
  color: #fff !important;
}

a.bg-purple:hover, a.bg-purple:focus {
  background-color: #8c31e4 !important;
}

button.bg-purple:hover, button.bg-purple:focus {
  background-color: #8c31e4 !important;
}

.text-purple {
  color: #920bfa !important;
}

.text-lightpink-red {
  color: #ff7088 !important;
}

.text-lightgreen {
  color: #26eda2 !important;
}

.bg-pink {
  background-color: #bb08c0 !important;
  color: #fff !important;
}

.bg-darkpink {
  background-color: #14A485 !important;
  color: #fff !important;
}

a.bg-pink:hover, a.bg-pink:focus {
  background-color: #f33d7a !important;
}

button.bg-pink:hover, button.bg-pink:focus {
  background-color: #f33d7a !important;
}

.text-pink {
  color: #bb08c0 !important;
}

.bg-red {
  background-color: #dc0441 !important;
  color: #fff !important;
}

a.bg-red:hover, a.bg-red:focus {
  background-color: #a11918 !important;
}

button.bg-red:hover, button.bg-red:focus {
  background-color: #a11918 !important;
}

.text-red {
  color: #dc0441 !important;
}

.bg-orange {
  background-color: #fc7303 !important;
  color: #fff !important;
}

a.bg-orange:hover, a.bg-orange:focus {
  background-color: #fc7a12 !important;
}

button.bg-orange:hover, button.bg-orange:focus {
  background-color: #fc7a12 !important;
}

.text-orange {
  color: #fc7303 !important;
}

.bg-yellow {
  background-color: #ecb403 !important;
  color: #fff !important;
}

a.bg-yellow:hover, a.bg-yellow:focus {
  background-color: #c29d0b !important;
}

button.bg-yellow:hover, button.bg-yellow:focus {
  background-color: #c29d0b !important;
}

.text-yellow {
  color: #ffab00 !important;
}

.bg-green {
  background-color: #2dce89 !important;
  color: #fff !important;
}

a.bg-green:hover, a.bg-green:focus {
  background-color: #15ca7c !important;
}

button.bg-green:hover, button.bg-green:focus {
  background-color: #15ca7c !important;
}

.text-green {
  color: #2dce89 !important;
}

.bg-teal {
  background-color: #06c0d9 !important;
}

a.bg-teal:hover, a.bg-teal:focus {
  background-color: #22a193 !important;
}

button.bg-teal:hover, button.bg-teal:focus {
  background-color: #22a193 !important;
}

.text-teal {
  color: #06c0d9 !important;
}

.bg-cyan {
  background-color: #17a2b8 !important;
  color: #fff !important;
}

a.bg-cyan:hover, a.bg-cyan:focus {
  background-color: #117a8b !important;
}

button.bg-cyan:hover, button.bg-cyan:focus {
  background-color: #117a8b !important;
}

.text-cyan {
  color: #17a2b8 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus {
  background-color: #f3f4f5 !important;
}

button.bg-white:hover, button.bg-white:focus {
  background-color: #f3f4f5 !important;
}

.text-white {
  color: #fff !important;
}

.bg-gray {
  background-color: #868e96 !important;
}

a.bg-gray:hover, a.bg-gray:focus {
  background-color: #6c757d !important;
}

button.bg-gray:hover, button.bg-gray:focus {
  background-color: #6c757d !important;
}

.text-gray {
  color: #969696 !important;
}

.bg-lightpink-red {
  color: #ff7088 !important;
}

.text-gray-dark {
  color: #343a40 !important;
}

.bg-azure {
  background-color: #45aaf2 !important;
}

a.bg-azure:hover, a.bg-azure:focus {
  background-color: #1594ef !important;
}

button.bg-azure:hover, button.bg-azure:focus {
  background-color: #1594ef !important;
}

.text-azure {
  color: #45aaf2 !important;
}

.bg-lime {
  background-color: #1e74a0 !important;
}

a.bg-lime:hover, a.bg-lime:focus {
  background-color: #63ad27 !important;
}

button.bg-lime:hover, button.bg-lime:focus {
  background-color: #63ad27 !important;
}

.text-lime {
  color: #1e74a0 !important;
}

.icon {
  color: #fff !important;
}
.icon i {
  vertical-align: -1px;
  color: #5c678f;
}

a.icon {
  text-decoration: none;
  cursor: pointer;
}
a.icon:hover {
  color: #fff !important;
}

.o-auto {
  overflow: auto !important;
}

.o-hidden {
  overflow: hidden !important;
}

.shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}

.shadow2 {
  box-shadow: 0px 10px 10px 20px rgba(179, 186, 212, 0), 10px 10px 15px -5px rgb(176, 184, 214) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.bg-info-green {
  background-color: rgba(0, 200, 81, 0.4);
}

.bg-success-transparent {
  background-color: rgba(56, 203, 137, 0.2) !important;
}

.bg-info-transparent {
  background-color: rgba(34, 133, 204, 0.2) !important;
}

.bg-warning-transparent {
  background-color: rgba(252, 191, 9, 0.2) !important;
}

.bg-danger-transparent {
  background-color: rgba(250, 96, 96, 0.2) !important;
}

.bg-success-transparent-2 {
  background-color: rgba(56, 203, 137, 0.15) !important;
}

.bg-info-transparent-2 {
  background-color: rgba(34, 133, 204, 0.15) !important;
}

.bg-warning-transparent-2 {
  background-color: rgba(252, 191, 9, 0.15) !important;
}

.bg-danger-transparent-2 {
  background-color: rgba(250, 96, 96, 0.15) !important;
}

.bg-facebook {
  background: #2b4170 !important;
}

.bg-google {
  background: linear-gradient(to bottom right, #e64522, #c33219) !important;
  color: #fff;
}

.bg-dribbble {
  background: linear-gradient(to bottom right, #ea4c89, #c93764) !important;
}

/*------ Default -------*/
.card {
  font-family: "Lato", sans-serif;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
  border: 1px solid #dbe2eb;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.special-shadow {
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  margin-top: 1rem;
}

.card-subtitle {
  margin-top: -0.75rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-header:before {
  content: "";
  position: absolute;
  left: 0px;
  padding: 3px;
  border-radius: 0 50px 50px 0;
  height: 20px;
}
.card-header:first-child {
  border-radius: 2px 2px 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0rem 1.5rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.03);
  color: #6e7687;
}
.card-footer:last-child {
  border-radius: 0 0 2px 2px;
}

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -1.5rem;
  margin-left: -0.75rem;
  border-bottom: 0;
  margin: -1.25rem 0;
  border-bottom: 0;
  line-height: 2rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  background-color: rgba(0, 0, 0, 0.4);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-img {
  width: 100%;
  border-radius: 2px;
}

.card-img-top {
  width: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 0.75rem;
}

@media (max-width: 576px) {
  .header {
    border-bottom: 0px !important;
  }
}
.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 0.75rem;
    margin-bottom: 0;
    margin-left: 0.75rem;
  }
  .horizontalMenucontainer .header-brand-img.mobile-logo, .horizontalMenucontainer .header-brand-img.darkmobile-logo, .horizontalMenucontainer .header-brand-img.dark-logo {
    display: none;
  }
  .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top, .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom, .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top, .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom, .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 3px;
  }
  .card-group > .card:only-child .card-img-top, .card-group > .card:only-child .card-header {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .card-group > .card:only-child .card-img-bottom, .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 1.5rem;
}

/*------ Default Card Values -------*/
.rhombus {
  width: 350px;
  height: 80px;
  transform: rotate(67.5deg);
  background-color: yellow;
}

@media print {
  .card {
    box-shadow: none;
    border: none;
  }
  .card-header {
    display: none;
  }
  .card-body {
    padding: 0;
  }
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 0;
  padding: 1rem 1.5rem;
  position: relative;
}
.card-body + .card-body {
  border-top: 1px solid #ebecf1;
}

.tilebox-one i {
  font-size: 62px;
}

.card-body > :last-child {
  margin-bottom: 0;
}

.card-body-scrollable {
  overflow: auto;
}

.card-footer, .card-bottom {
  padding: 1rem 1.5rem;
  background: none;
}

.log {
  margin-top: -26px;
}

.card-header {
  background: transparent;
  padding: 0.5rem 1.5rem;
  display: -ms-flexbox;
  display: flex;
  min-height: 3.5rem;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  border-bottom: 1px solid #ebecf1;
  position: relative;
}
.card-header .card-title {
  margin-bottom: 0;
}
.card-header.border-0 + .card-body {
  padding-top: 0;
}

.card-title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 14px;
}

.reg {
  text-align: center;
  font-size: 50px;
  color: #2e1170;
  float: right;
}

.card-title a {
  color: inherit;
}
.card-title:only-child {
  margin-bottom: 0;
}
.card-title small {
  color: #9aa0ac;
  font-size: 0.875rem;
  display: block;
  margin: -0.75rem 0 1rem;
  line-height: 1.1;
  font-weight: 400;
}

.card-subtitle {
  color: #9aa0ac;
  font-size: 0.875rem;
  display: block;
  margin: -0.75rem 0 1rem;
  line-height: 1.1;
  font-weight: 400;
}

.card-table {
  margin-bottom: 0;
}
.card-table tr:first-child td, .card-table tr:first-child th {
  border-top: 0;
}
.card-table tr td:first-child, .card-table tr th:first-child {
  padding-left: 1.5rem;
}
.card-table tr td:last-child, .card-table tr th:last-child {
  padding-right: 1.5rem;
}

.card-body + .card-table {
  border-top: 1px solid #ebecf1;
}

.card-profile .card-header {
  height: 5rem;
  background-size: cover;
  border: 0;
}

.profie-img .flex-md-row img {
  width: 60px;
}

.card-profile-img {
  max-width: 6rem;
  margin-bottom: 1rem;
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.card-link + .card-link {
  margin-left: 1rem;
  margin-left: 1.5rem;
}

.card-body + .card-list-group {
  border-top: 1px solid #ebecf1;
}

.card-list-group .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.card-list-group .list-group-item:last-child {
  border-bottom: 0;
}
.card-list-group .list-group-item:first-child {
  border-top: 0;
}

.card-header-tabs .nav-item {
  margin-bottom: 1px;
}

.card-header-pills {
  margin: -0.75rem 0;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.card-aside {
  -ms-flex-direction: row;
  flex-direction: row;
}

.card-aside-column {
  min-width: 5rem;
  width: 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  background: no-repeat center/cover;
}

.card-value {
  font-size: 1rem;
  line-height: 3.4rem;
  height: 3.4rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
}
.card-value i {
  vertical-align: middle;
}

.card-chart-bg {
  height: 4rem;
  margin-top: -1rem;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.list-media .media-img {
  position: relative;
  float: left;
  width: 48px;
  margin-right: 20px;
}
.list-media .info {
  padding-left: 55px;
  min-height: 40px;
  height: auto;
  position: relative;
}
.list-media .info h4 a {
  font-size: 16px;
  margin-top: 10px;
}
.list-media .info p {
  font-size: 13px;
}
.list-media .info .text-right {
  right: 0;
  color: #8a8a8a;
  top: 50%;
  font-size: 12px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  margin-top: -28px;
}

.card-options {
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-order: 100;
  order: 100;
  margin-right: -0.5rem;
  color: #9aa0ac;
  -ms-flex-item-align: center;
  align-self: center;
}
.card-options a:not(.btn) {
  color: #1e1e2d;
}
.card-options a:not(.btn):hover {
  text-decoration: none;
}
.card-options a:not(.btn) i {
  font-size: 1rem;
}
.card-options a.option-dots {
  color: #5c678f;
  display: inline-block;
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.8rem;
  text-align: center;
  font-size: 15px;
  margin-right: 5px;
  padding: 0;
}
.card-options a.option-dots:hover {
  text-decoration: none;
  color: #007BFF;
}
.card-options .dropdown-toggle:after {
  display: none;
}

.widgets-cards .wrp.icon-circle {
  width: 73px;
  height: 73px;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 0;
  margin-right: 15px;
}
.widgets-cards .wrp.icon-circle i {
  font-size: 1.6rem;
  color: #fff;
}
.widgets-cards .wrp p {
  margin-bottom: 0;
  line-height: 1;
  margin-bottom: 10px;
}
.widgets-cards .wrp p:first-child {
  font-weight: 400;
  font-size: 36px;
}

ul li .legend-dots {
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 0.5rem;
}

.vertical-align-middle {
  vertical-align: middle;
}

.legend li {
  padding: 5px;
  float: left;
}

.stretch-card {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: stretch;
  justify-content: stretch;
}
.stretch-card > .card {
  width: 100%;
  min-width: 100%;
}

.icon-size {
  font-size: 2.5rem !important;
}

.card-img-absolute {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.statistics-box {
  position: relative;
  padding-left: 80px;
  text-align: right;
  padding-top: 14px;
  min-height: 80px;
  text-align: center;
}
.statistics-box .ico {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
  height: 80px;
  font-size: 70px;
  line-height: 80px;
  text-align: center;
}
.statistics-box .ico.small {
  font-weight: 900;
  font-size: 42px;
}
.statistics-box h2 {
  font-weight: 600 !important;
}

/*Card options*/
.card-collapsed > :not(.card-header):not(.card-status) {
  display: none;
}
.card-collapsed .card-options-collapse i:before {
  content: "\e92d";
}

.card-fullscreen .card-options-fullscreen i:before {
  content: "\e992";
}
.card-fullscreen .card-options-remove {
  display: none;
}

/*Card maps*/
.card-map {
  height: 15rem;
  background: #e9ecef;
}

.card-map-placeholder {
  background: no-repeat center;
}

/**Card tabs*/
.card-tabs {
  display: -ms-flexbox;
  display: flex;
}

.card-tabs-bottom .card-tabs-item {
  border: 0;
}
.card-tabs-bottom .card-tabs-item.active {
  border-top-color: #fff;
}

.card-tabs-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: block;
  padding: 1.4rem 1.5rem;
  color: inherit;
  overflow: hidden;
}

a.card-tabs-item {
  background: #fafbfc;
}
a.card-tabs-item:hover {
  text-decoration: none;
  color: inherit;
}
a.card-tabs-item:focus {
  z-index: 1;
}
a.card-tabs-item.active {
  background: #fff;
  border-bottom-color: #fff;
}

/**Card status*/
.card-status {
  position: absolute;
  top: -1px;
  left: 0px;
  right: 0px;
  height: 3px;
  border-radius: 7px 7px 0 0;
  background: rgba(0, 40, 100, 0.12);
}

.card-status-left {
  right: auto;
  bottom: 0;
  height: auto;
  width: 3px;
  border-radius: 3px 0 0 3px;
}

/**Card icon*/
.card-icon {
  width: 3rem;
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
}

/**Card fullscreen*/
.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  margin: 0;
}

/**Card alert*/
.card-alert {
  border-radius: 0;
  margin: -1px -1px 0;
}

.card-category {
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin: 0 0 0.5rem;
  background: #f3f3f3;
}

.list-card {
  padding: 14px 25px;
  border-radius: 0px;
  -webkit-box-shadow: 0 2px 10px 0 rgba(4, 4, 7, 0.1);
  box-shadow: 0 2px 10px 0 rgba(4, 4, 7, 0.1);
  margin-bottom: 12px;
  background: #fff;
  position: relative;
}

.card > .list-group {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col, .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -ms-flex: 0 0 8.33333333%;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  -ms-flex: 0 0 16.66666667%;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.33333333%;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  -ms-flex: 0 0 41.66666667%;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.33333333%;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  -ms-flex: 0 0 66.66666667%;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.33333333%;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  -ms-flex: 0 0 91.66666667%;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1280px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
/*------Drop Downs-------*/
.dropup, .dropright, .dropdown, .dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  color: #c4c9d6;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9375rem;
  color: #495057;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: none;
  min-width: auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border: 1px solid #ebecf1;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  vertical-align: 0;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
  vertical-align: 0;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.header .dropdown-menu {
  top: 62px !important;
  border-radius: 6px;
  animation-name: fadeInUp;
  border: 1px solid #ebecf1;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.header-notify .dropdown-menu.show {
  left: -273px !important;
  min-width: 320px !important;
}

.header-locale {
  padding-top: 0.1rem;
  margin-left: 0.8rem;
}
.header-locale .header-icon {
  width: 20px;
  margin-right: 0.3rem;
  vertical-align: bottom !important;
}
.header-locale .header-text {
  vertical-align: text-bottom;
  color: #1e1e2d;
  font-weight: 600;
}

.header-locale .dropdown-menu {
  left: -75px !important;
}
.header-locale .dropdown-menu .dropdown-item {
  padding: 0.5rem 1.1rem;
}
.header-locale .dropdown-menu .dropdown-item:hover, .header-locale .dropdown-menu .dropdown-item:focus {
  background: #f5f9fc;
}

.profile-dropdown .dropdown-menu.show {
  left: -130px !important;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.notifyimg {
  color: #fff;
  float: left;
  height: 40px;
  line-height: 39px;
  margin-right: 15px;
  text-align: center;
  vertical-align: middle;
  width: 40px;
  border-radius: 50%;
  margin-top: 5px;
}

.view-all-button {
  padding: 0.5rem 0.5rem 0.2rem;
}

.mark-read:hover {
  background-color: #1e1e2d !important;
}

.text-brown {
  color: #363b40;
}

.header-expand .mdi {
  line-height: 1 !important;
}

.profile-icon {
  line-height: 1.1;
  text-align: center;
  vertical-align: middle;
  margin-right: 12px;
}

.notify-menu {
  position: relative;
  overflow-y: scroll;
  max-height: 300px;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.7rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.drop-icon-wrap {
  text-align: center;
}
.drop-icon-wrap li {
  display: block;
}
.drop-icon-wrap .drop-icon-item {
  display: inline-block;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  color: #878787;
  text-transform: capitalize;
  width: 50%;
  float: left;
}

.block {
  display: block !important;
}

.drop-icon-item i {
  font-size: 20px;
  padding-bottom: 5px;
}

.drop-icon-wrap .drop-icon-item:hover, .drop-icon-wrap .drop-icon-item:focus {
  text-decoration: none;
  background-color: #eef0ff;
}

.dropdown-item.user {
  font-size: 12px;
  padding: 5px;
}

.floating {
  animation: floating 3s ease infinite;
  will-change: transform;
}

.dropdown-item:hover, .dropdown-item:focus {
  text-decoration: none;
  background-color: #f5f9fc;
}
.dropdown-item.active, .dropdown-item:active {
  text-decoration: none;
  background-color: #f5f9fc;
  color: #3e4358;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #fff;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
  margin: 0;
}

.dropdown-menu-arrow.dropdown-menu-right:before {
  content: "";
  position: absolute;
  top: -10px;
  left: 15px;
  border-bottom: 10px solid #ebecf1;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.dropdown-menu-arrow.dropdown-menu-right:after {
  content: "";
  position: absolute;
  top: -8.5px;
  left: 16px;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.dropdown-menu-arrow.dropdown-menu-left:before {
  content: "";
  position: absolute;
  top: -10px;
  left: 15px;
  border-bottom: 10px solid #ebecf1;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.dropdown-menu-arrow.dropdown-menu-left:after {
  content: "";
  position: absolute;
  top: -8.5px;
  left: 16px;
  border-bottom: 9px solid #fff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}

.nav-item.show .dropdown-menu.dropdown-menu-arrow.show {
  top: -1px !important;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group, .btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.btn-group > .btn:hover, .btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active {
  z-index: 1;
}

.btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn, .btn-group .btn + .btn-group {
  margin-left: -1px;
}
.btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-group-vertical .btn + .btn, .btn-group-vertical .btn + .btn-group {
  margin-left: -1px;
}
.btn-group-vertical .btn-group + .btn, .btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after {
  margin-left: 0;
}

.dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-group-vertical .btn, .btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio], .btn-group-toggle > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-group-toggle > .btn-group > .btn input[type=radio], .btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

/*-----List Of Dropdwons-----*/
btn.dropdown-toggle ~ .dropdown-menu {
  background-color: rgb(244, 244, 244) !important;
  background-color: rgb(255, 255, 255) !important;
  border: 0 solid rgb(66, 133, 244) !important;
  box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
  top: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
}

ul.dropdown-menu li.dropdown ul.dropdown-menu {
  background-color: rgb(244, 244, 244) !important;
  background-color: rgb(255, 255, 255) !important;
  border: 0 solid rgb(66, 133, 244) !important;
  box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
  top: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
  position: absolute !important;
  width: 100% !important;
}

.dropdown-menu > li > a {
  display: block;
  padding: 0.5rem 1.5rem;
  clear: both;
  font-weight: 400;
  line-height: 1.428571429;
  color: #333;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #1e1e2d;
  background-color: #f0f0f2;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background: #ebecf1;
}
.dropdown-menu .dropdown-plus-title {
  width: 100% !important;
  color: #6b6f80 !important;
  padding: 6px 12px !important;
  font-weight: 500 !important;
  border: 0 solid #ebecf1 !important;
  border-bottom-width: 1px !important;
  cursor: pointer !important;
}

ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  line-height: 20px !important;
}

.btn.dropdown-toggle.btn-success ~ .dropdown-menu .dropdown-plus-title {
  border-color: #38cb89 !important;
}
.btn.dropdown-toggle.btn-info ~ .dropdown-menu .dropdown-plus-title {
  border-color: #5b7fff !important;
}
.btn.dropdown-toggle.btn-warning ~ .dropdown-menu .dropdown-plus-title {
  border-color: #ffab00 !important;
}
.btn.dropdown-toggle.btn-danger ~ .dropdown-menu .dropdown-plus-title {
  border-color: #ef4b4b !important;
}

.dropdown-menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  border-bottom: 1px solid #ebecf1;
}

.dropdown-demo .dropdown-menu {
  position: static;
  float: none;
}

.dropdown-menu-header label {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #6b6f80;
}

.dropdown-media-list .media {
  padding: 12px 15px;
}
.dropdown-media-list .media:hover, .dropdown-media-list .media:focus {
  background-color: #f8f9fa;
}

.media {
  display: flex;
  align-items: flex-start;
}

.dropdown-media-list img {
  border-radius: 100%;
}
.dropdown-media-list .media-body {
  margin-left: 15px;
}

.media-body {
  flex: 1;
}

.dropdown-media-list .media-body > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown-media-list .media-body > div p {
  margin-bottom: 0;
  font-weight: 500;
  color: #6b6f80;
  font-size: 14px;
}
.dropdown-media-list .media-body > div span {
  font-size: 12px;
}
.dropdown-media-list .media-body > p {
  font-size: 14px;
  margin-bottom: 0;
}

.dropdown-list-footer {
  padding: 10px 15px;
  text-align: center;
  font-size: 12px;
  border-top: 1px solid #ebecf1;
}

.label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.dropdown-media-list {
  padding: 0;
}

@media (min-width: 768px) {
  ul.dropdown-menu li.dropdown ul.dropdown-menu {
    width: auto !important;
  }
  ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}
.dropdown {
  display: block;
}

#sidebar .dropdown-menu {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  min-width: 12rem;
}

.dropdown-menu-arrow.dropdown-menu-right:before, .dropdown-menu-arrow.dropdown-menu-right:after {
  left: auto;
  right: 12px;
}

.dropdown-toggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.dropdown-toggle:after {
  vertical-align: 0.155em;
}
.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-icon {
  color: #3e4358;
  margin-right: 0.5rem;
  margin-left: -0.5rem;
  width: 1em;
  display: inline-block;
  text-align: center;
  vertical-align: -1px;
}

.header .profile-dropdown .dropdown-menu {
  top: 61px !important;
}
.header .profile-dropdown .dropdown-menu .dropdown-item {
  padding: 0.5rem 1.1rem;
}
.header .profile-dropdown .dropdown-menu .dropdown-item:hover, .header .profile-dropdown .dropdown-menu .dropdown-item:focus {
  background: #f5f9fc;
}
.header .header-dropdown-list {
  height: 300px;
}

.dropdown-header {
  padding: 15px;
  border-bottom: 1px solid #ebecf1;
  color: #1e1e2d;
  display: flex;
}

.dropdown-toggle.split-dropdown {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  width: 100%;
  background: white;
}

#payment-request-button {
  margin-bottom: 32px;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }
}
a:hover {
  color: #007BFF;
}

.default-card.default .loader {
  border-top-color: #007BFF;
}

.file-manger.list-group-transparent .list-group-item.active {
  color: #007BFF;
}

.custom-progress::-webkit-progress-value, .custom-progress::-moz-progress-bar, .custom-progress::-ms-fill {
  background-color: #007BFF;
}

.card-pay .tabs-menu li a.active {
  background: #007BFF;
}

.page-link:hover {
  color: #007BFF;
}

.page-item.active .page-link {
  background-color: #007BFF;
  border-color: #007BFF;
}

.panel-default > .panel-heading {
  color: #007BFF;
}

.panel-primary .list-group-item.active {
  color: #FFF;
  background-color: #007BFF;
  border-color: #007BFF;
}

.alert-primary {
  color: #464a4e;
  background-color: #007BFF;
  border-color: #007BFF;
}
.alert-primary hr {
  border-top-color: #b7cded;
}
.alert-primary .alert-link {
  color: #172b46;
}

.alert-secondary {
  color: #464a4e;
  background-color: #f72d66;
  border-color: #f72d66;
}
.alert-secondary hr {
  border-top-color: #f72d66;
}
.alert-secondary .alert-link {
  color: #151213;
}

.alert-light-primary {
  color: #007BFF;
  background-color: #cdc8e5;
  border-color: #cdc8e5;
}

.alert-light-secondary {
  color: #fb1c52;
  background-color: #f1a5b8;
  border-color: #f1a5b8;
}

.badge-primary {
  color: #fff;
  background-color: #007BFF;
}
.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #6958be;
}

.badge-secondary {
  color: #fff;
  background-color: #f72d66;
}
.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #f72d66;
}

.badge-primary-light {
  color: #007BFF;
  background-color: rgba(112, 94, 200, 0.15);
}

.badge-secondary-light {
  background-color: rgba(250, 113, 59, 0.15) !important;
  color: #fb1c52;
}

.offer-primary {
  border-color: #007BFF;
}
.offer-primary .shape {
  border-color: transparent #007BFF transparent transparent;
}

.badge-gradient-primary {
  color: #fff;
  background: linear-gradient(to left, #be5fca 0%, #007BFF 100%);
}

.badge-gradient-secondary {
  background-image: linear-gradient(to left, #ed7d98 0%, #fb1c52 100%);
  color: #fff;
}

.breadcrumb-item a, .breadcrumb-item1 a {
  color: #007BFF;
}

.breadcrumb-arrow li a {
  background-color: #007BFF;
  border: 1px solid #007BFF;
}
.breadcrumb-arrow li a:before {
  border-left-color: #007BFF;
}

.btn-primary {
  font-size: 10px;
  min-width: 80px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 35px;
  text-transform: uppercase;
  color: #fff !important;
  background-color: #007bFF;
  border-color: #007bFF;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}
.btn-primary:hover {
  color: #fff !important;
  background-color: #1e1e2d;
  border-color: #1e1e2d;
}
.btn-primary:focus, .btn-primary.focus {
  background-color: #007bFF;
  border-color: #007bFF;
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bFF;
  border-color: #007bFF;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #007bFF;
  border-color: #007bFF;
}

.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #6958be;
  border-color: #6958be;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 2px rgba(112, 94, 200, 0.1);
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(112, 94, 200, 0.1);
}

.btn-secondary {
  color: #fff !important;
  background-color: #fb1c52;
  border-color: #fb1c52;
  box-shadow: 0 0px 10px -5px rgba(251, 28, 82, 0.5);
}
.btn-secondary:hover {
  color: #fff !important;
  background-color: #f83e6b;
  border-color: #f83e6b;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 2px rgba(251, 28, 82, 0.1);
  background-color: #f83e6b;
  border-color: #f83e6b;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #f83e6b;
  border-color: #f83e6b;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #f83e6b;
  border-color: #f83e6b;
}

.btn-cancel,
.btn-cancel-black {
  font-size: 10px;
  min-width: 80px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 35px;
  text-transform: uppercase;
  color: #fff !important;
  background-color: #1e1e2d;
  border-color: #1e1e2d;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}
.btn-cancel:hover,
.btn-cancel-black:hover {
  color: #fff !important;
  background-color: #D13212;
  border-color: #D13212;
}
.btn-cancel:focus, .btn-cancel.focus,
.btn-cancel-black:focus,
.btn-cancel-black.focus {
  background-color: #D13212;
  border-color: #D13212;
}

.btn-cancel-upgrade {
  font-size: 10px;
  min-width: 80px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 35px;
  text-transform: uppercase;
  color: #fff !important;
  background-color: #1e1e2d;
  border-color: #1e1e2d;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}
.btn-cancel-upgrade:hover {
  color: #fff !important;
  background-color: #007BFF;
  border-color: #007BFF;
}
.btn-cancel-upgrade:hover i.fa-solid {
  color: #1e1e2d !important;
}
.btn-cancel-upgrade:focus, .btn-cancel-upgrade.focus {
  background-color: #007BFF;
  border-color: #007BFF;
}
.btn-cancel-upgrade:focus i.fa-solid, .btn-cancel-upgrade.focus i.fa-solid {
  color: #1e1e2d !important;
}

.btn-cancel-black {
  cursor: default;
}
.btn-cancel-black:hover, .btn-cancel-black.focus {
  background-color: #1e1e2d;
  border-color: #1e1e2d;
}

.btn-confirm {
  font-size: 10px;
  min-width: 80px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 35px;
  text-transform: uppercase;
  color: #fff !important;
  background-color: #D13212;
  border-color: #D13212;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}
.btn-confirm:hover {
  color: #fff !important;
  background-color: #1e1e2d;
  border-color: #1e1e2d;
}
.btn-confirm:focus, .btn-confirm.focus {
  background-color: #1e1e2d;
  border-color: #1e1e2d;
}

.color-red {
  color: #D13212 !important;
}

.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #f83e6b;
  border-color: #f83e6b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 2px rgba(251, 28, 82, 0.1);
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(251, 28, 82, 0.1);
}

.btn-outline-primary {
  color: #007BFF;
  background-color: transparent;
  background-image: none;
  border-color: #007BFF;
  box-shadow: 0 0px 10px -5px rgba(112, 94, 200, 0.5);
}
.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #007BFF;
  border-color: #007BFF;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.1);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007BFF;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #007BFF;
  border-color: #007BFF;
}

.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007BFF;
  border-color: #007BFF;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 2px rgba(247, 45, 102, 0.1);
}

.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(247, 45, 102, 0.1);
}

.btn-outline-secondary {
  color: #fb1c52;
  background-color: transparent;
  background-image: none;
  border-color: #fb1c52;
  box-shadow: 0 0px 10px -5px rgba(242, 46, 105, 0.5);
}
.btn-outline-secondary:hover {
  color: #fff !important;
  background-color: #fb1c52;
  border-color: #fb1c52;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 2px rgba(247, 45, 102, 0.1);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #fb1c52;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #fb1c52;
  border-color: #fb1c52;
}

.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #fb1c52;
  border-color: #fb1c52;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 2px rgba(247, 45, 102, 0.1);
}

.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(247, 45, 102, 0.1);
}

.btn-link {
  color: #007BFF;
}
.btn-link:hover {
  color: #6958be;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #007BFF;
}

.btn.dropdown-toggle.btn-primary ~ .dropdown-menu .dropdown-plus-title {
  border-color: #007BFF !important;
}

.list-group-item-primary {
  color: #007BFF;
  background-color: #cbdbf2;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #007BFF;
  background-color: #b7cded;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #007BFF;
  border-color: #007BFF;
}

.list-group-item-secondary {
  color: #fff;
  background-color: #f72d66;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #fff;
  background-color: #f72d66;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #f72d66;
  border-color: #f72d66;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007BFF;
}

.user-button {
  background: #007BFF;
}
.user-button:hover {
  border: 1px solid #007BFF;
  background: #fff;
  color: #007BFF !important;
}

.nav-tabs .nav-item1 .nav-link:hover:not(.disabled), .nav-tabs .nav-item1 .nav-link.active {
  color: #fff;
  background: #007BFF;
}
.nav-tabs .nav-link.active {
  color: #fff;
  background: #007BFF;
}

.header .nav-tabs .nav-link:hover:not(.disabled), .header .nav-tabs .nav-link.active {
  color: #fff !important;
  background: #007BFF;
}

.expanel-primary {
  border: #007BFF !important;
}

.expanel-secondary {
  border: #f72d66 !important;
}

.expanel-primary > .expanel-heading {
  color: #fff !important;
  background-color: #007BFF !important;
  border-color: #007BFF !important;
}

.expanel-secondary > .expanel-heading {
  color: #fff !important;
  background-color: #f72d66 !important;
  border-color: #f72d66 !important;
}

.tag-primary {
  background-color: #007BFF;
  color: #fff;
}

.tag-secondary {
  background-color: #f72d66;
  color: #fff;
}

.pop-primary {
  color: #007BFF;
}

.popsecondary {
  color: #f72d66;
}

.tooltip-primary .tooltip-inner {
  background-color: #007BFF !important;
  color: #fff;
}
.tooltip-primary.bs-tooltip-top .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #007BFF;
}
.tooltip-primary.bs-tooltip-bottom .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #007BFF;
}
.tooltip-primary.bs-tooltip-left .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #007BFF;
}
.tooltip-primary.bs-tooltip-right .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #007BFF;
}

.tooltip-secondary .tooltip-inner {
  background-color: #007BFF;
  color: #fff;
}
.tooltip-secondary.bs-tooltip-top .arrow::before, .tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #007BFF;
}
.tooltip-secondary.bs-tooltip-bottom .arrow::before, .tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #007BFF;
}
.tooltip-secondary.bs-tooltip-left .arrow::before, .tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #007BFF;
}
.tooltip-secondary.bs-tooltip-right .arrow::before, .tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #007BFF;
}

.popover-head-primary .popover-header {
  color: #fff;
  background-color: #007BFF;
}
.popover-head-primary.bs-popover-bottom .arrow::after, .popover-head-primary.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #007BFF;
}

.popover-head-secondary .popover-header {
  color: #fff;
  background-color: #007BFF;
}
.popover-head-secondary.bs-popover-bottom .arrow::after, .popover-head-secondary.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #007BFF;
}

.popover-primary {
  background-color: #007BFF !important;
}
.popover-primary.bs-popover-top .arrow::after, .popover-primary.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #007BFF !important;
}
.popover-primary.bs-popover-bottom .arrow::after, .popover-primary.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #007BFF !important;
}
.popover-primary.bs-popover-left .arrow::after, .popover-primary.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #007BFF !important;
}
.popover-primary.bs-popover-right .arrow::after, .popover-primary.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #007BFF !important;
}

.popover-secondary {
  background-color: #f72d66 !important;
}
.popover-secondary.bs-popover-top .arrow::after, .popover-secondary.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #f72d66 !important;
}
.popover-secondary.bs-popover-bottom .arrow::after, .popover-secondary.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #f72d66 !important;
}
.popover-secondary.bs-popover-left .arrow::after, .popover-secondary.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #f72d66 !important;
}
.popover-secondary.bs-popover-right .arrow::after, .popover-secondary.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #f72d66 !important;
}

.heading-primary {
  background-color: #007BFF !important;
  color: #fff;
}

.dark-mode .popover-primary .popover-header {
  background-color: #007BFF !important;
  color: #fff;
}
.dark-mode .popover-secondary .popover-header {
  background-color: #f72d66 !important;
  color: #fff;
}

.message-feed.right .mf-content {
  background: #007BFF;
}
.message-feed.right .mf-content:before {
  border-bottom: 8px solid #007BFF;
}

.ms-menu .tab-menu-heading .tabs-menu1 ul li .active {
  background: #007BFF;
  color: #fff;
  border: 1px solid #007BFF;
}

.chat .msg_cotainer_send {
  background-color: #007BFF;
}
.chat .msg_cotainer_send:after {
  border-bottom: 10px solid #007BFF;
}

.action-header {
  background: #007BFF;
}

.main-nav-line-chat .nav-link.active {
  color: #fff;
  background: #007BFF;
}

.main-chat-header .nav-link:hover, .main-chat-header .nav-link:focus {
  color: #007BFF;
}

.main-chat-body .media.flex-row-reverse .main-msg-wrapper {
  background-color: #007BFF;
  color: #fff;
}
.main-chat-body .media.flex-row-reverse .main-msg-wrapper:before {
  border-bottom: 7px solid #007BFF;
}

.main-contact-item.selected {
  border-left-color: #007BFF;
}

.main-contact-info-header .main-img-user a:hover, .main-contact-info-header .main-img-user a:focus {
  background-color: #007BFF;
}
.main-contact-info-header .media-body .nav-link:hover, .main-contact-info-header .media-body .nav-link:focus {
  color: #007BFF;
}

.main-contact-action a:hover, .main-contact-action a:focus {
  color: #007BFF;
}

.jsr_bar {
  background-color: #007BFF;
}

.jsr_slider::before {
  background: #007BFF;
}

.range.range-primary input[type=range]::-webkit-slider-thumb, .range.range-primary input[type=range]::-moz-slider-thumb {
  background-color: #007BFF;
}
.range.range-primary output {
  background-color: #007BFF;
}
.range.range-primary input[type=range] {
  outline-color: #007BFF;
}

.br-theme-bars-horizontal .br-widget a.br-active, .br-theme-bars-horizontal .br-widget a.br-selected {
  background-color: #007BFF;
}
.br-theme-bars-horizontal .br-widget .br-current-rating {
  color: #007BFF;
}

.br-theme-bars-pill .br-widget a {
  color: #007BFF;
}
.br-theme-bars-pill .br-widget a.br-active, .br-theme-bars-pill .br-widget a.br-selected {
  background-color: #007BFF;
  color: white;
}

.br-theme-bars-square .br-widget a.br-active, .br-theme-bars-square .br-widget a.br-selected {
  border: 2px solid #007BFF;
  color: #007BFF;
}

.br-theme-bars-movie .br-widget a.br-active, .br-theme-bars-movie .br-widget a.br-selected {
  background-color: #007BFF;
}

.br-theme-bars-1to10 .br-widget a.br-active, .br-theme-bars-1to10 .br-widget a.br-selected {
  background-color: #007BFF;
}
.br-theme-bars-1to10 .br-widget .br-current-rating {
  color: #007BFF;
}

.br-theme-bars-movie .br-widget .br-current-rating {
  color: #007BFF;
}

.header-bg {
  background: #007BFF;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #be5fca 0%, #007BFF 100%);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #be5fca 0%, #007BFF 100%);
}

.footer a:not(.btn) {
  color: #007BFF;
  font-weight: 800;
}
.footer a:not(.btn):hover, .footer a:not(.btn):focus {
  color: #1e1e2d;
}

@media (max-width: 767.98px) and (min-width: 576px) {
  .search-show .search-element {
    background: #007BFF;
  }
}
@media (max-width: 575.98px) {
  .search-show .search-element {
    background: #007BFF;
  }
}
.wizard-card .moving-tab {
  background-color: #007BFF !important;
}

.form-group label.control-label {
  color: #007BFF;
}

.wizard-card .choice:hover .icon, .wizard-card .choice.active .icon {
  border-color: #007BFF !important;
  color: #007BFF !important;
}

.addui-slider .addui-slider-track .addui-slider-range, .addui-slider .addui-slider-track .addui-slider-handle:after {
  background: #007BFF;
}

.cal1 .clndr .clndr-table .header-days {
  background: #007BFF;
}
.cal1 .clndr .clndr-table tr .day.today, .cal1 .clndr .clndr-table tr .day.my-today {
  color: #007BFF !important;
}
.cal1 .clndr .clndr-controls .clndr-control-button .clndr-previous-button {
  color: #007BFF;
}
.cal1 .clndr .clndr-controls .clndr-control-button .clndr-next-button {
  color: #007BFF;
}
.cal1 .clndr .clndr-controls .clndr-control-button .clndr-next-button:hover {
  background: #007BFF;
}
.cal1 .clndr .clndr-controls .clndr-control-button .clndr-previous-button:hover {
  background: #007BFF;
}

.fc-event, .fc-event-dot {
  background-color: #007BFF;
}

.fc-toolbar .fc-state-active, .fc-toolbar .ui-state-active {
  background: #007BFF;
}

div.conv-form-wrapper div.options div.option {
  border: 1px solid #007BFF;
  background: #fff;
  color: #007BFF;
}

form.convFormDynamic button.submit {
  color: #007BFF;
  border: 1px solid #007BFF;
  background: #fff;
}

div.conv-form-wrapper div.options div.option.selected {
  background: #007BFF;
  color: #fff;
}
div.conv-form-wrapper div.options div.option.selected:hover {
  background: #007BFF;
}

form.convFormDynamic button.submit:hover, div.conv-form-wrapper div#messages div.message.from {
  background: #007BFF;
  color: #fff;
}

#sidebar ul li.active > a {
  color: #007BFF;
}

.accordionjs .acc_section.acc_active > .acc_head {
  background: #007BFF !important;
}

.tab_wrapper > ul li.active {
  border-color: #007BFF !important;
  background: #007BFF !important;
  color: #fff;
}
.tab_wrapper.right_side > ul li.active:after {
  background: #007BFF !important;
}

#sidebar li a[aria-expanded=true], #sidebar li a.active {
  background: #007BFF;
  color: #fff;
}

.sweet-alert button {
  background-color: #007BFF !important;
}

.timeline__item:after {
  border: 6px solid #007BFF;
}

.job-box-filter a.filtsec i {
  color: #007BFF;
}

.search-inline button[type=submit] {
  background: #007BFF;
}

.sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
  color: #007BFF;
}

.card-bgimg:before {
  background: #007BFF;
}

.card-custom-icon.text-primary {
  fill: #007BFF;
}

.feature .feature-icon {
  color: #007BFF;
}

.under-countdown .countdown {
  background: #007BFF;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > th:first-child:before {
  background: #007BFF;
}

.social-profile-buttons li a i, .footer .social .social-icon {
  color: #007BFF;
}

.page-leftheader .breadcrumb-item.active {
  color: #007BFF;
}
.page-leftheader .breadcrumb-item.active a {
  color: #007BFF;
}

.dot1, .dot2, .spinner4 > div, .sk-circle .sk-child:before {
  background: -webkit-gradient(linear, left top, right top, from(#007BFF), to(#007BFF));
}

.sk-cube-grid .sk-cube {
  background: #007BFF;
}

.sk-folding-cube .sk-cube:before {
  background-color: #007BFF;
}

.spinner5 > div {
  background: -webkit-gradient(linear, left top, right top, from(#007BFF), to(#007BFF));
}

.spinner {
  background: #007BFF;
}

.spinner-lg {
  background-color: #007BFF;
}

.double-bounce1, .double-bounce2 {
  background: #007BFF;
}

.cube1, .cube2 {
  background-color: #007BFF;
}

.lds-heart div {
  background: #007BFF;
}
.lds-heart div:after, .lds-heart div:before {
  background: #007BFF;
}

.lds-ring div {
  border: 6px solid #007BFF;
  border-color: #007BFF transparent transparent transparent;
}

.lds-hourglass:after {
  border: 26px solid #007BFF;
  border-color: #007BFF transparent;
}

.table-primary {
  background-color: #d5cee6;
}
.table-primary > th, .table-primary > td {
  background-color: #d5cee6;
}

.table-hover .table-primary:hover {
  background-color: #f0f0f2;
}
.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #b7cded;
}

.table-secondary {
  background-color: #f72d66;
}
.table-secondary > th, .table-secondary > td {
  background-color: #f72d66;
}

.table-hover .table-secondary:hover {
  background-color: #cfd2d6;
}
.table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #f72d66;
}

.mediaicon {
  border: 1px solid #007BFF;
  background: #007BFF;
}

a.chip:hover {
  background-color: #007BFF;
}

#back-to-top {
  background: #007BFF;
}
#back-to-top:hover {
  color: #007BFF;
}

.tabs-menu ul li .active {
  background: #007BFF;
}

.tabs-menu1 ul li .active {
  border-bottom: 3px solid #007BFF;
  color: #007BFF;
}

.sub-panel-heading .tabs-menu ul li a.active {
  color: #007BFF !important;
}

.receipts-inline-table .tabs-menu1 ul li .active {
  color: #007BFF;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007BFF;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007BFF;
  border-color: #007BFF;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007BFF;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007BFF;
  border-color: #007BFF;
}

.custom-select:focus {
  border-color: #007BFF;
}

.custom-file-label::after {
  background-color: #007BFF;
  border-left: 1px solid #007BFF;
}

.custom-range::-ms-thumb, .custom-range::-webkit-slider-thumb, .custom-range::-moz-range-thumb {
  background-color: #007BFF;
}

.ui-datepicker .ui-datepicker-title {
  color: #007BFF;
}
.ui-datepicker .ui-datepicker-calendar td a:hover, .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
  background-color: #007BFF;
}

.form-control:focus {
  border-color: #007BFF;
}

.wizard > .steps .current a {
  color: #007BFF;
}
.wizard > .steps .current a:hover, .wizard > .steps .current a:active {
  color: #007BFF;
}
.wizard > .steps .current a .number, .wizard > .steps .current a:hover .number, .wizard > .steps .current a:active .number {
  background-color: #007BFF;
  color: #fff;
}
.wizard > .actions a {
  background-color: #007BFF;
}
.wizard > .actions a:hover, .wizard > .actions a:active {
  background-color: #007BFF;
}

.wizard-style-1 > .steps > ul .current a .number, .wizard-style-1 > .steps > ul .current a:hover .number, .wizard-style-1 > .steps > ul .current a:active .number {
  background-color: #007BFF;
  color: #fff;
}

.wizard-style-2 > .steps > ul .current a .number, .wizard-style-2 > .steps > ul .current a:hover .number, .wizard-style-2 > .steps > ul .current a:active .number {
  border-color: #007BFF;
  color: #007BFF;
}

.selectgroup-input:checked + .selectgroup-button, .selectgroup-input:focus + .selectgroup-button {
  border-color: #007BFF;
  color: #007BFF;
}

.custom-switch-input:checked ~ .custom-switch-indicator {
  background: #007BFF;
}
.custom-switch-input:focus ~ .custom-switch-indicator {
  border-color: #007BFF;
}

.label-primary {
  background: #007BFF;
}

.bg-primary {
  background-color: #007BFF !important;
}

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #6958be !important;
}

button.bg-primary:hover, button.bg-primary:focus {
  background-color: #6958be !important;
}

.bg-secondary {
  background-color: #fa057a !important;
}

a.bg-secondary:hover, a.bg-secondary:focus {
  background-color: #fa057a !important;
}

button.bg-secondary:hover, button.bg-secondary:focus {
  background-color: #fa057a !important;
}

.bg-gradient-primary {
  background: linear-gradient(to left, #be5fca 0%, #007BFF 100%);
}

.border-primary {
  border-color: #007BFF !important;
}

.border-secondary {
  border-color: #f72d66 !important;
}

i.fa.round, .list-icon span p {
  border: 1px solid #007BFF;
}

.text-primary {
  color: #007BFF !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #5e69b8 !important;
}

.text-secondary {
  color: #f72d66 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #f72d66 !important;
}

.bg-primary-transparent {
  background-color: rgba(74, 50, 212, 0.2);
}

.bg-secondary-transparent {
  background-color: rgba(247, 45, 102, 0.2);
}

.bg-primary-transparent-2 {
  background-color: rgba(250, 5, 122, 0.15);
}

.bg-secondary-transparent-2 {
  background-color: rgba(247, 45, 102, 0.15);
}

.panel.price > .panel-heading {
  background: #007BFF;
  color: #fff;
}

.pricing-tabs ul.nav-price li a.active, .pricing-tabs .pri-tabs-heading2 ul.nav-price li a.active {
  background: #007BFF;
}

.icon-dropshadow-secondary {
  filter: drop-shadow(0px 4px 4px rgba(247, 45, 102, 0.3));
}

.card-custom-icon.text-secondary, .fill-secondary {
  fill: #f72d66;
}

.tree li i {
  color: #007BFF;
}

.timeline .timeline-items .timeline-item::after {
  background: #007BFF;
}

li.ui-timepicker-selected {
  background: #007BFF;
  color: #fff;
}

.ui-timepicker-list li:hover, .ui-timepicker-list .ui-timepicker-selected:hover {
  background: #007BFF;
  color: #fff;
}

.SumoSelect:focus > .CaptionCont {
  border-color: #007BFF;
}
.SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls > p:focus {
  border-color: #007BFF;
  outline: none;
  background-color: #007BFF;
}
.SumoSelect > .optWrapper.multiple > .options li.opt.selected span i {
  background-color: #007BFF;
}
.SumoSelect .select-all.selected > span i, .SumoSelect .select-all.partial > span i {
  background-color: #007BFF;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #007BFF;
  color: white;
}

.ql-editor .ql-bg-blue {
  background-color: #007BFF;
}

.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #007BFF;
  outline: 0;
  background: #fff;
}
.ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #007BFF;
}
.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #007BFF;
}
.ql-snow a {
  color: #007BFF;
}

.ms-choice {
  color: #007BFF;
}

.checkbox-group .checkbox-normal:checked + label:before {
  background: #007BFF;
}

.irs-from, .irs-to, .irs-single {
  background-color: #007BFF;
}

.irs-bar, .irs-bar-edge {
  background-image: none;
  background-color: #007BFF;
}

.irs-from::after, .irs-to::after, .irs-single::after {
  border-top-color: #007BFF;
}

.irs-slider:before, .irs-slider:hover:before, .irs-slider:focus:before {
  background-color: #007BFF;
}

.irs-primary .irs-bar, .irs-primary .irs-bar-edge, .irs-primary .irs-slider::before, .irs-primary .irs-from, .irs-primary .irs-to, .irs-primary .irs-single {
  background-color: #007BFF;
}
.irs-primary .irs-from::after, .irs-primary .irs-to::after, .irs-primary .irs-single::after {
  border-top-color: #007BFF;
}

.irs-modern .irs-slider {
  border: 1px solid #007BFF;
}
.irs-modern .irs-slider::before {
  border-left: 1px solid #007BFF;
  border-right: 1px solid #007BFF;
}
.irs-modern.irs-primary .irs-slider {
  border-color: #007BFF;
}
.irs-modern.irs-primary .irs-slider::before {
  border-color: #007BFF;
}

.irs-outline .irs-line {
  border: 1px solid #007BFF;
}
.irs-outline .irs-slider {
  border: 1px solid #007BFF;
}
.irs-outline .irs-slider:hover::before, .irs-outline .irs-slider:focus::before {
  background-color: #007BFF;
}
.irs-outline.irs-primary .irs-line, .irs-outline.irs-primary .irs-slider {
  border-color: #007BFF;
}
.irs-outline.irs-primary .irs-line:hover::before, .irs-outline.irs-primary .irs-line:focus::before {
  background-color: #007BFF;
}
.irs-outline.irs-primary .irs-slider:hover::before, .irs-outline.irs-primary .irs-slider:focus::before {
  background-color: #007BFF;
}

.cropme-slider input::-webkit-slider-thumb, .cropme-rotation-slider input::-webkit-slider-thumb {
  background: #007BFF;
}

.fc button {
  color: #007BFF;
}

.sw-theme-dots > ul.step-anchor > li > a:before {
  color: #007BFF;
}
.sw-theme-dots > ul.step-anchor > li.active > a {
  color: #007BFF;
}
.sw-theme-dots > ul.step-anchor > li.active > a:after {
  background: #007BFF;
}

.theme-white .cookie-popup-accept-cookies, .theme-light .cookie-popup-accept-cookies, .theme-primary .cookie-popup {
  background-color: #007BFF;
  color: #fff;
}

.daterangepicker td.active {
  background-color: #007BFF;
  border-color: transparent;
  color: #fff;
}
.daterangepicker td.active:hover {
  background-color: #007BFF;
  border-color: transparent;
  color: #fff;
}
.daterangepicker .ranges li.active {
  background-color: #007BFF;
  color: #fff;
}

/*-- Horizontal-menu --*/
.hor-menu .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover, .hor-menu .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover {
  color: #007BFF;
}
.hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a:hover, .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a.active {
  color: #007BFF;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a.active, .hor-menu .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a.active {
  color: #007BFF;
}

.sub-menu li.active:before, .sub-menu li:hover:before {
  color: #007BFF;
  opacity: 0.9;
}

.mega-menubg li a.active:before, .mega-menubg li a:hover:before {
  color: #007BFF;
  opacity: 0.9;
}

/*-- Sidemenu --*/
.app-sidebar__toggle:hover {
  color: #007BFF;
}

.slide-menu a.active {
  color: #007BFF !important;
}

.slide-item.active, .slide-item:hover, .slide-item:focus {
  color: #007BFF;
}

.slide.is-expanded .slide-menu li a:hover {
  color: #007BFF;
}

@media (min-width: 768px) {
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-menu a.active {
    color: #007BFF;
    background: transparent;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-item.active {
    color: #007BFF;
  }
  .slide-item:hover, .slide-item:focus {
    color: #007BFF;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-item.active {
    color: #007BFF;
    background: transparent;
  }
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
  background-color: #007BFF;
  border-color: #007BFF;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  border-color: #007BFF;
}

.onoffswitch2-checkbox:checked + .onoffswitch2-label {
  background-color: #007BFF;
}
.onoffswitch2-checkbox:checked + .onoffswitch-label2, .onoffswitch2-checkbox:checked + .onoffswitch2-label:before {
  border-color: #007BFF;
}

@media (min-width: 768px) {
  .app.sidenav-toggled .app-content {
    margin-left: 0;
  }
  .app.sidenav-toggled .app-sidebar {
    left: 250px;
  }
  .app.sidenav-toggled .app-sidebar__overlay {
    visibility: hidden;
  }
  .app-sidebar__user {
    margin-top: 74px;
  }
}
@media (max-width: 767px) {
  .app {
    overflow-x: hidden;
  }
  .app .app-sidebar {
    left: -265px;
    margin-top: 75px;
  }
  .app .app-sidebar__overlay {
    visibility: hidden;
  }
  .app.sidenav-toggled .app-content {
    margin-left: 0;
  }
  .app.sidenav-toggled .aside-progress-position {
    display: none;
  }
  .app.sidenav-toggled .app-sidebar {
    left: 0;
  }
  .app.sidenav-toggled .app-sidebar__overlay {
    visibility: visible;
  }
  .app.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
    /* width: 2.5rem !important; */
    line-height: 1;
  }
  .app-header .header-brand {
    margin-right: 0;
    margin-left: 0.75rem;
  }
}
.app-sidebar .ps__thumb-y {
  right: 0;
}

.app-content {
  min-height: calc(100vh - 100px);
  margin-top: 50px;
  margin-bottom: 0 !important;
  -webkit-transition: margin-left 0.3s ease;
  -o-transition: margin-left 0.3s ease;
  transition: margin-left 0.3s ease;
}
.app-content .side-app {
  padding: 20px 2rem 0 2rem;
}

.footer .container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .app-content {
    margin-left: 250px;
    padding-bottom: 0.5rem;
  }
}
@media (max-width: 767px) {
  .app-content {
    margin-top: 50px;
    min-width: 100%;
  }
}
@media (max-width: 480px) {
  .app-sidebar__toggle {
    padding: 5px !important;
  }
}
@media print {
  .app-content {
    margin: 0;
    padding: 0;
    background-color: #fff;
  }
}
.light-text {
  font-weight: 300 !important;
}

.semibold-text {
  font-weight: 600 !important;
}

.line-head {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

/*----- Componant: Top Navigation Bar ----- */
.app-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 999;
  padding-right: 15px;
  padding: 0.75rem 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

@media (min-width: 768px) {
  .app-header {
    padding-right: 20px;
    padding-left: 250px;
  }
  .sidebar-mini.sidenav-toggled .app-header {
    padding-left: 70px;
  }
}
@media print {
  .app-header {
    display: none;
  }
}
.app-header__logo {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  color: #fff;
  text-align: center;
  font-family: "Niconne";
  padding: 0 15px;
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;
}
.app-header__logo:focus, .app-header__logo:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .app-header__logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: block;
    width: 250px;
  }
}
.app-sidebar__toggle {
  margin: 5px;
  padding: 0px 18px 0 10px;
  text-align: center;
  height: 2.5rem;
  font-size: 1.2rem;
  position: relative;
  border-radius: 3px;
}
.app-sidebar__toggle a {
  color: #b6c1d9;
}
.app-sidebar__toggle .header-icon {
  font-size: 17px;
  text-align: center;
  vertical-align: middle;
  width: 35px;
  height: 35px;
  background: #fff;
  padding-top: 7px !important;
}
.app-sidebar__toggle:focus, .app-sidebar__toggle:hover {
  text-decoration: none;
  color: #007BFF;
}

/*@media (max-width: 360px) {
	.app-sidebar__toggle {
		line-height: 2;
	}
}*/
@media (max-width: 767px) {
  .app-sidebar__toggle {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
}
.app-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .app-nav {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}
.app-nav__item {
  display: block;
  padding: 15px;
  line-height: 20px;
  color: #a8a8a8;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.app-nav__item:hover, .app-nav__item:focus {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}

.app-search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  margin-right: 15px;
  padding: 10px 0;
}

@media (max-width: 480px) {
  .app-search {
    display: none;
  }
}
.app-search__input {
  border: 0;
  padding: 5px 10px;
  padding-right: 30px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.app-search__input::-webkit-input-placeholder, .app-search__input:-ms-input-placeholder, .app-search__input::-ms-input-placeholder, .app-search__input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.app-search__button {
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  padding: 0 10px;
  border: 0;
  color: rgba(0, 0, 0, 0.8);
  background: none;
  cursor: pointer;
}

.app-notification {
  min-width: 270px;
}

.app-notification__title {
  padding: 8px 20px;
  text-align: center;
  background-color: rgba(0, 150, 136, 0.4);
  color: #333;
}

.app-notification__footer {
  padding: 8px 20px;
  text-align: center;
  background-color: #eee;
}

.app-notification__content {
  max-height: 220px;
  overflow-y: auto;
}
.app-notification__content::-webkit-scrollbar {
  width: 6px;
}
.app-notification__content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.app-notification__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 20px;
  color: inherit;
  border-bottom: 1px solid #ddd;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.app-notification__item:focus, .app-notification__item:hover {
  color: inherit;
  text-decoration: none;
  background-color: #e0e0e0;
}

.app-notification__message, .app-notification__meta {
  margin-bottom: 0;
}

.app-notification__icon {
  padding-right: 10px;
}

.app-notification__message {
  line-height: 1.2;
}

.app-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  margin-top: 0px;
  width: 250px;
  max-height: 100%;
  z-index: 1000;
  background: #fff !important;
  -webkit-transition: left 0.3s ease, width 0.3s ease;
  transition: left 0.3s ease, width 0.3s ease;
}
.app-sidebar::-webkit-scrollbar {
  width: 6px;
}
.app-sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

@media print {
  .app-sidebar {
    display: none;
  }
}
@media (max-width: 767px) {
  .app-sidebar__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
}
.app-sidebar__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #1e1e2d;
  width: 100%;
  padding: 20px;
  display: inline-block;
  border-bottom: 1px solid #ebecf1;
}
.app-sidebar__user .dropdown-menu {
  top: 10px !important;
}

.sidenav-toggled .app-sidebar__user .user-pic {
  margin: 0px 0px 0px 0;
}

.app-sidebar__user .user-pic {
  margin-bottom: 12px;
}

.app-sidebar__user-avatar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 15px;
}

.app-sidebar__user-name {
  font-size: 17px;
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 600;
  font-size: 15px;
  margin-top: 5px !important;
}
.app-sidebar__user-name.text-sm {
  font-size: 12px;
  font-weight: 400;
}

.app-sidebar__user-designation {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 600;
  font-size: 15px;
  margin-top: 5px !important;
}

.side-menu {
  margin-bottom: 0;
  padding-bottom: 40px;
  margin-top: 74px;
  border-top: 1px solid #ebecf1;
}

.side-menu__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 2.5px 22px 3px 13px;
  font-size: 14px;
  font-weight: 400;
  transition: border-left-color 0.2s ease, background-color 0.2s ease;
  color: #344050;
  margin: 0px 5px 0px 0px;
  border-radius: 50px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 46px;
}
.side-menu__item .angle {
  font-size: 10px !important;
  opacity: 0.9;
}
.side-menu__item:hover, .side-menu__item:focus {
  text-decoration: none;
  background: #f5f9fc;
  color: #1e1e2d;
  border-left: 3px solid #007BFF;
  transition: border-left 0.2s ease-in-out, color 0.2s ease;
}
.side-menu__item:hover .side-menu__icon, .side-menu__item:focus .side-menu__icon {
  color: #007BFF;
}
.side-menu__item:hover .angle, .side-menu__item:focus .angle {
  color: #007BFF;
}
.side-menu__item.active {
  background: #007BFF;
  color: #fff;
}
.side-menu__item.active .side-menu__icon {
  color: #fff;
}
.side-menu__item.active:hover .angle, .side-menu__item.active:focus .angle {
  color: #fff;
}
.side-menu__item.active:hover, .side-menu__item.active:focus {
  transition: none;
}

@media (min-width: 768px) {
  .app.sidebar-mini.sidenav-toggled .side-menu__label {
    display: none !important;
    position: relative;
    padding: 0;
    min-width: 100%;
    margin: 0;
    left: 0;
    color: rgba(255, 255, 255, 0.8);
    opacity: 1;
    background: transparent;
    font-size: 12px;
    box-shadow: none;
  }
}
.slide-item.active, .slide-item:hover, .slide-item:focus {
  text-decoration: none;
}

.slide.is-expanded a.slide-item {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.slide.is-expanded a.slide-item:hover {
  margin-left: 5px;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #007BFF !important;
}

.side-menu .side-menu__icon {
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
  margin-right: 10px;
  width: 30px;
  padding: 8px;
  line-height: 0.6;
}

.side-menu__icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25px;
}

.side-menu__label {
  white-space: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 13px;
  font-weight: 400;
  opacity: 1;
  height: auto;
  transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.slide.is-expanded .slide-menu {
  max-height: 350vh;
  -webkit-transition: max-height 2s ease;
  -o-transition: max-height 2s ease;
  transition: max-height 2s ease;
  animation-name: fadeInUp;
}
.slide.is-expanded .angle {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.enlarge {
  height: 41px;
  padding-top: 10px !important;
}

.badge {
  font-family: sans-serif !important;
  font-size: 10px !important;
  border-radius: 2px !important;
  font-weight: 400 !important;
  padding: 0 0.4em !important;
  line-height: 1.5 !important;
  color: #fff !important;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}

.badge-warning {
  background: #FF9D00;
}

.slide-menu {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  font-size: 0.8rem !important;
  -webkit-transition: max-height 0.9s ease;
  -o-transition: max-height 0.9s ease;
  transition: max-height 0.9s ease;
}

.slide-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 14px 8px 55px;
  font-size: 12px;
  color: #1e1e2d;
}
.slide-item .icon {
  margin-right: 5px;
}

.angle {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

@media (min-width: 768px) {
  .sidebar-mini.sidenav-toggled {
    top: 0.5rem;
    right: 30px !important;
  }
  .sidebar-mini.sidenav-toggled .app-sidebar__user-name, .sidebar-mini.sidenav-toggled .app-sidebar__user-designation, .sidebar-mini.sidenav-toggled .angle, .sidebar-mini.sidenav-toggled .app-sidebar__user-name, .sidebar-mini.sidenav-toggled .user-notification, .sidebar-mini.sidenav-toggled .app-sidebar__user-name, .sidebar-mini.sidenav-toggled .user-info {
    display: none;
  }
  .sidebar-mini.sidenav-toggled .aside-progress-position {
    display: none;
  }
  .sidebar-mini.sidenav-toggled .side-progress-position {
    display: none;
  }
  .sidebar-mini.sidenav-toggled .sidebar-mini.sidenav-toggled.user-notification::before {
    background: transparent;
    display: none;
  }
  .sidebar-mini.sidenav-toggled .avatar-xl {
    width: 3rem !important;
    height: 3rem !important;
    line-height: 3rem;
    font-size: 1rem;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
  .sidebar-mini.sidenav-toggled .sidebar-navs {
    display: none;
  }
  .sidebar-mini.sidenav-toggled .app-sidebar__user {
    top: 0.5rem;
    right: 30px !important;
    margin-top: 74px;
  }
  .sidebar-mini.sidenav-toggled .app-sidebar__user-avatar {
    width: 25px;
    height: 25px;
  }
  .sidebar-mini.sidenav-toggled .side-menu li .side-menu__item.active:before {
    display: none;
  }
  .sidebar-mini.sidenav-toggled .app-sidebar__user {
    padding: 12px 0px 12px 0;
    margin-bottom: 0px;
  }
  .sidebar-mini.sidenav-toggled .profile-img {
    top: 0px;
    right: 19px;
  }
  .sidebar-mini.sidenav-toggled .app-content {
    margin-left: 70px;
  }
  .sidebar-mini.sidenav-toggled .app-sidebar {
    left: 0;
    width: 70px;
    overflow: hidden;
    -webkit-transition: left 0.3s ease, width 0.3s ease;
    transition: left 0.3s ease, width 0.3s ease;
    max-height: fit-content;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    overflow: visible;
  }
  .sidebar-mini.sidenav-toggled .side-menu__item {
    overflow: hidden;
  }
  .sidebar-mini.sidenav-toggled .side-menu__item:hover {
    overflow: visible;
  }
  .sidebar-mini.sidenav-toggled .side-menu__item:hover .side-menu__label {
    opacity: 1;
  }
  .sidebar-mini.sidenav-toggled .side-menu__item:hover + .slide-menu {
    visibility: visible;
  }
  .sidebar-mini.sidenav-toggled .side-menu__label {
    display: block;
    position: absolute;
    top: 0;
    left: 50px;
    min-width: 180px;
    padding: 12px 5px 12px 20px;
    margin-left: -3px;
    line-height: 1;
    opacity: 0;
    background: #fff;
    color: #a8a8a8;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    -webkit-box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
  }
  .sidebar-mini.sidenav-toggled .slide:hover .side-menu__label {
    opacity: 1;
  }
  .sidebar-mini.sidenav-toggled .slide:hover .slide-menu {
    max-height: 100%;
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }
  .sidebar-mini.sidenav-toggled .slide .side-menu__label {
    border-bottom-right-radius: 0;
  }
  .sidebar-mini.sidenav-toggled .slide-menu {
    position: absolute;
    background: #fff;
    left: 0;
    min-width: 180px;
    opacity: 0;
    border-bottom-right-radius: 4px;
    z-index: 9;
    visibility: hidden;
    -webkit-transition: visibility 0.3s ease;
    -o-transition: visibility 0.3s ease;
    transition: visibility 0.3s ease;
    -webkit-box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
  }
  .app.sidebar-mini.sidenav-toggled .side-menu__item {
    display: block;
    padding: 10px 0;
    margin: 0 auto;
    text-align: center;
    border-left: 0;
    border-radius: 0;
  }
  .app.sidebar-mini.sidenav-toggled .side-menu_label {
    display: block;
    font-size: 12px;
  }
  .app.sidebar-mini.sidenav-toggled .side-menu__label {
    display: block;
    position: relative;
    padding: 0;
    min-width: 100%;
    margin: 0;
    left: 0;
    color: #1e1e2d;
    opacity: 1;
    background: transparent;
    font-size: 12px;
    box-shadow: none;
  }
  .app.sidebar-mini.sidenav-toggled .slide-divider {
    display: none;
  }
  .sidebar-mini.sidenav-toggled .app-sidebar__user .avatar-md {
    margin: 0 auto;
  }
  .app.sidebar-mini.sidenav-toggled .nav-badge {
    position: absolute;
    top: 8px;
    right: 28px;
    padding: 0.2rem 0.4rem;
    font-size: 11px;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    width: 250px;
    -webkit-transition: left 0.3s ease, width 0.3s ease;
    transition: left 0.3s ease, width 0.3s ease;
    max-height: 100%;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .aside-progress-position {
    display: block;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-progress-position {
    display: block;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__user {
    display: inline-block;
    align-items: center;
    width: 100%;
    padding: 20px;
    display: inline-block;
    border-bottom: 1px solid #ebecf1;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__user .dropdown-menu {
    top: 10px !important;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .sidebar-navs {
    display: block;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__user-avatar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-right: 15px;
    display: inline-block;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__user-name {
    font-size: 17px;
    display: inline-block;
    line-height: 1.3;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin-bottom: 0;
    overflow: hidden;
    font-weight: 600;
    color: #e5e9ec;
    font-size: 15px;
    margin-top: 5px !important;
    display: inline-block;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__user-name.text-sm {
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    width: 100%;
    height: auto;
    margin: 0 !important;
  }
  .app-sidebar__user-designation {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin-bottom: 0;
    overflow: hidden;
    font-weight: 600;
    color: #e5e9ec;
    font-size: 15px;
    margin-top: 5px !important;
    display: inline-block;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu {
    margin-bottom: 0;
    padding-bottom: 40px;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu__item {
    position: relative;
    display: flex !important;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 2.5px 22px 3px 13px;
    font-size: 14px;
    font-weight: 400;
    -webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
    -o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
    transition: border-left-color 0.3s ease, background-color 0.3s ease;
    color: #344050;
    margin: 0px 5px 0px 0px;
    border-radius: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-item.active {
    text-decoration: none;
    color: #007BFF;
  }
  .slide-item:hover, .slide-item:focus {
    text-decoration: none;
    color: #007BFF;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-item.active {
    text-decoration: none;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu__item.active .side-menu__label {
    color: #FFF !important;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu__item.active .angle {
    color: #FFF !important;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu__item .side-menu__icon {
    line-height: 1;
  }
  .slide-item:hover, .slide-item:focus {
    text-decoration: none;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide.is-expanded a {
    /*color: $text-color;*/
    text-decoration: none;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu .side-menu__icon {
    margin-right: 12px;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu__label {
    display: inline-flex !important;
    font-size: 12px;
    position: unset;
    min-width: inherit;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu__icon {
    font-size: 18px;
    text-align: center;
    vertical-align: middle;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    padding: 8px !important;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu__label {
    white-space: nowrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    position: unset;
    display: inline-block;
    font-size: 12px;
    color: #1e1e2d;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide.is-expanded .slide-menu {
    max-height: 100%;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide.is-expanded .angle {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .angle {
    display: inline-block;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-menu {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    font-size: 0.8rem !important;
    box-shadow: none;
    position: unset;
    top: inherit;
    min-width: 0;
    width: 100%;
    opacity: inherit;
    visibility: inherit;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 14px 10px 60px;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-item .icon {
    margin-right: 5px;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .angle {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__user img {
    margin-top: 8px;
    width: 4rem !important;
    height: 4rem !important;
    line-height: 4rem;
    font-size: 1.75rem;
    margin-bottom: 0.25rem !important;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .user-info {
    display: inline-block;
  }
  .sidenav-toggled.sidenav-toggled1 .app-sidebar__user .user-pic {
    margin: 0px 0px 12px 0;
  }
}
.app.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
  position: relative;
}
.app.sidebar-mini .side-menu_label {
  display: none;
}

.dropdown-menu {
  border-radius: 0;
}
.dropdown-menu.dropdown-menu-right {
  left: auto;
}

.app-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: #FFF;
  margin: -30px -30px 30px;
  padding: 20px 30px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.app-title h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
}
.app-title p {
  margin-bottom: 0;
  font-style: italic;
}

@media print {
  .app-title {
    display: none;
  }
}
@media (max-width: 480px) {
  .app-title {
    margin: -15px -15px 15px;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .side-menu {
    margin-top: 0;
    border-top: none;
  }
}
@media (max-width: 480px) {
  .app-title p {
    display: none;
  }
}
.app-breadcrumb {
  margin-bottom: 0;
  text-align: right;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0;
  text-align: left;
  padding: 0;
  background-color: transparent;
}

@media (max-width: 480px) {
  .app-breadcrumb {
    margin-top: 10px;
  }
}
@media print {
  .tile {
    border: 1px solid #ddd;
  }
}
.sidenav-toggled .app-sidebar__user .avatar-md {
  line-height: 2rem;
  font-size: 1rem;
}

.app-sidebar .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: rgba(0, 0, 0, 0.05);
}
.app-sidebar .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .app-sidebar .mCSB_scrollTools .mCSB_draggerRail {
  background: rgba(255, 255, 255, 0.2);
}

.slide-menu a:before {
  font: var(--fa-font-solid);
  content: "\f101";
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  font-weight: 400;
  opacity: 0.7;
  font-size: 8px;
}

.sidenav-toggled .main-wrapper .side-menu .side-menu__item .nav-badge {
  position: absolute;
  top: 5px;
  left: 57px;
  display: block !important;
  padding: 3px 5px !important;
}
.sidenav-toggled .main-wrapper .side-menu .side-menu__item .nav-badge1 {
  display: none;
}

.nav-badge {
  border-radius: 30px;
  padding: 0.4em 0.6em;
  font-size: 12px;
}

@media (max-width: 575.98px) {
  .app-header .header-brand-img.mobile-logo {
    margin-left: 0;
  }
  .app-content .side-app {
    padding: 20px 0.75rem 0 0.75rem;
  }
  .app-sidebar {
    margin-top: 75px;
  }
  .app-sidebar__toggle .header-icon {
    margin-top: 0 !important;
  }
}
@media (max-width: 992px) and (min-width: 768px) {
  .app-header .header-brand-img.desktop-lgo, .app-header .header-brand {
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .footer {
    padding: 1.25rem 1.25rem;
  }
  footer.footer {
    padding: 1.25rem 1.25rem 1.25rem 250px;
  }
  .sidenav-toggled footer.footer {
    padding: 0 1.3rem 2rem;
  }
}
@media (max-width: 768px) and (min-width: 481px) {
  .header-brand {
    margin-left: 0;
    min-width: auto !important;
  }
}
@media (max-width: 767px) {
  .app-sidebar__logo {
    display: none;
  }
}
@media (min-width: 768px) {
  .app-header .header-brand {
    display: none;
  }
  .header.top-header {
    padding-left: 250px;
  }
  .sidenav-toggled .header.top-header {
    padding-left: 70px;
  }
  .sidenav-toggled .app-sidebar__logo {
    width: 70px;
    border-right: 0px solid #ebecf1;
  }
}
.app-sidebar__logo {
  padding: 13px 15px;
  height: 74px;
  text-align: center;
  width: 250px;
  z-index: 999999;
  position: fixed;
  background: #fff;
  -webkit-transition: left 0.3s ease, width 0.3s ease;
  -o-transition: left 0.3s ease, width 0.3s ease;
  transition: left 0.3s ease, width 0.3s ease;
}

.close-toggle {
  display: none;
}

.app-sidebar__toggle a i {
  line-height: 1.5;
  margin-top: 0;
}

@media (min-width: 768px) {
  .header-brand-img.dark-logo, .header-brand-img.darkmobile-logo, .header-brand-img.mobile-logo {
    display: none;
  }
  .sidenav-toggled .header-brand-img.desktop-lgo {
    display: none;
  }
  .sidenav-toggled .header-brand-img.mobile-logo {
    display: flex;
    margin-top: 5px;
  }
  .sidenav-toggled.sidenav-toggled1 .header-brand-img.mobile-logo {
    display: none;
  }
  .sidenav-toggled.sidenav-toggled1 .header-brand-img.desktop-lgo {
    display: flex;
    margin: 0 auto;
    margin-top: -7px;
  }
  .app-sidebar.app-sidebar3 {
    top: 74px;
  }
  .app-sidebar.app-sidebar2 {
    box-shadow: none;
    z-index: 9999;
    height: 73px;
  }
  .sidenav-toggled .app-sidebar-help .help-dropdown {
    display: none;
  }
  .sidenav-toggled.sidenav-toggled1 .app-sidebar-help .help-dropdown {
    display: flex;
  }
  .sidenav-toggled .app-sidebar-help, .sidenav-toggled .app-sidebar .side-item.side-item-category, .sidenav-toggled .side-badge {
    display: none;
  }
  .sidenav-toggled.sidenav-toggled1 .app-sidebar-help {
    width: 250px;
  }
  .app.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
    margin-right: 0;
  }
  .app.sidebar-mini.sidenav-toggled .slide a.side-menu__item.active:after {
    top: 20px;
  }
  .sidenav-toggled.sidenav-toggled1 .app-sidebar-help, .sidenav-toggled.sidenav-toggled1 .app-sidebar .side-item.side-item-category, .sidenav-toggled.sidenav-toggled1 .side-badge {
    display: block;
  }
  .sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
    width: 250px;
  }
}
.app-sidebar.app-sidebar3 {
  padding-bottom: 70px;
}

.app-sidebar-help {
  position: fixed;
  bottom: 0;
  border-top: 1px solid #ebecf1;
  background: #fff;
  z-index: 11;
  width: 250px;
}
.app-sidebar-help .header-icon {
  width: 30px;
  height: 30px;
}

.sub-slide-item {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  list-style: none;
  height: 30px;
  padding: 0 0 0 25px !important;
  padding-left: 70px !important;
  height: 28px !important;
  padding: 19px 0 19px 70px !important;
  font-size: 12px;
  color: #576482;
}

.sub-slide-menu {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.9s ease;
  -o-transition: max-height 0.9s ease;
  transition: max-height 0.9s ease;
  padding: 0;
  font-size: 0.8rem !important;
  padding-left: 0;
  list-style: none;
}

.slide-menu .sub-slide.active.is-expanded {
  background: rgba(255, 255, 255, 0.09);
}

.sub-slide.is-expanded .sub-slide-menu {
  max-height: 100vh;
  -webkit-transition: max-height 2s ease;
  -o-transition: max-height 2s ease;
  transition: max-height 2s ease;
}
.sub-slide.is-expanded .sub-slide-menu li:last-child {
  padding-bottom: 10px;
}

.sub-side-menu__item {
  padding-left: 55px !important;
  padding-right: 20px !important;
  height: 40px !important;
  display: block;
  line-height: 40px;
  font-size: 12px;
  color: #576482;
}

.sub-angle {
  float: right;
  line-height: 40px;
  margin-top: 12px;
}

.help-dropdown {
  color: #1e1e2d !important;
}

.slide.is-expanded a.sub-side-menu__item {
  position: relative;
}

.app-sidebar__user .user-pic img {
  box-shadow: 0 0 0 4px #eeeef3;
  border: 3px solid #f5f9fc;
  width: 58px;
  height: 58px;
  margin-top: 8px;
}
.app-sidebar__user .user-pic img:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #8760fb;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.sidemenu-footer .icon {
  margin: 5px;
  padding: 7px 14px;
  text-align: center;
  position: relative;
}
.sidemenu-footer .header-icons {
  width: 24px;
  height: 24px;
  color: #1e1e2d;
  fill: #1e1e2d;
}

.tooltip {
  z-index: 9999 !important;
}

.profile-status {
  content: "";
  position: absolute;
  bottom: 0;
  right: 80px;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  top: 51px;
  border: 2px solid #fff;
}

.app-sidebar .side-item.side-item-category {
  color: #727685;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 0 20px 0 21px;
}
.app-sidebar .side-item.side-item-category:not(:first-child) {
  margin-top: 20px;
}

.slide-divider {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.app-sidebar__user .user-info .user-name {
  margin-bottom: 2px;
  font-size: 15px;
}

.profile-dropdown img {
  width: 40px;
  height: 40px;
}

.user-pro-body .dot-label {
  width: 6px;
  height: 6px;
}

.sidebar-navs a {
  background: #fff;
  color: #68798b !important;
  border-radius: 50px;
  padding: 0.5rem !important;
  margin: 12px 8px 0 8px;
  border-radius: 50px;
  box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
  border: 1px solid #ebecf1;
}

.app-sidebar .sidebar-navs {
  padding: 10px 0px 0px 0px;
}

.sidebar-navs .nav li:last-child a {
  margin-right: 0 !important;
}
.sidebar-navs .header-icons {
  color: #5c678f;
  fill: #5c678f;
  font-size: 20px;
  vertical-align: middle;
}

.slide.active.is-expanded .side-menu__item {
  text-decoration: none;
  color: #1e1e2d;
  background: #f5f9fc;
  border-radius: 50px;
}

.app.sidebar-mini.sidenav-toggled .slide.active.is-expanded .side-menu__item {
  border-radius: 0;
}

@media (min-width: 767px) {
  .sidebar-mini.sidenav-toggled .app-sidebar {
    max-height: 100%;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    max-height: 100%;
  }
}
/*-------- Bootstrap Framework -------*/
#area-chart, #line-chart, #bar-chart, #stacked, #pie-chart {
  min-height: 250px;
}

* {
  box-sizing: border-box;
}
*::before, *::after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

.nav-sidebar li ul {
  background: #eeeeee;
}
.nav-sidebar li ul li:last-child {
  border-bottom: 0px;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #1e1e2d;
  text-align: left;
  background: #f5f9fc;
  overflow-x: hidden;
}

.horizontalMenucontainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.66em;
}

.overflow-auto {
  overflow: auto;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 400;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1a1630;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  text-decoration: none;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #505662;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

button:focus, textarea:focus {
  outline: 0;
}

input[type=radio], input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}
input[type=date], input[type=time], input[type=datetime-local], input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

.bcolor {
  background-color: #cf4c3a !important;
}

.bcolor1 {
  background-color: #d6124a !important;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.66em;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1, .h1 {
  font-size: 2rem;
}

h2, .h2 {
  font-size: 1.75rem;
}

h3, .h3 {
  font-size: 1.5rem;
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.171875rem;
  font-weight: 300;
}

.display-1 {
  font-size: 10.5rem;
  font-weight: 500;
  line-height: 1.1;
}

.display-2 {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-6 {
  font-size: 2.3rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #ebecf1;
}

small, .small {
  font-size: 87.5%;
  font-weight: 400;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-unstyled li {
  border-bottom: 1px solid #ebecf1;
}

#lightgallery.list-unstyled li {
  margin: 10px 0;
  border: 0;
}

#sidebar .list-unstyled li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);
}

.nav-sidebar .list-unstyled li:last-child {
  border-bottom: 0px;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.171875rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ebecf1;
  border-radius: 3px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #868e96;
}

.fs-5 {
  font-size: 5px;
}

.fs-6 {
  font-size: 6px;
}

.fs-7 {
  font-size: 7px;
}

.fs-8 {
  font-size: 8px;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-35 {
  font-size: 35px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-45 {
  font-size: 45px !important;
}

.fs-50 {
  font-size: 50px !important;
}

.fs-55 {
  font-size: 55px !important;
}

.fs-60 {
  font-size: 60px !important;
}

.fs-65 {
  font-size: 65px !important;
}

.fs-70 {
  font-size: 70px;
}

.fs-75 {
  font-size: 75px;
}

.fs-80 {
  font-size: 80px;
}

.fs-85 {
  font-size: 85px;
}

.fs-90 {
  font-size: 90px;
}

.fs-95 {
  font-size: 95px;
}

.fs-100 {
  font-size: 100px;
}

.mt-m1 {
  margin-top: -1rem;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 85%;
  color: #fff;
  background-color: #343a40;
  border-radius: 3px;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container, .container-fluid {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
  #sticky {
    text-align: center;
    padding: 1px;
    font-size: 1.75em;
    color: #FFF;
    z-index: 0;
    height: 130px !important;
  }
  #sticky.stick {
    z-index: 1;
    height: 130px !important;
  }
  .header.top-header {
    z-index: 999 !important;
  }
  .app-content.page-body {
    margin-top: 9.5rem;
  }
  .comb-page .app-content.page-body {
    margin-top: 0;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1200px;
  }
}
.number-font {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.number-font-chars {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 24px;
}

.number-font-light {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
}

/*----- Global Loader -----*/
#global-loader {
  position: fixed;
  z-index: 50000;
  background: rgb(255, 255, 255);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
#global-loader img {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 43%;
  left: 0;
  margin: 0 auto;
  text-align: center;
}

/*------ Custom Default -------*/
.loader {
  border: 2px solid #ffffff;
  border-top: 2px solid #000000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  display: inline-block;
  margin: 1rem 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.loader.animate {
  opacity: 1;
}

h2.animate {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.custom-card.blue {
  color: #1687ed;
  background: #ebf5fd;
  border: 1px solid #5dacf2;
  border-radius: 8px;
}
.custom-card.blue .loader {
  border-color: #99d0ff;
  border-top-color: #1687ed;
}
.custom-card.red {
  color: #ff0000;
  background: #ffe6e6;
  border: 1px solid #ff8080;
  border-radius: 8px;
}
.custom-card.red .loader {
  border-color: #ff9696;
  border-top-color: #ff0000;
}
.custom-card.green {
  color: #007e08;
  background: #cbffce;
  border: 1px solid #00cb0d;
  border-radius: 8px;
}
.custom-card.green .loader {
  border-color: #75cc7d;
  border-top-color: #007e08;
}

.default-card.default .loader {
  border-color: #e0e3f5;
}

.parent {
  -webkit-transform-origin: 66px 56px;
  -ms-transform-origin: 66px 56px;
  transform-origin: 66px 56px;
  -webkit-animation: spin 200s infinite;
  animation: spin 200s infinite;
}

.child1 {
  -webkit-animation: child1 10s 2s alternate infinite;
  animation: child1 10s 2s alternate infinite;
}

.child2 {
  -webkit-animation: child2 5s alternate infinite;
  animation: child2 5s alternate infinite;
}

.child3 {
  -webkit-animation: child3 10s alternate infinite;
  animation: child3 10s alternate infinite;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes child1 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  to {
    -webkit-transform: translate(35px, -20px);
    transform: translate(35px, -20px);
  }
}
@keyframes child1 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  to {
    -webkit-transform: translate(35px, -20px);
    transform: translate(35px, -20px);
  }
}
@-webkit-keyframes child2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@keyframes child2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@-webkit-keyframes child3 {
  0% {
    -webkit-transform: translateY(-10px) rotate(50deg);
    transform: translateY(-10px) rotate(50deg);
  }
  to {
    -webkit-transform: translateY(20px) rotate(0deg);
    transform: translateY(20px) rotate(0deg);
  }
}
@keyframes child3 {
  0% {
    -webkit-transform: translateY(-10px) rotate(50deg);
    transform: translateY(-10px) rotate(50deg);
  }
  to {
    -webkit-transform: translateY(20px) rotate(0deg);
    transform: translateY(20px) rotate(0deg);
  }
}
/*------ Scroll -------*/
.index1 {
  height: 270px;
}

.topsales {
  height: 330px;
}

.salesscroll {
  height: 280px;
}

.countryscroll {
  height: 350px;
}

.countrytable td {
  padding-bottom: 10px;
  padding-top: 10px;
}
.countrytable tr {
  border-bottom: 1px solid #ebecf1;
}
.countrytable tr:last-child {
  border-bottom: 0px;
}

* html .fileinput-button {
  line-height: 24px;
  margin: 1px -3px 0 0;
}
* + html .fileinput-button {
  margin: 1px 0 0;
  padding: 2px 15px;
}

@media (max-width: 767px) {
  .files .btn span {
    display: none;
  }
  .files .preview * {
    width: 40px;
  }
  .files .name * {
    display: inline-block;
    width: 80px;
    word-wrap: break-word;
  }
  .files .progress {
    width: 20px;
  }
  .files .delete {
    width: 60px;
  }
}
ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.list-icons .svg-icon2, .list-icons .svg-icon {
  width: 60px;
  height: 60px;
}

.file-manger.list-group-transparent .list-group-item {
  padding: 0.5rem;
  border: 0 !important;
}
.file-manger.list-group-transparent .list-group-item.active {
  background: transparent;
}
.file-manger.list-group-transparent a i {
  display: block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  font-size: 16px !important;
  box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
  border: 1px solid #ebecf1;
  vertical-align: middle;
  color: #5c678f;
}
.file-manger.list-group-transparent .list-group-item .svg-icon {
  width: 24px;
  height: 24px;
}

.file-manger-icon {
  position: relative;
}

.file-manger-absolute-icon {
  position: absolute;
  top: 30px;
  color: #fff;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.file-manger-icon i {
  font-size: 70px;
  margin: 0 auto;
  text-align: center;
}
.file-manger-icon img {
  width: 70px;
  height: 70px;
}

.profile-footer {
  margin: 25px -25px -25px -25px;
  padding: 15px;
  background: #f0f0f2;
}

.w-200 {
  width: 200px;
}

.option-dots {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  font-size: 15px;
  color: #5c678f;
  padding: 10px 12px;
}
.new.option-dots {
  line-height: 55px;
}

.new-list.option-dots {
  line-height: 55px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.new-list2.option-dots {
  height: 40px;
  width: 40px;
  background: 0 0;
  color: #5c678f;
  box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
  border: 1px solid #ebecf1;
  border-radius: 50px;
  font-size: 18px;
  line-height: initial;
  margin-right: 8px;
}
.new-list2.option-dots:hover {
  background: #f0f0f2;
}

.ms-body .action-header {
  background: #fff;
}

.user-contact-list {
  overflow: hidden;
}
.user-contact-list:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 70px;
  border-radius: 0% 98% 84% 16%/100% 0% 100% 0%;
  background: linear-gradient(rgba(112, 94, 200, 0.85), rgba(112, 94, 200, 0.51));
  background-size: cover;
  background-position: top;
}

hr.divider:after {
  content: "OR";
  display: block;
  position: relative;
  top: -1.5em;
  font-size: 11px;
  padding: 0 0.25em;
  background: #f0f0f2;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 9;
  left: 0;
  right: 0;
  line-height: 30px;
  margin: 0 auto;
  font-weight: 500;
  border: 1px solid #f0eff1;
  text-align: center;
}

.page-style1 {
  position: relative;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.page-style1:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgb(240, 239, 241);
}

.error-page {
  position: relative;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
}
.error-page:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, rgba(254, 96, 96, 0.7) 0%, rgba(227, 9, 113, 0.7) 100%);
}

.bg-style {
  position: relative;
}
.bg-style:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(68, 84, 195, 0.9), rgba(68, 84, 195, 0.8));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  opacity: 0.8;
}
.bg-style .svg-icon {
  fill: #fff;
}
.bg-style .header-brand-img {
  position: absolute;
  top: 30px;
  left: 55px;
}

.page-style2 .h-100vh {
  max-height: 100vh;
}

.page-style1 .page-content, .page-style2 .page-content {
  margin: 0;
}

@media (max-width: 767px) {
  .d-md-flex .bg-style {
    display: none;
  }
  .d-md-flex .w-80 {
    width: 100% !important;
  }
}
.title-style h1 {
  position: absolute;
  width: 100%;
  background: #fff;
  display: block;
  left: 0;
  right: 0;
  width: fit-content;
  margin: 0 auto;
  display: block;
  top: 20px;
  padding: 0 20px;
}

.page-style2.page-style1:before {
  background: linear-gradient(rgba(255, 255, 255, 0.79), rgba(255, 255, 255, 0.75));
  background-repeat: no-repeat;
  background-size: cover;
}

.relative {
  position: relative;
}

.page-style1 .input-group-addon {
  border: 1px solid #ebecf1;
  background: #f0f0f2;
  border-right: 0;
  line-height: 0px;
}
.page-style1 .input-group-append {
  border: 1px solid #ebecf1;
  background: #f0f0f2;
  border-left: 0;
  line-height: 16px;
  border-radius: 0 5px 5px 0;
}
.page-style1 .form-control {
  border: 1px solid #ebecf1;
}
.page-style1 hr {
  border-top: 1px solid #ebecf1;
}
.page-style1 .custom-control-label {
  line-height: 26px;
}
.page-style1 .btn-svgs .svg-icon {
  position: absolute;
  left: 37%;
  top: auto;
}
.page-style1 .custom-control-label:before {
  border: 1px solid #cccdd3;
}

/* CROSS BROWSER SELECTOR*/
.custom-progress {
  /* RESETS */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 10px;
  border-radius: 0px;
  background-color: #e8ebf2;
  width: 80%;
}
.custom-progress::-webkit-progress-bar {
  background-color: #e8ebf2;
  border-radius: 0px;
}
.custom-progress::-webkit-progress-value {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  border-radius: 0px;
}
.custom-progress::-moz-progress-bar {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.custom-progress::-ms-fill {
  border-radius: 0px;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

/* WEBKIT BROWSERS - CHROME, OPERA AND SAFARI */
/* MOZILLA FIREFOX */
/* MICROSOFT EDGE & IE */
.map svg {
  height: 400px;
}

.card-pay .tabs-menu {
  margin-bottom: 25px;
  border-radius: 5px;
  overflow: hidden;
}
.card-pay .tabs-menu li {
  width: 33.3%;
  display: block;
}
.card-pay .tabs-menu li a {
  padding: 0.5rem 1rem;
  background: #f0f0f2;
  display: block;
  text-align: center;
  border-right: 1px solid #ebecf1;
}
.card-pay .tabs-menu li a.active {
  color: #fff;
  border-radius: 2px;
  text-align: center;
}
.card-pay .tabs-menu li:last-child a {
  border-right: 0;
}

.morris-donut-wrapper-demo {
  height: 270px;
}

.shop-title {
  font-size: 16px;
}

.apexcharts-toolbar {
  display: none !important;
}

#SvgjsText1920 {
  font-weight: 500;
  font-size: 20px;
}

.pos-static {
  position: static;
}

.html-code {
  padding: 9px 20px;
  background: #e3e2e5;
  width: fit-content;
  margin-left: 20px;
  border-radius: 5px 5px 0 0;
  border: 1px solid #ebecf1;
  border-bottom: 0;
  margin-bottom: -1px;
  z-index: 1;
  font-size: 15px;
  position: relative;
  text-transform: uppercase;
  margin-top: 15px;
  font-weight: 500;
  color: #282d3c;
}
.html-code .svg-icon {
  position: absolute;
  left: 14px;
}

.list-style {
  list-style: inherit;
  padding-left: inherit;
}
.list-style ul {
  list-style: inherit;
  padding-left: 30px;
}
.list-style li {
  line-height: 30px;
}

.list-style2 {
  list-style: decimal;
  padding-left: inherit;
}
.list-style2 ul {
  list-style: decimal;
  padding-left: 30px;
}
.list-style2 li {
  line-height: 30px;
}

.list-style3 {
  list-style: circle;
  padding-left: inherit;
}
.list-style3 ul {
  list-style: decimal;
  padding-left: 30px;
}
.list-style3 li {
  line-height: 30px;
}

.list-style4 {
  list-style: lower-alpha;
  padding-left: inherit;
}
.list-style4 ul {
  list-style: decimal;
  padding-left: 30px;
}
.list-style4 li {
  line-height: 30px;
}

.order-list li {
  line-height: 30px;
}

.list-style5 {
  list-style: square;
  padding-left: inherit;
}
.list-style5 ul {
  list-style: decimal;
  padding-left: 30px;
}
.list-style5 li {
  line-height: 30px;
}

.list-style6 {
  list-style: lower-roman;
  padding-left: inherit;
}
.list-style6 ul {
  list-style: decimal;
  padding-left: 30px;
}
.list-style6 li {
  line-height: 30px;
}

.profile-dropdown {
  margin-top: 1px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .expenses-card .fs-50 {
    font-size: 25px !important;
  }
  .expenses-card .fs-18 {
    font-size: 14px !important;
  }
}
@media (max-width: 567px) {
  .card-pay .tabs-menu li {
    width: 100%;
  }
  .card-pay .tabs-menu li a {
    margin-bottom: 15px;
  }
}
.input-group-btn .btn-primary {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  padding: 0.52rem 15px;
  border-radius: 0;
}

@media (min-width: 1440px) {
  .crypto-header-section h3 {
    font-size: 32px;
  }
  .crypto-header-section .w-5 {
    width: 2rem !important;
  }
  .crypto-header-section .h-5 {
    height: 2rem !important;
  }
}
@media (min-width: 1600px) {
  .crypto-header-section h3 {
    font-size: 35px;
  }
  .crypto-header-section .w-5 {
    width: 2.5rem !important;
  }
  .crypto-header-section .h-5 {
    height: 2.5rem !important;
  }
}
@media (max-width: 768px) {
  .page-single .card-group {
    display: block;
  }
  .d-md-down-none {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1440px) {
  .item-card .btn-svgs {
    width: 100%;
  }
}
.header.bg-primary .form-control.header-search.bg-transparent.border-transparent {
  color: #fff;
}

@media (max-width: 1200px) {
  .sales-img {
    width: 50% !important;
    margin: 0 auto;
    text-align: center !important;
    display: block;
  }
}
.error-text {
  text-shadow: -4px 4px 4px rgb(99, 85, 163);
}

.box div {
  position: absolute;
  width: 60px;
  height: 60px;
  background: transparent;
  border: 6px solid rgba(255, 255, 255, 0.2);
}
.box div:nth-child(1) {
  top: 12%;
  left: 42%;
  animation: animate 10s linear infinite;
}
.box div:nth-child(2) {
  top: 70%;
  left: 50%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(3) {
  top: 17%;
  left: 6%;
  animation: animate 9s linear infinite;
}
.box div:nth-child(4) {
  top: 20%;
  left: 60%;
  animation: animate 10s linear infinite;
}
.box div:nth-child(5) {
  top: 67%;
  left: 10%;
  animation: animate 6s linear infinite;
}
.box div:nth-child(6) {
  top: 80%;
  left: 70%;
  animation: animate 12s linear infinite;
}
.box div:nth-child(7) {
  top: 60%;
  left: 80%;
  animation: animate 15s linear infinite;
}
.box div:nth-child(8) {
  top: 32%;
  left: 25%;
  animation: animate 16s linear infinite;
}
.box div:nth-child(9) {
  top: 90%;
  left: 25%;
  animation: animate 9s linear infinite;
}
.box div:nth-child(10) {
  top: 20%;
  left: 80%;
  animation: animate 5s linear infinite;
}

@-webkit-keyframes animate {
  0% {
    -webkit-transform: scale(0) translateY(0) rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.3) translateY(-90px) rotate(360deg);
    opacity: 0;
  }
}
.page-content .input-group .form-control:focus {
  border-color: #ffffff;
}

/*------ Icons List ------*/
.flag {
  width: 1.6rem;
  height: 1.2rem;
  display: inline-block;
  background: no-repeat center/100% 100%;
  vertical-align: bottom;
  font-style: normal;
}

.flag-ad {
  background-image: url("/img/flags/ad.svg");
}

.flag-ae {
  background-image: url("/img/flags/ae.svg");
}

.flag-af {
  background-image: url("/img/flags/af.svg");
}

.flag-ag {
  background-image: url("/img/flags/ag.svg");
}

.flag-ai {
  background-image: url("/img/flags/ai.svg");
}

.flag-al {
  background-image: url("/img/flags/al.svg");
}

.flag-am {
  background-image: url("/img/flags/am.svg");
}

.flag-ao {
  background-image: url("/img/flags/ao.svg");
}

.flag-aq {
  background-image: url("/img/flags/aq.svg");
}

.flag-ar {
  background-image: url("/img/flags/ar.svg");
}

.flag-as {
  background-image: url("/img/flags/as.svg");
}

.flag-at {
  background-image: url("/img/flags/at.svg");
}

.flag-au {
  background-image: url("/img/flags/au.svg");
}

.flag-aw {
  background-image: url("/img/flags/aw.svg");
}

.flag-ax {
  background-image: url("/img/flags/ax.svg");
}

.flag-az {
  background-image: url("/img/flags/az.svg");
}

.flag-ba {
  background-image: url("/img/flags/ba.svg");
}

.flag-bb {
  background-image: url("/img/flags/bb.svg");
}

.flag-bd {
  background-image: url("/img/flags/bd.svg");
}

.flag-be {
  background-image: url("/img/flags/be.svg");
}

.flag-bf {
  background-image: url("/img/flags/bf.svg");
}

.flag-bg {
  background-image: url("/img/flags/bg.svg");
}

.flag-bh {
  background-image: url("/img/flags/bh.svg");
}

.flag-bi {
  background-image: url("/img/flags/bi.svg");
}

.flag-bj {
  background-image: url("/img/flags/bj.svg");
}

.flag-bl {
  background-image: url("/img/flags/bl.svg");
}

.flag-bm {
  background-image: url("/img/flags/bm.svg");
}

.flag-bn {
  background-image: url("/img/flags/bn.svg");
}

.flag-bo {
  background-image: url("/img/flags/bo.svg");
}

.flag-bq {
  background-image: url("/img/flags/bq.svg");
}

.flag-br {
  background-image: url("/img/flags/br.svg");
}

.flag-bs {
  background-image: url("/img/flags/bs.svg");
}

.flag-bt {
  background-image: url("/img/flags/bt.svg");
}

.flag-bv {
  background-image: url("/img/flags/bv.svg");
}

.flag-bw {
  background-image: url("/img/flags/bw.svg");
}

.flag-by {
  background-image: url("/img/flags/by.svg");
}

.flag-bz {
  background-image: url("/img/flags/bz.svg");
}

.flag-ca {
  background-image: url("/img/flags/ca.svg");
}

.flag-cc {
  background-image: url("/img/flags/cc.svg");
}

.flag-cd {
  background-image: url("/img/flags/cd.svg");
}

.flag-cf {
  background-image: url("/img/flags/cf.svg");
}

.flag-cg {
  background-image: url("/img/flags/cg.svg");
}

.flag-ch {
  background-image: url("/img/flags/ch.svg");
}

.flag-ci {
  background-image: url("/img/flags/ci.svg");
}

.flag-ck {
  background-image: url("/img/flags/ck.svg");
}

.flag-cl {
  background-image: url("/img/flags/cl.svg");
}

.flag-cm {
  background-image: url("/img/flags/cm.svg");
}

.flag-cn {
  background-image: url("/img/flags/cn.svg");
}

.flag-co {
  background-image: url("/img/flags/co.svg");
}

.flag-cr {
  background-image: url("/img/flags/cr.svg");
}

.flag-cu {
  background-image: url("/img/flags/cu.svg");
}

.flag-cv {
  background-image: url("/img/flags/cv.svg");
}

.flag-cw {
  background-image: url("/img/flags/cw.svg");
}

.flag-cx {
  background-image: url("/img/flags/cx.svg");
}

.flag-cy {
  background-image: url("/img/flags/cy.svg");
}

.flag-cz {
  background-image: url("/img/flags/cz.svg");
}

.flag-de {
  background-image: url("/img/flags/de.svg");
}

.flag-dj {
  background-image: url("/img/flags/dj.svg");
}

.flag-dk {
  background-image: url("/img/flags/dk.svg");
}

.flag-dm {
  background-image: url("/img/flags/dm.svg");
}

.flag-do {
  background-image: url("/img/flags/do.svg");
}

.flag-dz {
  background-image: url("/img/flags/dz.svg");
}

.flag-ec {
  background-image: url("/img/flags/ec.svg");
}

.flag-ee {
  background-image: url("/img/flags/ee.svg");
}

.flag-eg {
  background-image: url("/img/flags/eg.svg");
}

.flag-eh {
  background-image: url("/img/flags/eh.svg");
}

.flag-er {
  background-image: url("/img/flags/er.svg");
}

.flag-es {
  background-image: url("/img/flags/es.svg");
}

.flag-et {
  background-image: url("/img/flags/et.svg");
}

.flag-eu {
  background-image: url("/img/flags/eu.svg");
}

.flag-fi {
  background-image: url("/img/flags/fi.svg");
}

.flag-fj {
  background-image: url("/img/flags/fj.svg");
}

.flag-fk {
  background-image: url("/img/flags/fk.svg");
}

.flag-fm {
  background-image: url("/img/flags/fm.svg");
}

.flag-fo {
  background-image: url("/img/flags/fo.svg");
}

.flag-fr {
  background-image: url("/img/flags/fr.svg");
}

.flag-ga {
  background-image: url("/img/flags/ga.svg");
}

.flag-gb-eng {
  background-image: url("/img/flags/gb-eng.svg");
}

.flag-gb-nir {
  background-image: url("/img/flags/gb-nir.svg");
}

.flag-gb-sct {
  background-image: url("/img/flags/gb-sct.svg");
}

.flag-gb-wls {
  background-image: url("/img/flags/gb-wls.svg");
}

.flag-gb {
  background-image: url("/img/flags/gb.svg");
}

.flag-gd {
  background-image: url("/img/flags/gd.svg");
}

.flag-ge {
  background-image: url("/img/flags/ge.svg");
}

.flag-gf {
  background-image: url("/img/flags/gf.svg");
}

.flag-gg {
  background-image: url("/img/flags/gg.svg");
}

.flag-gh {
  background-image: url("/img/flags/gh.svg");
}

.flag-gi {
  background-image: url("/img/flags/gi.svg");
}

.flag-gl {
  background-image: url("/img/flags/gl.svg");
}

.flag-gm {
  background-image: url("/img/flags/gm.svg");
}

.flag-gn {
  background-image: url("/img/flags/gn.svg");
}

.flag-gp {
  background-image: url("/img/flags/gp.svg");
}

.flag-gq {
  background-image: url("/img/flags/gq.svg");
}

.flag-gr {
  background-image: url("/img/flags/gr.svg");
}

.flag-gs {
  background-image: url("/img/flags/gs.svg");
}

.flag-gt {
  background-image: url("/img/flags/gt.svg");
}

.flag-gu {
  background-image: url("/img/flags/gu.svg");
}

.flag-gw {
  background-image: url("/img/flags/gw.svg");
}

.flag-gy {
  background-image: url("/img/flags/gy.svg");
}

.flag-hk {
  background-image: url("/img/flags/hk.svg");
}

.flag-hm {
  background-image: url("/img/flags/hm.svg");
}

.flag-hn {
  background-image: url("/img/flags/hn.svg");
}

.flag-hr {
  background-image: url("/img/flags/hr.svg");
}

.flag-ht {
  background-image: url("/img/flags/ht.svg");
}

.flag-hu {
  background-image: url("/img/flags/hu.svg");
}

.flag-id {
  background-image: url("/img/flags/id.svg");
}

.flag-ie {
  background-image: url("/img/flags/ie.svg");
}

.flag-il {
  background-image: url("/img/flags/il.svg");
}

.flag-im {
  background-image: url("/img/flags/im.svg");
}

.flag-in {
  background-image: url("/img/flags/in.svg");
}

.flag-io {
  background-image: url("/img/flags/io.svg");
}

.flag-iq {
  background-image: url("/img/flags/iq.svg");
}

.flag-ir {
  background-image: url("/img/flags/ir.svg");
}

.flag-is {
  background-image: url("/img/flags/is.svg");
}

.flag-it {
  background-image: url("/img/flags/it.svg");
}

.flag-je {
  background-image: url("/img/flags/je.svg");
}

.flag-jm {
  background-image: url("/img/flags/jm.svg");
}

.flag-jo {
  background-image: url("/img/flags/jo.svg");
}

.flag-jp {
  background-image: url("/img/flags/jp.svg");
}

.flag-ke {
  background-image: url("/img/flags/ke.svg");
}

.flag-kg {
  background-image: url("/img/flags/kg.svg");
}

.flag-kh {
  background-image: url("/img/flags/kh.svg");
}

.flag-ki {
  background-image: url("/img/flags/ki.svg");
}

.flag-km {
  background-image: url("/img/flags/km.svg");
}

.flag-kn {
  background-image: url("/img/flags/kn.svg");
}

.flag-kp {
  background-image: url("/img/flags/kp.svg");
}

.flag-kr {
  background-image: url("/img/flags/kr.svg");
}

.flag-kw {
  background-image: url("/img/flags/kw.svg");
}

.flag-ky {
  background-image: url("/img/flags/ky.svg");
}

.flag-kz {
  background-image: url("/img/flags/kz.svg");
}

.flag-la {
  background-image: url("/img/flags/la.svg");
}

.flag-lb {
  background-image: url("/img/flags/lb.svg");
}

.flag-lc {
  background-image: url("/img/flags/lc.svg");
}

.flag-li {
  background-image: url("/img/flags/li.svg");
}

.flag-lk {
  background-image: url("/img/flags/lk.svg");
}

.flag-lr {
  background-image: url("/img/flags/lr.svg");
}

.flag-ls {
  background-image: url("/img/flags/ls.svg");
}

.flag-lt {
  background-image: url("/img/flags/lt.svg");
}

.flag-lu {
  background-image: url("/img/flags/lu.svg");
}

.flag-lv {
  background-image: url("/img/flags/lv.svg");
}

.flag-ly {
  background-image: url("/img/flags/ly.svg");
}

.flag-ma {
  background-image: url("/img/flags/ma.svg");
}

.flag-mc {
  background-image: url("/img/flags/mc.svg");
}

.flag-md {
  background-image: url("/img/flags/md.svg");
}

.flag-me {
  background-image: url("/img/flags/me.svg");
}

.flag-mf {
  background-image: url("/img/flags/mf.svg");
}

.flag-mg {
  background-image: url("/img/flags/mg.svg");
}

.flag-mh {
  background-image: url("/img/flags/mh.svg");
}

.flag-mk {
  background-image: url("/img/flags/mk.svg");
}

.flag-ml {
  background-image: url("/img/flags/ml.svg");
}

.flag-mm {
  background-image: url("/img/flags/mm.svg");
}

.flag-mn {
  background-image: url("/img/flags/mn.svg");
}

.flag-mo {
  background-image: url("/img/flags/mo.svg");
}

.flag-mp {
  background-image: url("/img/flags/mp.svg");
}

.flag-mq {
  background-image: url("/img/flags/mq.svg");
}

.flag-mr {
  background-image: url("/img/flags/mr.svg");
}

.flag-ms {
  background-image: url("/img/flags/ms.svg");
}

.flag-mt {
  background-image: url("/img/flags/mt.svg");
}

.flag-mu {
  background-image: url("/img/flags/mu.svg");
}

.flag-mv {
  background-image: url("/img/flags/mv.svg");
}

.flag-mw {
  background-image: url("/img/flags/mw.svg");
}

.flag-mx {
  background-image: url("/img/flags/mx.svg");
}

.flag-my {
  background-image: url("/img/flags/my.svg");
}

.flag-mz {
  background-image: url("/img/flags/mz.svg");
}

.flag-na {
  background-image: url("/img/flags/na.svg");
}

.flag-nc {
  background-image: url("/img/flags/nc.svg");
}

.flag-ne {
  background-image: url("/img/flags/ne.svg");
}

.flag-nf {
  background-image: url("/img/flags/nf.svg");
}

.flag-ng {
  background-image: url("/img/flags/ng.svg");
}

.flag-ni {
  background-image: url("/img/flags/ni.svg");
}

.flag-nl {
  background-image: url("/img/flags/nl.svg");
}

.flag-no {
  background-image: url("/img/flags/no.svg");
}

.flag-np {
  background-image: url("/img/flags/np.svg");
}

.flag-nr {
  background-image: url("/img/flags/nr.svg");
}

.flag-nu {
  background-image: url("/img/flags/nu.svg");
}

.flag-nz {
  background-image: url("/img/flags/nz.svg");
}

.flag-om {
  background-image: url("/img/flags/om.svg");
}

.flag-pa {
  background-image: url("/img/flags/pa.svg");
}

.flag-pe {
  background-image: url("/img/flags/pe.svg");
}

.flag-pf {
  background-image: url("/img/flags/pf.svg");
}

.flag-pg {
  background-image: url("/img/flags/pg.svg");
}

.flag-ph {
  background-image: url("/img/flags/ph.svg");
}

.flag-pk {
  background-image: url("/img/flags/pk.svg");
}

.flag-pl {
  background-image: url("/img/flags/pl.svg");
}

.flag-pm {
  background-image: url("/img/flags/pm.svg");
}

.flag-pn {
  background-image: url("/img/flags/pn.svg");
}

.flag-pr {
  background-image: url("/img/flags/pr.svg");
}

.flag-ps {
  background-image: url("/img/flags/ps.svg");
}

.flag-pt {
  background-image: url("/img/flags/pt.svg");
}

.flag-pw {
  background-image: url("/img/flags/pw.svg");
}

.flag-py {
  background-image: url("/img/flags/py.svg");
}

.flag-qa {
  background-image: url("/img/flags/qa.svg");
}

.flag-re {
  background-image: url("/img/flags/re.svg");
}

.flag-ro {
  background-image: url("/img/flags/ro.svg");
}

.flag-rs {
  background-image: url("/img/flags/rs.svg");
}

.flag-ru {
  background-image: url("/img/flags/ru.svg");
}

.flag-rw {
  background-image: url("/img/flags/rw.svg");
}

.flag-sa {
  background-image: url("/img/flags/sa.svg");
}

.flag-sb {
  background-image: url("/img/flags/sb.svg");
}

.flag-sc {
  background-image: url("/img/flags/sc.svg");
}

.flag-sd {
  background-image: url("/img/flags/sd.svg");
}

.flag-se {
  background-image: url("/img/flags/se.svg");
}

.flag-sg {
  background-image: url("/img/flags/sg.svg");
}

.flag-sh {
  background-image: url("/img/flags/sh.svg");
}

.flag-si {
  background-image: url("/img/flags/si.svg");
}

.flag-sj {
  background-image: url("/img/flags/sj.svg");
}

.flag-sk {
  background-image: url("/img/flags/sk.svg");
}

.flag-sl {
  background-image: url("/img/flags/sl.svg");
}

.flag-sm {
  background-image: url("/img/flags/sm.svg");
}

.flag-sn {
  background-image: url("/img/flags/sn.svg");
}

.flag-so {
  background-image: url("/img/flags/so.svg");
}

.flag-sr {
  background-image: url("/img/flags/sr.svg");
}

.flag-ss {
  background-image: url("/img/flags/ss.svg");
}

.flag-st {
  background-image: url("/img/flags/st.svg");
}

.flag-sv {
  background-image: url("/img/flags/sv.svg");
}

.flag-sx {
  background-image: url("/img/flags/sx.svg");
}

.flag-sy {
  background-image: url("/img/flags/sy.svg");
}

.flag-sz {
  background-image: url("/img/flags/sz.svg");
}

.flag-tc {
  background-image: url("/img/flags/tc.svg");
}

.flag-td {
  background-image: url("/img/flags/td.svg");
}

.flag-tf {
  background-image: url("/img/flags/tf.svg");
}

.flag-tg {
  background-image: url("/img/flags/tg.svg");
}

.flag-th {
  background-image: url("/img/flags/th.svg");
}

.flag-tj {
  background-image: url("/img/flags/tj.svg");
}

.flag-tk {
  background-image: url("/img/flags/tk.svg");
}

.flag-tl {
  background-image: url("/img/flags/tl.svg");
}

.flag-tm {
  background-image: url("/img/flags/tm.svg");
}

.flag-tn {
  background-image: url("/img/flags/tn.svg");
}

.flag-to {
  background-image: url("/img/flags/to.svg");
}

.flag-tr {
  background-image: url("/img/flags/tr.svg");
}

.flag-tt {
  background-image: url("/img/flags/tt.svg");
}

.flag-tv {
  background-image: url("/img/flags/tv.svg");
}

.flag-tw {
  background-image: url("/img/flags/tw.svg");
}

.flag-tz {
  background-image: url("/img/flags/tz.svg");
}

.flag-ua {
  background-image: url("/img/flags/ua.svg");
}

.flag-ug {
  background-image: url("/img/flags/ug.svg");
}

.flag-um {
  background-image: url("/img/flags/um.svg");
}

.flag-un {
  background-image: url("/img/flags/un.svg");
}

.flag-us {
  background-image: url("/img/flags/us.svg");
}

.flag-uy {
  background-image: url("/img/flags/uy.svg");
}

.flag-uz {
  background-image: url("/img/flags/uz.svg");
}

.flag-va {
  background-image: url("/img/flags/va.svg");
}

.flag-vc {
  background-image: url("/img/flags/vc.svg");
}

.flag-ve {
  background-image: url("/img/flags/ve.svg");
}

.flag-vg {
  background-image: url("/img/flags/vg.svg");
}

.flag-vi {
  background-image: url("/img/flags/vi.svg");
}

.flag-vn {
  background-image: url("/img/flags/vn.svg");
}

.flag-vu {
  background-image: url("/img/flags/vu.svg");
}

.flag-wf {
  background-image: url("/img/flags/wf.svg");
}

.flag-ws {
  background-image: url("/img/flags/ws.svg");
}

.flag-ye {
  background-image: url("/img/flags/ye.svg");
}

.flag-yt {
  background-image: url("/img/flags/yt.svg");
}

.flag-za {
  background-image: url("/img/flags/za.svg");
}

.flag-zm {
  background-image: url("/img/flags/zm.svg");
}

.flag-zw {
  background-image: url("/img/flags/zw.svg");
}

.payment {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  background: no-repeat center/100% 100%;
  vertical-align: bottom;
  font-style: normal;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
}

.payment-2checkout-dark {
  background-image: url("/img/payments/2checkout-dark.svg");
}

.payment-2checkout {
  background-image: url("/img/payments/2checkout.svg");
}

.payment-alipay-dark {
  background-image: url("/img/payments/alipay-dark.svg");
}

.payment-alipay {
  background-image: url("/img/payments/alipay.svg");
}

.payment-amazon-dark {
  background-image: url("/img/payments/amazon-dark.svg");
}

.payment-amazon {
  background-image: url("/img/payments/amazon.svg");
}

.payment-americanexpress-dark {
  background-image: url("/img/payments/americanexpress-dark.svg");
}

.payment-americanexpress {
  background-image: url("/img/payments/americanexpress.svg");
}

.payment-applepay-dark {
  background-image: url("/img/payments/applepay-dark.svg");
}

.payment-applepay {
  background-image: url("/img/payments/applepay.svg");
}

.payment-bancontact-dark {
  background-image: url("/img/payments/bancontact-dark.svg");
}

.payment-bancontact {
  background-image: url("/img/payments/bancontact.svg");
}

.payment-bitcoin-dark {
  background-image: url("/img/payments/bitcoin-dark.svg");
}

.payment-bitcoin {
  background-image: url("/img/payments/bitcoin.svg");
}

.payment-bitpay-dark {
  background-image: url("/img/payments/bitpay-dark.svg");
}

.payment-bitpay {
  background-image: url("/img/payments/bitpay.svg");
}

.payment-cirrus-dark {
  background-image: url("/img/payments/cirrus-dark.svg");
}

.payment-cirrus {
  background-image: url("/img/payments/cirrus.svg");
}

.payment-clickandbuy-dark {
  background-image: url("/img/payments/clickandbuy-dark.svg");
}

.payment-clickandbuy {
  background-image: url("/img/payments/clickandbuy.svg");
}

.payment-coinkite-dark {
  background-image: url("/img/payments/coinkite-dark.svg");
}

.payment-coinkite {
  background-image: url("/img/payments/coinkite.svg");
}

.payment-dinersclub-dark {
  background-image: url("/img/payments/dinersclub-dark.svg");
}

.payment-dinersclub {
  background-image: url("/img/payments/dinersclub.svg");
}

.payment-directdebit-dark {
  background-image: url("/img/payments/directdebit-dark.svg");
}

.payment-directdebit {
  background-image: url("/img/payments/directdebit.svg");
}

.payment-discover-dark {
  background-image: url("/img/payments/discover-dark.svg");
}

.payment-discover {
  background-image: url("/img/payments/discover.svg");
}

.payment-dwolla-dark {
  background-image: url("/img/payments/dwolla-dark.svg");
}

.payment-dwolla {
  background-image: url("/img/payments/dwolla.svg");
}

.payment-ebay-dark {
  background-image: url("/img/payments/ebay-dark.svg");
}

.payment-ebay {
  background-image: url("/img/payments/ebay.svg");
}

.payment-eway-dark {
  background-image: url("/img/payments/eway-dark.svg");
}

.payment-eway {
  background-image: url("/img/payments/eway.svg");
}

.payment-giropay-dark {
  background-image: url("/img/payments/giropay-dark.svg");
}

.payment-giropay {
  background-image: url("/img/payments/giropay.svg");
}

.payment-googlewallet-dark {
  background-image: url("/img/payments/googlewallet-dark.svg");
}

.payment-googlewallet {
  background-image: url("/img/payments/googlewallet.svg");
}

.payment-ingenico-dark {
  background-image: url("/img/payments/ingenico-dark.svg");
}

.payment-ingenico {
  background-image: url("/img/payments/ingenico.svg");
}

.payment-jcb-dark {
  background-image: url("/img/payments/jcb-dark.svg");
}

.payment-jcb {
  background-image: url("/img/payments/jcb.svg");
}

.payment-klarna-dark {
  background-image: url("/img/payments/klarna-dark.svg");
}

.payment-klarna {
  background-image: url("/img/payments/klarna.svg");
}

.payment-laser-dark {
  background-image: url("/img/payments/laser-dark.svg");
}

.payment-laser {
  background-image: url("/img/payments/laser.svg");
}

.payment-maestro-dark {
  background-image: url("/img/payments/maestro-dark.svg");
}

.payment-maestro {
  background-image: url("/img/payments/maestro.svg");
}

.payment-mastercard-dark {
  background-image: url("/img/payments/mastercard-dark.svg");
}

.payment-mastercard {
  background-image: url("/img/payments/mastercard.svg");
}

.payment-monero-dark {
  background-image: url("/img/payments/monero-dark.svg");
}

.payment-monero {
  background-image: url("/img/payments/monero.svg");
}

.payment-neteller-dark {
  background-image: url("/img/payments/neteller-dark.svg");
}

.payment-neteller {
  background-image: url("/img/payments/neteller.svg");
}

.payment-ogone-dark {
  background-image: url("/img/payments/ogone-dark.svg");
}

.payment-ogone {
  background-image: url("/img/payments/ogone.svg");
}

.payment-okpay-dark {
  background-image: url("/img/payments/okpay-dark.svg");
}

.payment-okpay {
  background-image: url("/img/payments/okpay.svg");
}

.payment-paybox-dark {
  background-image: url("/img/payments/paybox-dark.svg");
}

.payment-paybox {
  background-image: url("/img/payments/paybox.svg");
}

.payment-paymill-dark {
  background-image: url("/img/payments/paymill-dark.svg");
}

.payment-paymill {
  background-image: url("/img/payments/paymill.svg");
}

.payment-payone-dark {
  background-image: url("/img/payments/payone-dark.svg");
}

.payment-payone {
  background-image: url("/img/payments/payone.svg");
}

.payment-payoneer-dark {
  background-image: url("/img/payments/payoneer-dark.svg");
}

.payment-payoneer {
  background-image: url("/img/payments/payoneer.svg");
}

.payment-paypal-dark {
  background-image: url("/img/payments/paypal-dark.svg");
}

.payment-paypal {
  background-image: url("/img/payments/paypal.svg");
}

.payment-paysafecard-dark {
  background-image: url("/img/payments/paysafecard-dark.svg");
}

.payment-paysafecard {
  background-image: url("/img/payments/paysafecard.svg");
}

.payment-payu-dark {
  background-image: url("/img/payments/payu-dark.svg");
}

.payment-payu {
  background-image: url("/img/payments/payu.svg");
}

.payment-payza-dark {
  background-image: url("/img/payments/payza-dark.svg");
}

.payment-payza {
  background-image: url("/img/payments/payza.svg");
}

.payment-ripple-dark {
  background-image: url("/img/payments/ripple-dark.svg");
}

.payment-ripple {
  background-image: url("/img/payments/ripple.svg");
}

.payment-sage-dark {
  background-image: url("/img/payments/sage-dark.svg");
}

.payment-sage {
  background-image: url("/img/payments/sage.svg");
}

.payment-sepa-dark {
  background-image: url("/img/payments/sepa-dark.svg");
}

.payment-sepa {
  background-image: url("/img/payments/sepa.svg");
}

.payment-shopify-dark {
  background-image: url("/img/payments/shopify-dark.svg");
}

.payment-shopify {
  background-image: url("/img/payments/shopify.svg");
}

.payment-skrill-dark {
  background-image: url("/img/payments/skrill-dark.svg");
}

.payment-skrill {
  background-image: url("/img/payments/skrill.svg");
}

.payment-solo-dark {
  background-image: url("/img/payments/solo-dark.svg");
}

.payment-solo {
  background-image: url("/img/payments/solo.svg");
}

.payment-square-dark {
  background-image: url("/img/payments/square-dark.svg");
}

.payment-square {
  background-image: url("/img/payments/square.svg");
}

.payment-stripe-dark {
  background-image: url("/img/payments/stripe-dark.svg");
}

.payment-stripe {
  background-image: url("/img/payments/stripe.svg");
}

.payment-switch-dark {
  background-image: url("/img/payments/switch-dark.svg");
}

.payment-switch {
  background-image: url("/img/payments/switch.svg");
}

.payment-ukash-dark {
  background-image: url("/img/payments/ukash-dark.svg");
}

.payment-ukash {
  background-image: url("/img/payments/ukash.svg");
}

.payment-unionpay-dark {
  background-image: url("/img/payments/unionpay-dark.svg");
}

.payment-unionpay {
  background-image: url("/img/payments/unionpay.svg");
}

.payment-verifone-dark {
  background-image: url("/img/payments/verifone-dark.svg");
}

.payment-verifone {
  background-image: url("/img/payments/verifone.svg");
}

.payment-verisign-dark {
  background-image: url("/img/payments/verisign-dark.svg");
}

.payment-verisign {
  background-image: url("/img/payments/verisign.svg");
}

.payment-visa-dark {
  background-image: url("/img/payments/visa-dark.svg");
}

.payment-visa {
  background-image: url("/img/payments/visa.svg");
}

.payment-webmoney-dark {
  background-image: url("/img/payments/webmoney-dark.svg");
}

.payment-webmoney {
  background-image: url("/img/payments/webmoney.svg");
}

.payment-westernunion-dark {
  background-image: url("/img/payments/westernunion-dark.svg");
}

.payment-westernunion {
  background-image: url("/img/payments/westernunion.svg");
}

.payment-worldpay-dark {
  background-image: url("/img/payments/worldpay-dark.svg");
}

.payment-worldpay {
  background-image: url("/img/payments/worldpay.svg");
}

svg {
  -ms-touch-action: none;
  touch-action: none;
}

body * {
  font-family: "Poppins", sans-serif;
}
body *::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  transition: 0.3s background;
}
body *::-webkit-scrollbar-thumb, body *:hover::-webkit-scrollbar-thumb {
  background: #c2cbdf;
}
body *::-webkit-scrollbar-track {
  background: #fff;
}

/*------ Headers -------*/
.page-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 1.5rem 0 1.5rem;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  position: relative;
}

.page-title {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 2.5rem;
  position: relative;
}

.page-title-icon {
  color: #9aa0ac;
  font-size: 1.25rem;
}

.page-subtitle {
  font-size: 0.8125rem;
  color: #6e7687;
  margin-left: 2rem;
  position: relative;
}
.page-subtitle a {
  color: inherit;
}

.page-options {
  margin-left: auto;
}

.page-description {
  margin: 0.25rem 0 0;
  color: #6e7687;
}
.page-description a {
  color: inherit;
}

.page-single, .page-single-content {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content-heading {
  font-weight: 400;
  margin: 2rem 0 1.5rem;
  font-size: 1.25rem;
  line-height: 1.25;
}
.content-heading:first-child {
  margin-top: 0;
}

.aside {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 22rem;
  background: #fff;
  border-left: 1px solid #ebecf1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 100;
  visibility: hidden;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.aside-progress {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.aside-progress-position {
  position: relative;
  bottom: 1.5rem;
  width: 100%;
}

@media (min-width: 1600px) {
  body.aside-opened .aside {
    visibility: visible;
  }
}
.aside-body {
  padding: 1.5rem;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow: auto;
}

.aside-footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid #ebecf1;
}

.aside-header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #ebecf1;
}

.header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background: #fff;
}

body.fixed-header .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
}

.header .dropdown-menu {
  margin-top: 0;
  border-radius: 0px 0 4px 4px !important;
}
.header .mega-menu {
  width: 350px;
}

.nav-unread {
  position: absolute;
  top: 0.1rem;
  right: 0.4rem;
  background: #ecd938;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}
.nav-unread.badge {
  position: absolute;
  top: 0;
  text-align: center;
  right: 0;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
}

.nav-link.icon {
  padding: 5px;
  text-align: center;
  height: 2.5rem;
  font-size: 1.2rem;
  position: relative;
  border-radius: 3px;
}

.app-header.header .nav-link.icon {
  padding: 5px;
}

.nav-link.icon .nav-span {
  font-size: 0.9rem;
  color: #576482;
  vertical-align: middle;
  margin-left: 0.5rem;
  font-weight: 400;
}

.header-brand {
  color: inherit;
  margin-right: 0;
  font-size: 1.25rem;
  white-space: nowrap;
  font-weight: 600;
  padding: 0;
  transition: 0.3s opacity;
  line-height: 2.9rem;
}

.app-header .header-brand {
  color: inherit;
  margin-right: 1rem;
  font-size: 1.25rem;
  white-space: nowrap;
  font-weight: 600;
  padding: 0;
  transition: 0.3s opacity;
  line-height: 2.9rem;
  min-width: 220px;
  text-align: center;
}
.app-header .header-brand .header-brand-img {
  margin-right: 0;
}

.app-header1 .header-brand {
  color: inherit;
  margin-right: 0;
  font-size: 1.25rem;
  white-space: nowrap;
  font-weight: 600;
  padding: 0;
  transition: 0.3s opacity;
  line-height: 2.9rem;
  min-width: 215px;
  text-align: center;
}
.app-header1 .header-brand .header-brand-img {
  margin-right: 0;
}

.header-brand:hover {
  color: inherit;
  text-decoration: none;
}

.header-brand-img {
  height: 2.5rem;
  line-height: 2rem;
  vertical-align: bottom;
  margin-right: 0;
  width: auto;
}

.desktop-lgo {
  height: auto !important;
  margin-top: -0.5rem;
}

.csp-brand-img {
  height: 70px;
  margin-right: 0;
  width: auto;
  margin-top: -1rem;
}

.header-btn.has-new {
  position: relative;
}
.header-btn.has-new:before {
  content: "";
  width: 6px;
  height: 6px;
  background: #dc0441;
  position: absolute;
  top: 4px;
  right: 4px;
  border-radius: 50%;
}

.header-toggler {
  width: 3rem;
  height: 3rem;
  position: relative;
  color: #000;
}
.header-toggler:hover {
  color: #32e448;
}

.header-toggler-icon {
  position: absolute;
  width: 1rem;
  height: 2px;
  color: inherit;
  background: currentColor;
  border-radius: 3px;
  top: 50%;
  left: 50%;
  margin: -2px 0 0 -0.5rem;
  box-shadow: 0 5px currentColor, 0 -5px currentColor;
}

.footer {
  font-size: 12px;
  padding: 1.25rem 0;
}

footer.footer {
  font-size: 12px;
  border-top: 0;
  padding: 0 1.3rem 1rem;
  display: block;
  margin-top: auto;
}

.footer .social {
  margin: 0 auto;
}
.footer .social ul li {
  float: left;
  padding: 7px;
}
.footer .social ul li a {
  color: #abb8c3;
}
.footer a:not(.btn) {
  color: #007BFF;
  font-weight: 800;
}

@media print {
  .footer, .header {
    display: none;
  }
}
.br-100 {
  border-radius: 100% !important;
}

.br-3 {
  border-radius: 3px !important;
}

.br-7 {
  border-radius: 7px !important;
}

.br-tl-7 {
  border-top-left-radius: 7px !important;
}

.br-bl-7 {
  border-bottom-left-radius: 7px !important;
}

.br-tr-7 {
  border-top-right-radius: 7px !important;
}

.br-br-7 {
  border-bottom-right-radius: 7px !important;
}

.br-0 {
  border-radius: 0px;
}

.br-tl-0 {
  border-top-left-radius: 0px !important;
}

.br-bl-0 {
  border-bottom-left-radius: 0px !important;
}

.br-tr-0 {
  border-top-right-radius: 0px !important;
}

.br-br-0 {
  border-bottom-right-radius: 0px !important;
}

.list.d-flex img {
  width: 60px;
}

#popup {
  position: absolute;
  width: auto;
  height: 30px;
  background: #fe6b1f;
  display: none;
  color: white;
  border-radius: 5px;
}

#copy {
  background: none;
  color: white;
  font-weight: bold;
  padding: 8px 25px;
  border: 0;
}

/*------ Charts styles ------*/
.h-85 {
  height: 85px !important;
}

.chart-visitors {
  min-height: 18rem;
  overflow: hidden;
}

.chart-height {
  height: 20rem;
  overflow: hidden;
}

.chart-tasks {
  height: 15rem;
  overflow: hidden;
}

.chart-donut {
  height: 21rem;
  overflow: hidden;
}

.chart-pie {
  height: 22rem;
  overflow: hidden;
}

.chartsh {
  height: 16rem;
  overflow: hidden;
}

.chartwidget {
  height: 17rem;
  overflow: hidden;
}

/*-----Countdown-----*/
.countdown span:first-child {
  font-size: 30px;
  font-weight: 500;
}

.social i {
  font-size: 40px;
}
.social .social-icon i {
  font-size: 14px;
}

.instagram {
  background: #de497b;
}

.linkedin {
  background: #0077b5;
}

.twitter {
  background: #1da1f2;
}

.facebook {
  background: #3b5998;
}

.widget-info i {
  width: 100px;
  height: 100px;
  padding: 27px 0;
  text-align: center;
  border: 2px solid #fff;
  border-radius: 50%;
}
.widget-info a {
  border-bottom: 1px solid #fff;
}

.z-index-10 {
  z-index: 10 !important;
}

.card-blog-overlay .card-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.card-blog-overlay1:before, .card-blog-overlay2:before {
  content: "";
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  border-radius: 7px;
}

.page-breadcrumb {
  background: none;
  padding: 0;
  margin: 1rem 0 0;
  font-size: 0.875rem;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.page-breadcrumb .breadcrumb-item {
  color: #9aa0ac;
}
.page-breadcrumb .breadcrumb-item.active {
  color: #6e7687;
}

@media (min-width: 768px) {
  .page-breadcrumb {
    margin: -0.5rem 0 0;
  }
}
@media (max-width: 320px) {
  .fc-toolbar .fc-right {
    float: right;
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }
}
.pagination-simple .page-item .page-link {
  background: none;
  border: none;
}
.pagination-simple .page-item.active .page-link {
  color: #495057;
  font-weight: 700;
}

.pagination-pager .page-prev {
  margin-right: auto;
}
.pagination-pager .page-next {
  margin-left: auto;
}

.page-total-text {
  margin-right: 1rem;
  -ms-flex-item-align: center;
  align-self: center;
  color: #6e7687;
}

.icons-list {
  list-style: none;
  margin: 0 -1px -1px 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.icons-list > li {
  -ms-flex: 1 0 2.5rem;
  flex: 1 0 2.5rem;
}

.icons-list-wrap {
  overflow: hidden;
}

.flags-icons .icons-list-item {
  line-height: inherit;
}

.payment-icons .icons-list-item {
  line-height: 2rem;
}

.icons-list-item {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  border-radius: 100%;
  font-size: 18px;
  border-radius: 50px;
  box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
  border: 1px solid #ebecf1;
  vertical-align: middle;
  color: #5c678f;
  margin: 5px;
}

.img-gallery {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
  margin-bottom: -0.5rem;
}
.img-gallery > .col, .img-gallery > [class*=col-] {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-bottom: 0.5rem;
}

.link-overlay {
  position: relative;
}
.link-overlay:hover .link-overlay-bg {
  opacity: 1;
}

.link-overlay-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(70, 127, 207, 0.8);
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.25rem;
  opacity: 0;
  transition: 0.3s opacity;
}

.media-icon {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  border-radius: 100%;
  font-size: 18px;
}

.media-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea[cols] {
  height: auto;
}

/* --------Added--------- css*/
.overflow-hidden {
  overflow: hidden;
}

.user-profile .user-pro-body .u-dropdown {
  color: #fff;
}

#sidebar ul li {
  position: relative;
}
#sidebar ul li a {
  color: #384056;
  font-weight: 400;
}
#sidebar ul li ul li:last-child {
  border-bottom: 0;
}
#sidebar li a[aria-expanded=true]:hover {
  color: #fff !important;
}

.timeline__content {
  background-color: #eaf0f9;
}

.tab_wrapper > ul {
  border-bottom: 1px solid #ebecf1;
}
.tab_wrapper > ul li {
  border: 1px solid #ebecf1;
  border-top: 1px solid #ebecf1;
}
.tab_wrapper.right_side .content_wrapper {
  border: 1px solid #ebecf1;
}
.tab_wrapper.right_side > ul {
  border-bottom: 1px solid #ebecf1;
}
.tab_wrapper.right_side > ul li.active {
  border-color: 1px solid #ebecf1;
}
.tab_wrapper.right_side > ul li:after {
  background: 1px solid #ebecf1;
}

.text-right {
  text-align: right;
}

/*--- img container---*/
.search {
  position: absolute;
  width: 320px;
}
.search svg {
  transform: translateX(-126px);
  width: 180px;
  height: auto;
  stroke-width: 8px;
  stroke: #fff;
  stroke-width: 1px;
  stroke-dashoffset: 0;
  stroke-dasharray: 64.6 206.305;
  transition: all 0.5s ease-in-out;
  margin-top: 5px;
}

.input-search {
  position: absolute;
  width: calc(100% - 148px);
  height: 34px;
  top: 0;
  right: 20px;
  bottom: 0;
  left: 0;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 20px;
  font-size: 16px;
  color: #fff;
}

.search-label {
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  top: 0;
  left: 50%;
  margin-left: -54px;
  z-index: 100;
  transition: 0.5s ease-in-out;
}

.isActive .search-label {
  transform: translateX(246px);
}
.isActive svg, .isActive.full svg {
  stroke-dashoffset: -65;
  stroke-dasharray: 141.305 65;
  transform: translateX(0);
}

.full .search-label {
  transform: translateX(246px);
}
.full svg {
  stroke-dashoffset: 0;
  stroke-dasharray: 64.6 206.305;
  transform: translateX(0);
}

.search-inline {
  width: 100%;
  left: 0;
  padding: 33px 0;
  top: 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  z-index: 9;
  transition: all 0.5s ease-in-out;
}
.search-inline.search-visible {
  opacity: 1;
  visibility: visible;
  animation: fadeInDown 0.2s ease-in-out;
}
.search-inline button[type=submit] {
  position: absolute;
  right: 52px;
  top: 0;
  background-color: transparent;
  border: 0px;
  top: 0;
  right: 80px;
  padding: 0px;
  cursor: pointer;
  width: 80px;
  height: 100%;
  color: #fff;
}
.search-inline .form-control {
  border: 0px;
  padding-left: 0;
  font-size: 20px;
  position: absolute;
  left: 1%;
  top: 0;
  height: 100%;
  width: 99%;
  outline: none;
}
.search-inline .form-control:focus {
  box-shadow: none;
}
.search-inline .search-close {
  position: absolute;
  top: 0;
  right: 0;
  color: #616161;
  width: 80px;
  height: 100%;
  text-align: center;
  display: table;
  background: #efefef;
  text-decoration: none;
}
.search-inline .search-close i {
  display: table-cell;
  vertical-align: middle;
}

.searching i {
  font-size: 18px;
}

.search-close i {
  padding: 24px 0;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.lockscreen {
  background: inherit;
  width: 300px;
  height: 280px;
  overflow: hidden;
}
.lockscreen:before {
  content: "";
  width: 350px;
  height: 400px;
  background: inherit;
  position: absolute;
  left: -25px;
  right: 0;
  top: -25px;
  bottom: 0;
  box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.4);
  filter: blur(1px);
}

.table-stats table th, .table-stats table td {
  border: none;
  border-bottom: 1px solid #e8e9ef;
  font-size: 12px;
  font-weight: normal;
  padding: 0.75em 1.25em;
  text-transform: uppercase;
}
.table-stats table th img, .table-stats table td img {
  margin-right: 10px;
  max-width: 45px;
}
.table-stats table th .name {
  font-size: 14px;
  text-transform: capitalize;
}
.table-stats table td {
  font-size: 14px;
  text-transform: capitalize;
  vertical-align: middle;
}
.table-stats table td .name {
  font-size: 14px;
  text-transform: capitalize;
}

.traffic-sales-content {
  padding: 14px;
}

.traffic-sales-amount {
  float: right;
}

.pulse {
  display: block;
  position: absolute;
  top: 11px;
  right: 15px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ff3547;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(247, 45, 102, 0.9);
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.9);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.9);
    box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.7);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
    box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
    box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
  }
}
@media (max-width: 992px) {
  .header.top-header .header-option, .header.top-header .header-setting {
    display: none;
  }
  .hor-header.header {
    position: fixed;
    width: 100%;
    z-index: 999;
  }
  .hor-content.main-content {
    margin-top: 6rem;
  }
  .horizontal-main.hor-menu {
    border-bottom: 0;
  }
  .page-body {
    padding-top: 1px;
  }
  .app-content.page-body .container, .header.top-header .container, .hor-content.main-content .container {
    max-width: none;
  }
  .active .header.top-header {
    z-index: 999;
  }
  .app-content.page-body .side-app {
    padding: 6.2rem 0.75rem 0 0.75rem !important;
  }
  .navsearch {
    margin: 5px;
    padding: 0px;
    text-align: center;
    height: 2.5rem;
    font-size: 1.2rem;
    position: relative;
    border: 0px solid #ebecf1;
    border-radius: 3px;
  }
  .navsearch svg {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    width: 35px;
    height: 35px;
    background: #fff;
    padding: 8px;
    border-radius: 50px;
    box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
    border: 1px solid #ebecf1;
  }
  .navsearch i {
    padding-top: 0 !important;
  }
  .app-content.page-body {
    margin-top: 4.7rem;
  }
  .comb-page .app-content.page-body {
    margin-top: 0;
  }
}
.header.top-header .search-element input {
  border-radius: 4px;
}

@media (max-width: 992px) {
  .media.profile-footer .media-user {
    display: none;
  }
}
@media (max-width: 992px) and (min-width: 768px) {
  .header-brand-img.desktop-lgo {
    margin-left: 2rem;
  }
  .hor-header .header-brand-img.desktop-lgo, .header-brand-img.dark-logo {
    margin-left: 1rem;
  }
  .animated-arrow.hor-toggle {
    text-align: center;
    height: 2.5rem;
    width: 2.5rem;
    font-size: 1.2rem;
    position: relative;
    border: 1px solid #ebecf1;
    border-radius: 50px;
    margin: 5px;
    top: 0;
  }
  .animated-arrow.hor-toggle span {
    margin-left: 8px;
    margin-top: 2px;
    vertical-align: middle;
  }
}
.activity {
  position: relative;
  border-left: 3px solid #ebecf1;
  margin-left: 16px;
}
.activity .img-activity {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 44px;
  border-radius: 50%;
  position: absolute;
  left: -20px;
  border: 3px solid;
}
.activity .item-activity {
  margin-left: 40px;
  margin-bottom: 26px;
}

#AreaChart2 {
  width: 105% !important;
  height: 88px !important;
  bottom: -20px;
  position: relative;
  left: -7px;
}

#CryptoChart, #CryptoChart1, #CryptoChart2, #CryptoChart3 {
  width: 105% !important;
  height: 70px !important;
  bottom: -15px;
  position: relative;
  left: -7px;
}

/* News ticker */
[class*=js-conveyor-] ul li {
  padding: 9px 15px;
  /* line-height: 47px; */
  border: 1px solid #efefef;
  margin-right: 5px;
  background: #fff;
  margin: 15px 6px;
  border-radius: 5px;
  box-shadow: 0 0px 10px -5px rgba(68, 84, 195, 0.5);
}

.news-crypto span {
  font-size: 0.8rem;
}

/*latetes news timeline */
.latest-timeline {
  height: 355px;
}
.latest-timeline ul.timeline {
  list-style-type: none;
  position: relative;
}
.latest-timeline ul.timeline:before {
  content: " ";
  display: inline-block;
  position: absolute;
  left: 10px;
  height: 100%;
  z-index: 1;
  border-left: 3px dotted #ebecf1;
}
.latest-timeline ul.timeline > li {
  margin: 20px 0;
  padding-left: 2.5rem;
}
.latest-timeline ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #1753fc;
  left: 5px;
  width: 13px;
  height: 13px;
  z-index: 1;
}
.latest-timeline ul.timeline > li:first-child:before {
  border: 2px solid #0dd157;
}
.latest-timeline ul.timeline > li:nth-child(2):before {
  border: 2px solid #2d9eef;
}
.latest-timeline ul.timeline > li:nth-child(3):before {
  border: 2px solid #f35e90;
}
.latest-timeline ul.timeline > li:nth-child(4):before {
  border: 2px solid #fc7303;
}
.latest-timeline ul.timeline > li:nth-child(5):before {
  border: 2px solid #05a5ba;
}
.latest-timeline ul.timeline > li:nth-child(6):before {
  border: 2px solid #920bfa;
}
.latest-timeline ul.timeline > li:last-child:before {
  border: 2px solid #ec2d38;
}

.app-content.page-body {
  min-height: 67vh;
}

.time-data {
  font-size: 14px;
  font-weight: 500;
}

/*
 *  Accordion-Wizard-Form
 * ----------------------
 */
div[data-acc-content] {
  display: none;
}
div[data-acc-step]:not(.open) {
  background: #f0f0f2;
}
div[data-acc-step]:not(.open) h5 {
  color: #8e9cad;
}
div[data-acc-step]:not(.open) .badge-primary {
  background: #afb4d8;
}

#form .list-group-item:hover {
  background: none;
}

.sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
  background: transparent;
}

.fc-widget-content .fc-bgevent {
  background-color: #f0f0f2 !important;
}

.apexcharts-toolbar {
  z-index: 0 !important;
  display: none;
}

.card-bgimg {
  background-size: cover;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  border: 0;
}
.card-bgimg:before {
  opacity: 0.9;
  border-radius: 3px;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  display: block;
  top: 0;
}
.card-bgimg .card-body {
  z-index: 10;
}

/*Horizontal Centerlogo */
@media (min-width: 992px) {
  .top-header.hor-top-header .header-brand-img {
    display: none;
  }
  .top-header.hor-top-header .header-brand.center-logo .desktop-lgo {
    left: 0;
    display: block;
    position: absolute;
    margin: 0 auto;
    right: 0px;
  }
  .top-header.hor-top-header .form-inline .form-control {
    width: 175px;
  }
  .top-header.hor-top-header .header-brand {
    margin-right: 0rem;
  }
  .search-element {
    margin-top: 4px;
  }
  .sticky-pin {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
  }
}
@media (max-width: 991px) {
  .top-header.hor-top-header .header-brand.center-logo .desktop-lgo {
    display: none;
  }
  .header.top-header.hor-top-header {
    border-bottom: 0px;
  }
}
.icon.icon-shape {
  width: 2.5rem;
  height: 2.2rem;
  line-height: 2.2rem;
  text-align: center;
  font-size: 12px;
}

.h-250 {
  height: 150px;
}

.latest-timeline1 li {
  position: relative;
}

.latest-timeline.latest-timeline1-icon ul.timeline > li:before {
  display: none;
}

.latest-timeline1-icon {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 7px;
  line-height: 2rem;
  text-align: center;
  left: 0;
  z-index: 1;
  color: #fff;
  font-weight: 500;
}

.h-330 {
  height: 330px;
}

.shadow {
  box-shadow: 0 2px 16px rgba(179, 186, 212, 0.7) !important;
}

.shadow3 {
  box-shadow: 0 0px 6px rgba(179, 186, 212, 0.7) !important;
}

.apexcharts-tooltip.light {
  border: 1px solid #e3e3e3;
  background: rgba(255, 255, 255, 0.96);
  box-shadow: 0 0px 17px -5px rgba(68, 84, 195, 0.5);
}

.chart-dropshadow-primary {
  filter: drop-shadow(-6px 12px 4px rgba(68, 84, 195, 0.2));
}

.icon-dropshadow {
  filter: drop-shadow(0px 10px 4px rgb(229, 233, 242));
}

.icon-dropshadow-info {
  filter: drop-shadow(0px 4px 4px rgba(69, 170, 242, 0.3));
}

.icon-dropshadow-success {
  filter: drop-shadow(0px 4px 4px rgba(45, 206, 137, 0.3));
}

.icon-dropshadow-danger {
  filter: drop-shadow(0px 4px 4px rgba(255, 91, 81, 0.3));
}

.icon-dropshadow-warning {
  filter: drop-shadow(0px 4px 4px rgba(236, 180, 3, 0.3));
}

.chart-dropshadow {
  -webkit-filter: drop-shadow(0 6px 4px rgba(179, 186, 212, 0.5));
  filter: drop-shadow(0 6px 4px rgba(179, 186, 212, 0.5));
}

.GradientlineShadow {
  -webkit-filter: drop-shadow(0 -8px 4px rgba(179, 186, 212, 0.2));
  filter: drop-shadow(0 -8px 4px rgba(179, 186, 212, 0.2));
}

.BarChartShadow {
  -webkit-filter: drop-shadow(0px 1px 4px rgba(179, 186, 212, 0.3));
  filter: drop-shadow(0px 1px 4px rgba(179, 186, 212, 0.3));
}

.donutShadow {
  -webkit-filter: drop-shadow(-5px 0px 10px rgba(179, 186, 212, 0.4));
  filter: drop-shadow(-5px 0px 10px rgba(179, 186, 212, 0.4));
}

.transaction-table.table td {
  vertical-align: middle;
}
.transaction-table.table .apexcharts-tooltip.light {
  display: none;
}
.transaction-table.table thead th {
  padding-bottom: 20px;
}

.inde4-table tr td:first-child {
  position: relative;
}
.inde4-table tr td:first-child:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 20px;
  left: 0px;
  top: 17px;
}
.inde4-table tr:nth-child(1) td:first-child:before {
  background: rgba(68, 84, 195, 0.7);
}
.inde4-table tr:nth-child(2) td:first-child:before {
  background: rgba(247, 45, 102, 0.7);
}
.inde4-table tr:nth-child(3) td:first-child:before {
  background: rgba(45, 206, 137, 0.7);
}
.inde4-table tr:nth-child(4) td:first-child:before {
  background: rgba(69, 170, 242, 0.7);
}
.inde4-table tr:nth-child(5) td:first-child:before {
  background: rgba(236, 180, 3, 0.7);
}
.inde4-table tr:nth-child(6) td:first-child:before {
  background: rgba(255, 91, 81, 0.7);
}
.inde4-table tr:nth-child(7) td:first-child:before {
  background: rgba(68, 84, 195, 0.7);
}
.inde4-table tr:nth-child(8) td:first-child:before {
  background: rgba(247, 45, 102, 0.7);
}
.inde4-table tr:nth-child(9) td:first-child:before {
  background: rgba(45, 206, 137, 0.7);
}
.inde4-table tr:nth-child(10) td:first-child:before {
  background: rgba(69, 170, 242, 0.7);
}

.crypto-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 4px;
  line-height: 3rem;
  text-align: center;
  font-size: 1.8rem;
  margin-right: 18px;
}

.crypto-tabs {
  padding: 0 !important;
  border: 0 !important;
}
.crypto-tabs .tabs-menu1 ul li .active {
  background: #fff;
  border-bottom: 0;
  border-radius: 3px 3px 0 0;
  box-shadow: 0px 10px 10px 20px rgba(179, 186, 212, 0.09), 10px 10px 15px -5px rgb(176, 184, 214);
  border: 0px solid #ebecf1;
  border-bottom: 0;
}
.crypto-tabs .tabs-menu1 ul li a {
  display: block;
  font-size: 16px;
}

table.table-bordered.dataTable td:first-child {
  border-left: 0px solid #ebecf1 !important;
}

.js-conveyor-example:before {
  width: 20%;
  position: absolute;
  content: "";
  height: 60px;
  background: linear-gradient(to right, #f0eff1, transparent);
  z-index: 9;
  left: 0;
  top: 8px;
}
.js-conveyor-example:after {
  width: 20%;
  position: absolute;
  content: "";
  height: 60px;
  background: linear-gradient(to left, #f0eff1, transparent);
  z-index: 9;
  right: 0;
  top: 8px;
}

.user-info .social li {
  display: inline-block;
}
.user-info .social .social-icon {
  color: #fff;
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.1);
  line-height: 2.5rem;
  border-radius: 50%;
}

.date-range-btn {
  font-size: 0.9375rem;
}

.side-menu__icon {
  color: #344050;
}

.sidemneu-icon2 {
  fill: #8e98db;
}

.svg-icon {
  color: #5c678f;
  fill: #5c678f;
}

.svg-icon1 {
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  margin-right: 5px;
  width: 37.5px;
  height: 37.5px;
  background: #fff;
  padding: 8px;
  border-radius: 50px;
  box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
  border: 1px solid #ebecf1;
  color: #5c678f;
  fill: #5c678f;
}

.profile-dropdown .header-icon {
  line-height: 20px;
}

.header-option .header-icon {
  margin-top: -3px;
}
.header-option .nav-link {
  margin: 7px 0 0 0;
}

.card-custom-icon.text-success {
  fill: #2dce89;
}

.feature .feature-icon {
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  border-radius: 50%;
  background: rgba(68, 84, 195, 0.2);
  text-align: center;
  font-size: 25px;
}

.expenses-card {
  padding-top: 170px;
}
.expenses-card #Chart {
  width: 105% !important;
  bottom: -15px;
  position: relative;
  left: -7px;
  height: 120px !important;
}
.expenses-card .card-body {
  position: absolute;
  top: 0;
}

.page-rightheader .breadcrumb {
  padding: 0;
  background: transparent;
}

.under-countdown .countdown {
  color: #fff;
  padding: 20px;
  border-radius: 5px;
}

/*----- clipboard-----*/
.clip-widget {
  position: relative;
}

.clipboard-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 0px;
  background: #fff;
  color: #212529;
  border-radius: 5px;
  width: 35px;
  cursor: pointer;
  /* line-height: 37px; */
  height: 35px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.clipboard-icon i {
  margin-top: 5px;
  font-size: 20px;
}

/*----- clipboard-----*/
table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > th:first-child:before {
  top: 12px;
  left: 4px;
  height: 14px;
  width: 14px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid #4757c4;
  border-radius: 14px;
  box-shadow: none;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  line-height: 14px;
  content: "+";
}

.usertab-list li {
  margin: 5px 0;
}

.profile-media-img {
  border: 0px solid #efefef;
  display: block;
  position: relative;
  display: block;
}
.profile-media-img h6 {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #fff;
}
.profile-media-img:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  position: absolute;
}
.profile-media-img h6 a {
  position: relative;
  z-index: 999;
}

#form .list-group-item {
  padding: 25px;
}

.custom-switch {
  padding-left: 0;
  font-size: 12px;
}

.custom-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-switch-indicator {
  display: inline-block;
  height: 1.25rem;
  width: 2.25rem;
  background: #f5f9fc;
  border-radius: 50px;
  position: relative;
  vertical-align: bottom;
  border: 1px solid #ebecf1;
  transition: 0.3s border-color, 0.3s background-color;
}
.custom-switch-indicator:before {
  content: "";
  position: absolute;
  height: calc(1.25rem - 4px);
  width: calc(1.25rem - 4px);
  top: 1px;
  left: 1px;
  background: #fff;
  border-radius: 50%;
  transition: 0.3s left;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}

.custom-switch-input:checked ~ .custom-switch-indicator:before {
  left: calc(1rem + 1px);
}

.custom-switch-input:focus ~ .custom-switch-indicator {
  box-shadow: none;
}

.custom-switch-description {
  margin-left: 0.5rem;
  color: #6e7687;
  transition: 0.3s color;
}

.custom-switch-input:checked ~ .custom-switch-description {
  color: #1e1e2d;
}

.page-rightheader .date-range-btn {
  position: relative;
  padding-left: 40px;
}

.main-profile {
  position: relative;
  background: #FFF;
  padding: 25px;
  padding-bottom: 0;
  margin-bottom: 30px;
  border-top: 1px solid #ebecf1;
  border-radius: 8px;
  overflow: hidden;
}
.main-profile .tab-menu-heading {
  border-left: 0;
  border-right: 0;
  margin: 0 -30px;
  margin-top: 25px;
}

.profile-edit {
  position: relative;
}

#profile-pages a:hover, #profile-pages a:focus {
  color: #007BFF !important;
}

.profile-share {
  width: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 0 0 5px 5px;
}
.profile-share a {
  background: #fff;
  color: #5c678f;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  border-radius: 100%;
  font-size: 18px;
  border-radius: 50px;
  box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
  border: 1px solid #ebecf1;
  vertical-align: middle;
  margin-right: 5px;
  display: inline-block;
}

.main-proifle .profile-icon, .profile-footer .new, .pro-option {
  background: #fff;
  color: #5c678f;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  border-radius: 100%;
  font-size: 18px;
  border-radius: 50px;
  box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
  border: 1px solid #ebecf1;
  vertical-align: middle;
  margin-right: 10px;
  display: inline-block;
}

.pro-details li {
  width: 50%;
  float: left;
  margin-bottom: 0.5rem;
}

.profile-edit textarea {
  background: #fff;
  border-radius: 5px 5px 0 0;
}
.profile-edit textarea:focus {
  background: #ebecf1;
  border: 1px solid #ebecf1;
}

.social-profile-buttons li {
  float: left;
  display: block;
}
.social-profile-buttons li a {
  display: block;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  background: #d2d6f1;
  border: 2px solid #1e1e2d;
  margin: 2px;
}

.fill-white {
  fill: transparent;
}

.page-header .breadcrumb-icon {
  margin-top: 3px;
  margin-left: 4px;
}
.page-header .breadcrumb-item .svg-icon {
  width: 20px;
}
.page-header .breadcrumb-item:first-child {
  margin-top: 0;
}

.only-subscription {
  display: block;
}

.main-nav-calendar-event .nav-link {
  padding: 0;
  height: 30px;
  line-height: 30px;
}
.main-nav-calendar-event .nav-link.exclude {
  position: relative;
  opacity: 0.3;
}
.main-nav-calendar-event .nav-link.exclude:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 1px solid #737688;
}

.form-wizard-title {
  line-height: 25px;
  margin-left: 10px;
}

.footer .social .social-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  border: 1px solid #ebecf1;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
  border: 1px solid #ebecf1;
}

.richText .richText-undo, .richText .richText-redo {
  border-right: #ebecf1 solid 1px !important;
}

.modal-open .select2-dropdown {
  z-index: 10000 !important;
}

.templates-scroller {
  width: 300px;
}

.page-leftheader ol,
.page-rightheader ol {
  padding-left: 0;
  background: none;
  padding: 0;
}
.page-leftheader .breadcrumb-item,
.page-rightheader .breadcrumb-item {
  font-size: 11px;
  font-family: "Poppins", sans-serif;
  margin-top: 0;
}
.page-leftheader .breadcrumb-item + .breadcrumb-item::before,
.page-rightheader .breadcrumb-item + .breadcrumb-item::before {
  color: #1e1e2d;
  padding-left: 0;
  font: var(--fa-font-solid);
  content: "\f101";
  font-weight: 400;
  float: none;
  font-size: 8px;
}
.page-leftheader .breadcrumb-item a,
.page-rightheader .breadcrumb-item a {
  color: #1e1e2d;
}

.sub-pageheader {
  display: flex;
  margin-bottom: 2.3rem;
  font-size: 14px;
}
.sub-pageheader .nav-link:first-child {
  padding-left: 0;
}
.sub-pageheader .nav-link:last-child {
  padding-right: 0;
}
.sub-pageheader .nav-link.border-right {
  border-right: 1px solid #cfd1e1 !important;
}

hr.pageheader-border {
  border-top: 1px solid #dfe0e7;
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}

@media (max-width: 992px) {
  .sub-pageheader .nav-link {
    display: none;
  }
  .sub-pageheader .left-nav .nav-link:first-child, .sub-pageheader .left-nav .nav-link:nth-child(2) {
    display: block;
  }
  .sub-pageheader .right-nav .nav-link.d-md-none {
    display: block;
  }
  .page-header {
    display: block;
  }
  .page-header .page-leftheader ol {
    padding-top: 0;
  }
  .page-rightheader h6 {
    font-size: 10px;
  }
}
.dash1-card {
  position: absolute;
}
.dash1-card p {
  font-size: 14px;
}
.dash1-card .ratio {
  position: absolute;
  right: -10px;
  top: 20px;
  padding: 5px 15px;
  background-color: #222222;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  color: #fff;
  border-radius: 15px 0px 0px 15px;
}
.dash1-card .ratio-text {
  position: absolute;
  top: 55px;
  right: 5px;
  font-size: 13px;
}

.sales-bar {
  min-height: 280px !important;
  position: relative;
  bottom: -21px;
  left: -10px;
}

.header-dropdown-list .ps__thumb-y {
  opacity: 0;
}

.page-header.main-pageheader {
  margin-bottom: 0;
}

.page-rightheader .breadcrumb-item a {
  color: #1e1e2d;
}

.card-over {
  position: relative;
  top: -20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #fff;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid #dbe2eb;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin-bottom: 1.5rem !important;
}

.bread-select {
  display: flex;
}
.bread-select .select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 10px !important;
}

.emp-circle-chart {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 100px;
  font-weight: 500;
  font-size: 20px;
}

.dash1-iocns {
  padding: 6px 14px;
  border-radius: 50px;
  border: 2px dotted;
}

.country-card {
  overflow: auto;
}

#talent-chart {
  -webkit-filter: drop-shadow(0 6px 4px rgba(179, 186, 212, 0.5));
  filter: drop-shadow(0 6px 4px rgba(179, 186, 212, 0.2));
}

.custom-btns .btn {
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  background: 0 0;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.9);
  margin: 0 5px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
  line-height: 2rem;
}

.widget-user-image {
  height: 100px;
  width: 100px;
  clear: both;
  margin: 0 auto;
  display: block;
  border-radius: 100%;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  position: relative;
}

.widget-user-image-sm {
  height: 44px;
  width: 44px;
  clear: both;
  display: block;
  border-radius: 0.42rem;
  background: #E1F0FF;
  position: relative;
}

.widget-user-name {
  line-height: 1.8;
}

.vendor-image-sm {
  clear: both;
  display: block;
  position: relative;
}
.vendor-image-sm img {
  width: 20px;
}

.header .nav-link.icon i {
  color: #5c678f;
}

.mail-inbox .list-group-item {
  border: 0 !important;
}

.user-details .user-icon {
  background: #fff;
  color: #5c678f;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  border-radius: 100%;
  font-size: 18px;
  border-radius: 50px;
  box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
  border: 1px solid #ebecf1;
  vertical-align: middle;
  margin-right: 10px;
  display: inline-block;
}
.user-details span.h6 {
  vertical-align: middle;
}

.item7-card-desc .fe {
  color: #5c678f;
}

.nav-link.icon .header-icon {
  font-size: 17px;
  text-align: center;
  vertical-align: middle;
  color: #1e1e2d;
  padding: 11px 10px 10px;
}

.profile-dropdown img {
  width: 40px;
  height: 40px;
  border: 1px solid #ebecf1;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  margin-top: -3px;
  object-fit: cover;
}
.profile-dropdown .nav-link {
  padding: 5px 0 0;
}

.hor-header {
  border-bottom: 1px solid #dbe2eb;
}
.hor-header .search-element {
  margin-left: 1rem;
}

@media (min-width: 992px) {
  .horizontalMenucontainer .header.top-header {
    box-shadow: none;
  }
}
@media (min-width: 991px) {
  .top-header1 .header-brand2 .header-brand-img {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 18px;
  }
  .top-header1 .header-brand {
    display: none;
  }
  .top-header1 .form-inline .form-control {
    width: 250px;
  }
}
@media (max-width: 575px) and (min-width: 569px) {
  .animated-arrow.hor-toggle {
    padding: 16px 35px 16px 0px !important;
  }
}
/*---- IE11 Support ----*/
*::-ms-backdrop, .img-height {
  height: 100%;
}

*::-ms-backdrop, .img-height1 {
  height: 333px;
}

*::-ms-backdrop, .social-profile-buttons li {
  float: none;
  display: inline-block;
}

*::-ms-backdrop, .social-profile-buttons ul {
  margin-bottom: 0;
}

*::-ms-backdrop, .btn-icon.btn-svg .svg-icon {
  height: 16px;
}

*::-ms-backdrop, .price-svg .price-title {
  top: 0 !important;
}

*::-ms-backdrop, .price-svg svg {
  height: 65px !important;
}

*::-ms-backdrop, .item7-card-img {
  height: 100%;
}

*::-ms-backdrop, .main-content {
  height: 100%;
}

*::-ms-backdrop, .app-sidebar-help {
  display: none;
}

*::-ms-backdrop, .list-card .media-body {
  display: block !important;
}

*::-ms-backdrop, .media-body.time-title1 {
  display: block !important;
}

*::-ms-backdrop, .media .media-body {
  display: table-cell;
}

*::-ms-backdrop, .chat-widget-header .option-dots new-list2 .fe {
  display: block;
}

*::-ms-backdrop, .message-feed.right .media-body {
  display: table-cell;
}

*::-ms-backdrop, .message-feed.media .media-body {
  display: block;
}

*::-ms-backdrop, .main-chat-body .media .media-body {
  display: -ms-flexbox;
}

*::-ms-backdrop, .main-chat-list .media-body {
  display: block;
}

*::-ms-backdrop, .chat-widget-header .option-dots.new-list2 .fe {
  display: block;
}

*::-ms-backdrop, .card-img-top {
  height: 250px;
}

*::-ms-backdrop, .image10 {
  height: 250px;
}

*::-ms-backdrop, .card-image1 {
  height: 250px;
}

*::-ms-backdrop, .sweet-alert .sa-info.sa-icon::after {
  margin-left: 37px !important;
  top: 17px !important;
}

*::-ms-backdrop, .main-contact-info-header .media-body {
  display: block;
}

*::-ms-backdrop, .main-contact-info-body .media-body {
  display: -ms-flexbox;
}

*::-ms-backdrop, #media3 .media-body, #media1 .media-body {
  display: block;
}

*::-ms-backdrop, .page-style1 {
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

*::-ms-backdrop, .page-single, .page-single-content {
  display: block !important;
}

*::-ms-backdrop, .page-style1 .page-single-content {
  display: block !important;
}

*::-ms-backdrop, .page, .page-content {
  display: flex !important;
}

*::-ms-backdrop, .page-style1 .page-single {
  display: block !important;
}

*::-ms-backdrop, .page-content img {
  min-width: 50%;
}

*::-ms-backdrop, .chat-conatct-list .option-dots {
  padding: 2px 12px !important;
}

.sidenav-toggled .header.py-4 .header-brand .header-brand-img.desktop-lgo {
  display: flex !important;
  margin: 0;
}

*::-ms-backdrop, .chat-conatct-list .media {
  overflow: visible;
}

.data-percentage-change {
  vertical-align: middle;
}

.remove-voice {
  display: none;
}

.voice-neural-sign {
  font-size: 10px;
  color: #D13212;
  font-style: normal;
}

.no-italics {
  font-style: normal;
}

/* ==========================================================================           
 *
 *    DATA TABLE STYLES
 *
 * ========================================================================== */
.data-table {
  margin-bottom: 1rem !important;
}

.dataTable tbody tr.shown + tr td:first-of-type {
  padding: 0 3rem 0 2rem;
  border-top: none;
}

.dataTable thead tr {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  text-transform: none;
  font-weight: 800;
  color: #728096;
}

.dataTable tbody td {
  font-size: 12px;
  vertical-align: middle;
  padding-right: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-style: dashed !important;
  border-color: rgb(243, 243, 243) !important;
}
.dataTable tbody td tbody td button {
  background-color: #1E1E2D;
  color: #FFF;
  border-radius: 0;
  font-size: 11px;
}
.dataTable tbody td tbody td button {
  background-color: #1E1E2D;
}
.dataTable tbody td tbody td button:hover {
  background-color: #7B83EB;
}
.dataTable tbody td tbody td .processing {
  padding: 0.4rem 0.7rem;
  background-color: #F6AD0F;
}
.dataTable tbody td tbody td .in_progress {
  padding: 0.4rem 0.7rem;
  background-color: #F6AD0F;
}
.dataTable tbody td tbody td .completed {
  padding: 0.4rem 0.7rem;
  color: #FFF;
  background-color: #008c00;
}
.dataTable tbody td tbody td .failed {
  padding: 0.4rem 0.7rem;
  color: #FFF;
  background-color: #D13212;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: inherit !important;
}

tbody > tr:last-child > td {
  border-bottom: 0;
}

.cell-box {
  border-radius: 35px;
  padding: 3px 20px;
  color: #fff;
  font-weight: 700;
}

.user-pending,
.user-suspended,
.support-closed,
.support-pending,
.payout-processing,
.priority-low,
.user-group-user,
.plan-closed,
.payment-pending,
.subscription-pending,
.notification-info,
.type-file,
.plan-free {
  background: #D1D3E0;
  color: #1e1e2d;
}

.notification-new-user,
.type-both,
.notification-marketing,
.user-group-admin,
.payment-monthly,
.glacier,
.plan-regular,
.support-resolved {
  background: #E1F0FF;
  color: #007BFF;
}

.glacier-ir {
  background: rgba(0, 188, 126, 0.1);
  color: #00bc7e;
}

.user-active,
.plan-paid,
.priority-normal,
.support-open,
.notification-announcement,
.notification-new-payment,
.plan-active,
.payment-completed,
.subscription-active,
.promocode-valid,
.payout-completed,
.blog-published,
.status-active,
.faq-visible,
.adsense-activated,
.payment-prepaid,
.user-group-subscriber {
  background: rgba(0, 188, 126, 0.1);
  color: #00bc7e;
}

.support-replied,
.priority-high,
.payment-yearly,
.support-replied,
.user-group-guest,
.plan-professional,
.deep-archive {
  background: #FFF4DF;
  color: #FF9D00;
}

.voice-mixed {
  background: #FF9D00;
}

.user-deactivated,
.priority-critical,
.payment-failed,
.subscription-cancelled,
.subscription-suspended,
.subscription-expired,
.payout-cancelled,
.payout-declined,
.promocode-invalid,
.payment-cancelled,
.payment-declined,
.blog-hidden,
.notification-payout-request,
.status-deactive,
.faq-hidden,
.adsense-deactivated,
.notification-warning {
  background: #FFE2E5;
  color: #ff0000;
}

.table-actions {
  font-size: 14px;
  color: #555;
}

.table-actions-dropdown {
  font-size: 12px;
  min-width: auto;
  padding: 0;
  color: #1e1e2d;
}
.table-actions-dropdown i {
  font-size: 14px;
  margin-right: 10px;
}
.table-actions-dropdown .dropdown-item {
  padding: 0.5rem 1rem;
}
.table-actions-dropdown .dropdown-item:hover, .table-actions-dropdown .dropdown-item:focus {
  background: #f5f9fc;
}

.table#subscriptionsAdminTable th, .table#subscriptionsAdminTable td {
  border-top: none !important;
}

.dataTables_wrapper {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}
.dataTables_wrapper .dataTables_filter {
  text-align: right !important;
}
.dataTables_wrapper .dataTables_filter .form-control-sm {
  padding: 0 1rem !important;
}
.dataTables_wrapper .dataTables_filter label {
  font-weight: 800;
}
.dataTables_wrapper .dataTables_length label {
  font-weight: 800;
  margin-bottom: 0 !important;
  padding-top: 0.3rem !important;
}
.dataTables_wrapper .dataTables_length label select {
  margin-bottom: 0 !important;
}
.dataTables_wrapper .dataTables_length select {
  font-size: 10px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #ebecf1;
  margin-bottom: 0.5rem;
}
.dataTables_wrapper .dataTables_filter input {
  border-radius: 0;
  font-size: 12px;
  border: none;
  padding-left: 15px;
  border-bottom: 1px solid #ebecf1;
}
.dataTables_wrapper .dataTables_filter input:hover, .dataTables_wrapper .dataTables_filter input:focus {
  border-color: #007BFF;
  box-shadow: none;
  transition: all 0.2s;
}
.dataTables_wrapper .dataTables_filter .search-icon {
  position: absolute;
  top: 8px;
  margin-left: 5px;
  color: #728096;
}
.dataTables_wrapper .dataTables_length .custom-select:focus {
  border-color: #007BFF;
  box-shadow: none;
  transition: all 0.2s;
}
.dataTables_wrapper .dataTables_paginate .pagination .page-item.disabled a {
  color: #728096 !important;
}
.dataTables_wrapper .dataTables_paginate .pagination .page-item.active {
  color: #fff;
}
.dataTables_wrapper .dataTables_paginate .pagination .page-item .page-link {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, 微软雅黑, Arial, sans-serif;
  font-size: 10px;
  margin-bottom: 0.5rem;
  border-radius: 0;
  color: #555;
  border-color: #ebecf1;
}
.dataTables_wrapper .dataTables_paginate .pagination .page-item .page-link:hover {
  background: #f5f9fc;
}
.dataTables_wrapper .dataTables_paginate .pagination .page-item.active .page-link {
  background-color: #007BFF;
  border-color: #007BFF;
  color: #fff;
}
.dataTables_wrapper .dataTables_paginate .pagination .first .page-link {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.dataTables_wrapper .dataTables_paginate .pagination .last .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dataTables_info {
  padding-left: 12px;
  font-size: 10px;
  color: #728096;
}

.details-table {
  width: 100%;
  margin-bottom: 0;
}
.details-table td {
  padding: 12px 0 12px 12px !important;
  border-top: 1px solid #ebecf1 !important;
}
.details-table audio {
  width: 100%;
}
.details-table .details-title, .details-table .details-result {
  font-weight: 700;
}

.dataTable div.slider {
  display: none;
}

table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected {
  background-color: #f5f9fc;
  color: #1e1e2d;
}

table.dataTable thead th.dt-checkboxes-select-all, table.dataTable tbody td.dt-checkboxes-cell {
  line-height: 1;
  padding-left: 0;
}

table.dataTable thead th.dt-checkboxes-select-all {
  padding-left: 0.8rem;
}

@media (max-width: 575px) {
  .dataTable tbody tr.parent {
    display: contents;
  }
}
@media (max-width: 820px) {
  .dataTable tbody tr.parent {
    display: contents;
  }
}
@media (max-width: 1920px) {
  .dataTable tbody tr.parent {
    display: contents;
  }
}
#no-results-img {
  width: 200px;
  opacity: 0.7;
  margin-bottom: 0.2rem;
}

.result-play {
  background: none;
  padding: 3px 6px 1px 8px;
  border: 1px solid white;
  border-radius: 50%;
  margin-left: -5px;
  line-height: 1.4;
  color: #1e1e2d;
}
.result-play:hover {
  color: #007BFF;
}

.table-info-button {
  margin-top: 2px;
}
.table-info-button i {
  padding-right: 0 !important;
}

.green {
  color: #00bc7e;
}

.red {
  color: #dc0441;
}

.yellow {
  color: #FF9D00;
}

.vertical-middle {
  vertical-align: top;
}

.table-action-buttons {
  background: #f5f9fc;
  border: 1px solid white;
  border-radius: 0.42rem;
  line-height: 2.2;
  font-size: 14px;
  color: #67748E;
  width: 33px;
  height: 33px;
  text-align: center;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.view-action-button:hover, .view-action-button:focus {
  background: #e1f0ff;
  color: #007BFF;
}

.edit-action-button:hover, .edit-action-button:focus {
  background: #FFF4DE;
  color: #FFA800;
}

.request-action-button:hover, .request-action-button:focus {
  background: rgba(0, 188, 126, 0.1);
  color: #00bc7e;
}

.delete-action-button:hover, .delete-action-button:focus {
  background: #FFE2E5;
  color: #F64E60;
}

.transcribe-action-buttons {
  background: none;
  border: 1px solid white;
  border-radius: 50%;
  line-height: 2;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(150, 150, 150, 0.12), 0 1px 1px 0 rgba(150, 150, 150, 0.14);
  color: #1e1e2d;
  margin-right: 0.2rem;
  width: 26px;
  height: 25px;
  text-align: center;
  padding-right: 1px;
}

.refresh-button {
  position: absolute;
  right: 1.5rem;
  z-index: 100;
}
.refresh-button i {
  font-size: 12px;
  border-radius: 0.42rem;
  padding-right: 0;
  padding-top: 2px;
}

.template-buttons {
  z-index: 100;
}
.template-buttons i {
  font-size: 12px;
  border-radius: 0.42rem;
  padding-right: 0;
  padding-top: 2px;
}

.special-refresh {
  position: initial !important;
  float: right;
}

.balance-highlight {
  color: #007BFF;
  font-weight: 600;
}

#dashboard-users {
  font-family: "Poppins", sans-serif;
}
#dashboard-users tr th {
  vertical-align: middle !important;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-color: rgb(243, 243, 243) !important;
  border-style: dashed;
}
#dashboard-users tr td {
  vertical-align: middle !important;
  padding-top: 1.55rem;
  padding-bottom: 1.55rem;
  border-color: rgb(243, 243, 243) !important;
  border-style: dashed;
}
#dashboard-users tr:hover td {
  background-color: #f5f9fc !important;
}

/* ==========================================================================           
 *
 *    INPUT FORM BOX STYLES
 *
 * ========================================================================== */
.input-box {
  margin-bottom: 1.5rem;
}
.input-box h6 {
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.6rem;
  font-weight: 600;
  font-size: 11px;
}
.input-box .form-control {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #1e1e2d;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  appearance: none;
  background-color: #f5f9fc;
  border-color: transparent;
  border-radius: 0.5rem;
  border-width: 1px;
  font-weight: 400;
  line-height: 1rem;
  padding: 0.75rem 1rem;
  width: 100%;
}
.input-box input:hover, .input-box input:focus {
  border-color: #007BFF;
  box-shadow: none;
  transition: all 0.2s;
}
.input-box textarea {
  font-weight: 400 !important;
}
.input-box textarea:hover, .input-box textarea:focus {
  border-color: #007BFF;
  box-shadow: none;
  transition: all 0.2s;
}

/* ==========================================================================           
 *
 *    AWSELECT DROPDOWN SELECT BOX
 *
 * ========================================================================== */
.awselect {
  text-align: left;
  margin-bottom: 1.5rem;
  font-size: 12px !important;
  font-weight: 600;
}
.awselect .bg {
  border-radius: 10px;
}

#tts-project .awselect {
  margin-bottom: 1rem;
}

.awselect .front_face {
  padding: 0.75rem 1rem !important;
}

.awselect .front_face > .bg {
  background: #f5f9fc !important;
  color: #1e1e2d;
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
}

.awselect .front_face .current_value {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  font-weight: 400 !important;
}

.awselect .back_face {
  padding: 0px !important;
  float: left;
}

.awselect.animate2 > .bg {
  box-shadow: 0px 4px 7px -2px rgba(0, 0, 0, 0.5);
}

.awselect .back_face ul li a {
  padding: 11px 20px !important;
  float: left;
  border-bottom: 1px solid #ebecf1;
  font-family: "Poppins", sans-serif;
}

.awselect .back_face ul li a:hover {
  background: #f5f9fc;
  color: #007BFF;
}

.awselect .awselect-img {
  vertical-align: middle;
  border-style: none;
  width: 20px;
  padding-bottom: 1px;
  margin-right: 1rem;
}

.awselect.placeholder_animate2 .front_face .placeholder {
  color: #728096;
}

.awselect_bg {
  position: relative;
}
.awselect_bg .animate {
  opacity: 0 !important;
}

#checkbox-types {
  display: inline-flex;
}
#checkbox-types .radio-control {
  display: inline-flex;
}
#checkbox-types .radio-control {
  display: inline-flex;
}
#checkbox-types .radio-control:hover > .label-control {
  color: #007BFF;
  cursor: pointer;
}
#checkbox-types .input-control {
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #f5f9fc;
  color: #007BFF;
  top: 5px;
  height: 15px;
  width: 15px;
  border: 0;
  cursor: pointer;
  outline: none;
  transition: all 0.2s;
  border-radius: 3px;
}
#checkbox-types .input-control:checked::before {
  position: absolute;
  font: 9px/1 "Open Sans", sans-serif;
  left: 5px;
  top: 2px;
  content: "⅃";
  transform: rotate(40deg);
}
#checkbox-types .input-control:checked {
  background-color: #007BFF;
  color: #FFF;
  transition: all 0.2s;
}
#checkbox-types .input-control:checked + .label-control {
  color: #007BFF;
}
#checkbox-types .label-control {
  margin-left: 5px;
  margin-bottom: 0;
  margin-right: 1rem;
  padding-top: 3px;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.8;
}
#checkbox-types .label-control:hover {
  color: #007BFF;
}

#checkbox-types .label-control {
  padding-top: 2px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

/* ==========================================================================           
 *
 *    SWAL Modal Box
 *
 * ========================================================================== */
.swal2-title {
  font-size: 20px !important;
  font-weight: 800 !important;
  color: #1e1e2d !important;
  padding-top: 20px !important;
}

.swal2-html-container {
  font-size: 14px !important;
  margin-top: 1rem !important;
}

.swal2-actions {
  margin: 1em auto 0 !important;
}

.swal2-file {
  font-size: 14px !important;
  border: 1px solid #007BFF !important;
  margin-top: 1.5rem !important;
  margin-bottom: 0.5rem !important;
}

.swal2-input {
  font-family: "Poppins", sans-serif !important;
  border-radius: 0px !important;
  padding: 1.15rem 1.3rem !important;
  font-size: 10px !important;
  color: #1e1e2d !important;
  font-weight: 600 !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  outline: none !important;
  margin-top: 1rem !important;
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}
.swal2-input:hover, .swal2-input:focus {
  border-color: #007BFF !important;
  box-shadow: 0px 0px 3px 1px rgba(0, 123, 255, 0.4) !important;
  transition: all 0.2s !important;
}

.swal2-input-label {
  font-size: 12px;
  margin-top: 1.2rem;
}

.swal2-validation-message {
  font-size: 14px !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
  font-size: 10px !important;
  min-width: 80px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border-radius: 35px !important;
  text-transform: uppercase !important;
  color: #fff !important;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14) !important;
}
.swal2-styled.swal2-confirm:hover, .swal2-styled.swal2-confirm:focus,
.swal2-styled.swal2-cancel:hover,
.swal2-styled.swal2-cancel:focus {
  color: #fff !important;
  background-color: #1e1e2d !important;
  border-color: #1e1e2d !important;
}

.swal2-styled.swal2-confirm {
  background-color: #007BFF !important;
  border-color: #007BFF !important;
}

.swal2-styled.swal2-cancel {
  background-color: #D13212 !important;
  border-color: #D13212 !important;
}

.swal2-select {
  font-size: 13px !important;
  color: #1e1e2d !important;
  margin: 1em 4em 3px !important;
}

/* ==========================================================================           
 *
 *    PROFILE/EDIT - USERS
 *
 * ========================================================================== */
.card-body table tbody tr {
  font-size: 12px;
}

.intl-tel-input .flag-dropdown .selected-flag {
  margin-left: 2px;
  border: none;
  background: none;
}

#phone-number {
  padding-left: 55px;
}

.country-users h6 {
  font-size: 12px;
  font-weight: 800;
  margin-bottom: 1rem;
}
.country-users ul {
  column-count: 3;
  font-size: 12px;
}
.country-users ul li {
  margin-bottom: 0.5rem;
}

.special-btn {
  padding: 0.68rem 15px !important;
}

.usage-icon i {
  font-size: 30px;
  padding-top: 1rem;
  color: #007BFF;
}

.progress {
  background-color: #ECF4F7;
  overflow: visible;
  border-radius: 10px !important;
}
.progress .zip-bar {
  box-shadow: 0 3px 7px rgba(0, 123, 255, 0.5) !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.progress .zip-bar2 {
  box-shadow: 0 3px 7px rgba(0, 123, 255, 0.5) !important;
}
.progress .documents-bar {
  box-shadow: 0 3px 10px rgba(255, 157, 0, 0.5) !important;
}
.progress .subscription-bar {
  box-shadow: 0 3px 7px rgba(255, 157, 0, 0.5) !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.progress .media-bar {
  box-shadow: 0 3px 7px rgba(239, 75, 75, 0.5) !important;
}
.progress .images-bar {
  box-shadow: 0 3px 7px rgba(10, 187, 135, 0.5) !important;
}

.label {
  align-items: center;
  display: flex;
}
.label .label-dot {
  border-radius: 8px;
  display: block;
  flex: none;
  height: 8px;
  margin-right: 10px;
  width: 8px;
}
.label .label-title {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 700;
}
.label .zip {
  background: #007BFF;
}
.label .documents {
  background: #FF9D00;
}
.label .media {
  background: #ef4b4b;
}
.label .images {
  background: #0abb87;
}
.label .other {
  background: #f5f9fc;
}

.overflow-x-auto {
  overflow-x: auto;
}

#dashboard-background {
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgb(2, 0, 36) 0%, rgb(15, 24, 43) 0%, rgb(51, 65, 85) 100%);
}

.yellow {
  color: #FF9D00;
}

.file-placeholder-container {
  position: relative;
}

.file-placeholder {
  color: rgb(236, 244, 247);
  margin-right: 1rem;
}

.file-placeholder-text {
  position: absolute;
  font-size: 7px;
  font-weight: 600;
  text-align: center;
  text-overflow: ellipsis;
  color: #007BFF;
  top: 36%;
  left: 8px;
}

#return-sound {
  position: absolute;
  right: 0;
  margin-right: 1.2rem;
  top: 17px;
}

/* ==========================================================================           
 *
 *    SUPPORT/CREATE - USERS
 *
 * ========================================================================== */
.ql-toolbar.ql-snow {
  border: 1px solid #ced4da;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, 微软雅黑, Arial, sans-serif;
  background: #fff;
}

.ql-container.ql-snow {
  border: 1px solid #ced4da;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, 微软雅黑, Arial, sans-serif;
}

#support-messages-box {
  min-height: 600px;
  border-radius: 8px;
  height: 100%;
}
#support-messages-box .support-message {
  padding: 1rem;
  float: left;
  width: 90%;
  border-radius: 8px;
  box-shadow: 0 1px 8px rgba(179, 186, 212, 0.7) !important;
}
#support-messages-box .support-message p span {
  float: right;
  color: #FF9D00;
}
#support-messages-box .support-response {
  float: right;
  background-color: #E1F0FF !important;
}

.support-header {
  position: absolute;
  right: 0;
  top: 2rem;
  margin-right: 1.2rem;
}

/* ==========================================================================           
 *
 *    RESULT/SHOW - USERS/ADMINS
 *
 * ========================================================================== */
#user-text {
  border: 1px solid #ced4da;
  padding: 10px 20px;
  font-size: 14px;
}

.actions-total button.btn {
  padding: 0.3rem 0.4rem;
  background-color: #FFF;
  width: 35px;
  border: none;
  outline: none;
  text-decoration: none;
  border-radius: 0;
  color: #1e1e2d;
  margin-right: 0.1rem;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.actions-total button:hover {
  background-color: #007BFF;
  color: #FFF;
  transition: all 0.2s ease;
}

.actions-total a.btn {
  padding: 0.3rem 0.4rem;
  background-color: #FFF;
  width: 35px;
  border: none;
  outline: none;
  text-decoration: none;
  border-radius: 0;
  color: #1e1e2d;
  margin-right: 0.1rem;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.actions-total a:hover {
  background-color: #007BFF;
  color: #FFF;
  transition: all 0.2s ease;
}

/* ==========================================================================           
 *
 *    FLASH ALERT MESSAGES
 *
 * ========================================================================== */
.alert {
  z-index: 100;
  position: absolute;
  right: 2rem;
  margin-top: 2rem;
  font-size: 12px;
  line-height: 2.2;
  color: #fff;
  font-weight: 600;
  border: none !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.alert i {
  font-size: 16px;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 3px;
}

.alert-login {
  right: auto;
  width: 66.7% !important;
  top: 10px !important;
}

.alert-success {
  background: #00c851 !important;
}

.alert-info {
  background: #33b5e5 !important;
}

.alert-warning {
  background: #fb3 !important;
}

.alert-danger {
  background: #ff3547 !important;
}

.close {
  line-height: 1.3;
  opacity: 0.8;
  color: #fff;
  padding: 0 0.5rem 0 0;
}

#verify-email .alert {
  font-size: 10px;
  line-height: 3;
}

/* ==========================================================================           
 *
 *    MODAL STYLES
 *
 * ========================================================================== */
.modal-header {
  padding: 1rem 2rem;
  border-bottom: none;
}
.modal-header .close {
  color: #1e1e2d;
}

.modal-title {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
}
.modal-title i {
  font-weight: 600;
  margin-right: 0.5rem;
  color: #007BFF;
}
.modal-title button {
  outline: none;
  color: #1e1e2d;
}

.modal-body {
  padding-top: 5px;
  padding-bottom: 1.5rem;
}
.modal-body p {
  font-size: 12px;
  margin-bottom: 0;
}

.modal-footer {
  padding: 0 10px 0 0;
  border-top: none;
}

.modal-footer-awselect {
  display: inline-flex !important;
  float: right !important;
}

/* ==========================================================================           
 *
 *    TTS STYLES
 *
 * ========================================================================== */
.special-buttons .btn-special {
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  font-size: 12px;
  border-radius: 0;
  margin-right: 1rem;
  font-weight: 600;
  width: 100%;
  height: 38px;
}
.special-buttons .btn-special:hover, .special-buttons .btn-special:focus {
  background-color: #007BFF;
  color: #fff;
}
.special-buttons .dropdown-toggle::after {
  vertical-align: middle;
}
.special-buttons .dropdown-menu {
  border-radius: 0;
  font-size: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: none;
  margin-top: 3px;
  width: 100%;
}
.special-buttons .dropdown-item {
  padding: 0.5rem 1.5rem;
}
.special-buttons .dropdown-item:hover, .special-buttons .dropdown-item:focus {
  background-color: #f5f9fc;
}

/* ==========================================================================           
 *
 *    DATABASE BACKUP TABLE
 *
 * ========================================================================== */
#database-backup {
  font-family: "Poppins", sans-serif;
}
#database-backup tr th, #database-backup tr td {
  vertical-align: middle !important;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-color: rgb(243, 243, 243) !important;
  border-style: dashed;
}
#database-backup tr:hover td {
  background-color: #f5f9fc !important;
}

input {
  outline: none;
}

input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

/* ==========================================================================           
 *
 *    SEARCH FIELD IN NAVBAR
 *
 * ========================================================================== */
#search-field input[type=search] {
  background: #fff url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
  border: solid 1px #fff;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 10em;
  transition: all 0.5s;
  width: 15px;
  padding-left: 10px;
  color: #1e1e2d;
  cursor: pointer;
  margin-top: 6px;
  font-weight: 600;
}

#search-field input[type=search]:focus {
  border-color: #007BFF;
  box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  width: 300px;
  padding-left: 32px;
  color: #000;
  background-color: transparent;
  cursor: auto;
}

#search-field input[type=search]:hover {
  background-color: transparent;
}

#search-field input:-moz-placeholder {
  color: #fff;
}

#search-field input::-webkit-input-placeholder {
  color: #fff;
}

/* ==========================================================================           
 *
 *    CHECKOUT
 *
 * ========================================================================== */
.checkout-wrapper-box {
  border: 1px solid #ebecf1;
  padding: 1.5rem 2rem;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 1rem;
}
.checkout-wrapper-box label.gateway {
  margin-bottom: 1rem;
  width: 115px;
  height: 40px;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}
.checkout-wrapper-box label.gateway:hover, .checkout-wrapper-box label.gateway:focus {
  border: 1px solid #007BFF;
}
.checkout-wrapper-box label.gateway .active, .checkout-wrapper-box label.gateway .focus {
  border: 1px solid #007BFF !important;
}
.checkout-wrapper-box label.btn:focus, .checkout-wrapper-box label.btn.focus, .checkout-wrapper-box label.btn.active {
  border: 1px solid #007BFF !important;
}
.checkout-wrapper-box .btn-group {
  margin-bottom: 1.4rem;
}
.checkout-wrapper-box label[aria-expanded=true] {
  border: 1px solid #007BFF !important;
}
.checkout-wrapper-box #BankTransferCollapse {
  border: 1px solid #ebecf1;
  padding: 1rem;
  margin-bottom: 1.5rem;
}
.checkout-wrapper-box #BankTransferCollapse p {
  line-height: 1.2rem;
  text-align: justify;
}
.checkout-wrapper-box #BankTransferCollapse pre {
  text-align: left;
  border-radius: 0;
  padding: 1rem;
  background-color: #f5f9fc;
  border-left: 3px solid #007BFF;
}

.checkout-title {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
}

.checkout-cost {
  float: right;
  font-weight: 800;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}

.checkout-success {
  font-size: 20px;
  font-weight: 700;
  color: #007BFF;
}

.gateway-radio {
  display: none;
  bottom: 0;
  right: 0;
}

.gateway-logo {
  width: 5%;
  margin-right: 0.5rem;
}

.gateway-small-logo {
  width: 3% !important;
}

.transaction-gateway-logo {
  width: 50%;
}

.payment-image {
  width: 70%;
  padding-top: 9px;
}

.payment-image-braintree {
  width: 70%;
  padding-top: 9px;
}

.payment-image-mollie {
  width: 40%;
  padding-top: 6px;
}

.payment-image-coinbase {
  width: 60%;
  padding-top: 8px;
}

.payment-image-stripe {
  padding-top: 6px;
}

.p-family {
  font-family: "Poppins", sans-serif;
}

#promocode-apply {
  padding: 0.67rem 15px;
}

.tabs-menu ul {
  padding: 5px 7px;
  background: #f5f9fc;
  border-radius: 1.5rem;
}

.tabs-menu ul li a {
  width: auto;
  text-transform: none;
  border-radius: 1rem;
}

/* ==========================================================================           
 *
 *    INSTALLATION STYLES
 *
 * ========================================================================== */
#install-wrapper i {
  padding: 1.5rem 2.5rem;
  background: #f5f9fc;
  border-radius: 10px;
  margin-bottom: 2rem;
  color: #007BFF;
  font-size: 68px;
}
#install-wrapper .svg-success {
  color: #28a745 !important;
  width: 1rem;
  height: 1rem;
  fill: currentColor;
}
#install-wrapper .svg-error {
  color: #D13212 !important;
  width: 1rem;
  height: 1rem;
  fill: currentColor;
}

.install-path span {
  padding: 0.3rem 0.4rem;
  background-color: #FFF;
  width: 35px;
  border: none;
  outline: none;
  text-decoration: none;
  border-radius: 5px;
  color: #1e1e2d;
  margin-right: 0.1rem;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.install-path span.active {
  background-color: #007BFF !important;
  color: #FFF;
}

.install-notification .alert,
#manual-activation .alert {
  position: relative;
  margin-top: 0;
  margin-bottom: 2rem;
  line-height: normal;
  width: 100% !important;
}

#install-footer {
  position: absolute;
  bottom: 0;
  left: 42%;
}

#upgrade {
  min-width: 154px;
}

#installed-info {
  display: none;
}

/* ==========================================================================           
 *
 *    REFERRALS 
 *
 * ========================================================================== */
.referral-icon {
  vertical-align: middle;
  color: #ffab00;
  text-shadow: 0 1px 2px rgba(50, 50, 50, 0.2);
}

.referral-icon-user i {
  font-size: 20px;
  color: #007BFF;
  line-height: 59px;
  text-align: center;
  vertical-align: middle;
  width: 60px;
  border-radius: 50%;
  background-color: #f5f9fc;
  margin-bottom: 0.5rem;
}

.referral-social-icons .actions-total a.btn {
  padding: 0.45rem 0.4rem;
  width: 37px;
  margin-right: 0.3rem;
}

.form-control:disabled, .form-control[readonly] {
  color: #007BFF;
  font-weight: 400;
}

.input-group-btn {
  position: absolute;
  right: 0;
  top: 1px;
}

.file-data .input-group-btn .btn-primary {
  padding: 0.6rem 5px !important;
  min-width: 50px !important;
}

#invite-friends-button {
  padding: 0.75rem 15px !important;
  border-radius: 0 !important;
}

#left-direction {
  position: absolute;
  top: 27px;
  left: 65%;
}

#right-direction {
  position: absolute;
  top: 15px;
  left: 65%;
}

.referral-box {
  padding: 2rem;
  margin-right: 1rem;
  border: 1px solid #FFF;
}
.referral-box:hover, .referral-box:focus {
  border: 1px solid #ffab00;
  color: #007BFF;
}
.referral-box:hover i, .referral-box:focus i {
  color: #ffab00;
}
.referral-box i {
  font-size: 34px;
  line-height: 1rem;
}
.referral-box a {
  font-family: "Poppins", sans-serif;
}

.poppins {
  font-family: "Poppins", sans-serif !important;
}

.referral-guideline {
  line-height: 2;
  border-radius: 0;
  font-family: "Poppins", sans-serif;
}

#option-bank {
  line-height: 1;
}
#option-bank label {
  line-height: 1.5;
}

/* ==========================================================================           
 *
 *    FILE UPLOADS CONTAINER (FILEPOND PLUGIN)
 *
 * ========================================================================== */
#upload-container {
  display: none;
  overflow: hidden;
}

#upload-results {
  display: none;
  overflow: hidden;
}

.filepond--root {
  color: #1e1e2d;
  border-radius: 0.4rem;
  margin-bottom: 0;
}

.filepond--drop-label.filepond--drop-label label {
  font-weight: 800;
}
.filepond--drop-label.filepond--drop-label label .restrictions {
  font-size: 10px;
  font-weight: 600;
}
.filepond--drop-label.filepond--drop-label label .restrictions-highlight {
  color: #007bff;
}

.filepond--drop-label.filepond--drop-label label:hover {
  cursor: pointer;
}

.filepond--drop-label {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  color: #1e1e2d;
  height: 90px;
}

.filepond--root .filepond--drop-label {
  min-height: 0;
}

.filepond--browser.filepond--browser {
  bottom: 1.75em;
}

.filepond--file-info {
  font-weight: 400;
}

.filepond--item-panel {
  background: #1e1e2d;
}

.filepond--panel-root {
  background-color: #FFF;
}

.filepond--label-action {
  text-decoration: none;
  color: #007BFF;
}

.filepond--label-action:hover {
  color: #0e2e40;
}

.filepond--file-status {
  flex-shrink: 1;
}
.filepond--file-status .filepond--file-status-sub {
  display: none;
}

[data-filepond-item-state*=invalid] .filepond--item-panel, [data-filepond-item-state*=error] .filepond--item-panel {
  background-color: #c44e47 !important;
}

#multipartupload .h6 {
  text-transform: uppercase;
  font-weight: 10px !important;
  font-weight: 600;
}

.email-box,
.expiration-time-box,
.password-box {
  display: none;
}

#datetime {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #1e1e2d;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  appearance: none;
  background-color: #f5f9fc;
  border-color: transparent;
  border-radius: 0.5rem;
  border-width: 1px;
  font-weight: 700;
  line-height: 1rem;
  padding: 0.75rem 1rem;
  width: 100%;
  margin-bottom: 1.5rem;
}

.processing-image {
  display: none;
}

/* -------------------------------------------- */
/* 		Radio Button Checkboxes
/* -------------------------------------------- */
#storage-type-radio {
  display: inline-flex;
}
#storage-type-radio .radio-control {
  display: inline-flex;
}
#storage-type-radio .radio-control {
  display: inline-flex;
}
#storage-type-radio .radio-control:hover > .label-control {
  color: #007BFF;
  cursor: pointer;
}
#storage-type-radio .input-control {
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #f5f9fc;
  color: #007BFF;
  top: 5px;
  height: 15px;
  width: 15px;
  border: 0;
  cursor: pointer;
  outline: none;
  transition: all 0.2s;
  border-radius: 3px;
}
#storage-type-radio .input-control:checked::before {
  position: absolute;
  font: 9px/1 "Open Sans", sans-serif;
  left: 5px;
  top: 2px;
  content: "⅃";
  transform: rotate(40deg);
}
#storage-type-radio .input-control:checked {
  background-color: #007BFF;
  color: #FFF;
  transition: all 0.2s;
}
#storage-type-radio .input-control:checked + .label-control {
  color: #007BFF;
}
#storage-type-radio .label-control {
  margin-left: 5px;
  margin-bottom: 0;
  margin-right: 1rem;
  padding-top: 3px;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.8;
}
#storage-type-radio .label-control:hover {
  color: #007BFF;
}

/* -------------------------------------------- */
/* 		Upload Progress Bar
/* -------------------------------------------- */
#upload-status .file-info {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}
#upload-status .file-info span {
  font-weight: 700;
}
#upload-status .status {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 700;
}
#upload-status .progress .upload-bar {
  box-shadow: 0 3px 7px rgba(0, 123, 255, 0.5) !important;
  border-radius: 10px !important;
}
#upload-status .progress .progress-statistics {
  position: absolute;
  left: 50%;
  font-weight: 800;
  color: white;
  font-family: "Poppins", sans-serif;
}

/* -------------------------------------------- */
/* 		Frontend FilePond
/* -------------------------------------------- */
#settings {
  position: absolute;
  z-index: 100;
  right: 1rem;
  cursor: pointer;
}
#settings:hover, #settings:focus {
  color: #007BFF;
}

/* ==========================================================================           
 *
 *    PRELOADER
 *
 * ========================================================================== */
#preloader {
  background-color: rgba(255, 255, 255, 0.7);
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden !important;
  right: 0;
  z-index: 999999;
}
#preloader img {
  text-align: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 40%;
  z-index: 99;
  margin: 0 auto;
}

/* ==========================================================================           
 *
 *    PRELOADER
 *
 * ========================================================================== */
#toast-container {
  margin-top: 4.2rem;
}
#toast-container .toast {
  width: 100%;
  font-size: 12px;
}
#toast-container .toast .toast-title {
  display: none;
}
#toast-container .toast .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}
#toast-container .toast .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}
#toast-container .toast .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}
#toast-container .toast .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}

/* ==========================================================================           
 *
 *    AI DASHBOARD
 *
 * ========================================================================== */
.text-shadow {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.favorite-templates-panel {
  min-height: 200px;
}

.internal-special-links:hover, .internal-special-links:focus {
  color: #1e1e2d !important;
  font-weight: 600;
}

.template {
  margin-top: -1.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.template .card-body {
  min-height: 155px;
}
.template:hover, .template:focus {
  transform: scale(1.05);
  z-index: 100;
}
.template .favorite {
  border: 2px solid #FF9D00;
  background-color: #FEFCE8;
}
.template .professional {
  border: 2px solid #AB54EB;
  background-color: #F6E9FF;
}
.template .template-icon i.main-icon {
  font-size: 16px;
  padding: 7px;
  background: #FFF4DF;
  color: #FF9D00;
  border-radius: 5px;
}
.template .template-icon i.blog-icon {
  font-size: 16px;
  padding: 7px;
  background: #E1F0FF;
  color: #007bff;
  border-radius: 5px;
}
.template .template-icon i.web-icon {
  font-size: 16px;
  padding: 7px;
  background: rgba(0, 188, 126, 0.1);
  color: #00bc7e;
  border-radius: 5px;
}
.template .template-icon i.social-icon {
  font-size: 16px;
  padding: 7px;
  background: rgb(235, 232, 255);
  color: rgb(81, 56, 238);
  border-radius: 5px;
}
.template .template-icon i.other-icon {
  font-size: 16px;
  padding: 7px;
  background: #D1D3E0;
  color: #1e1e2d;
  border-radius: 5px;
}
.template .template-icon i.star {
  font-size: 16px;
  padding-top: 7px;
  color: #FF9D00;
}
.template .template-icon i.fa-stars {
  font-size: 18px;
}
.template a {
  position: relative;
  right: 27px;
  top: 50px;
  float: right;
  z-index: 200;
}
.template a i.star {
  font-size: 16px;
  padding-top: 7px;
  color: #FF9D00;
}
.template a i.fa-stars {
  font-size: 18px;
}
.template .btn-yellow {
  line-height: 1;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 35px;
  color: #fff !important;
  background-color: #FF9D00;
  border-color: #FF9D00;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.template-edit i.main-icon {
  font-size: 16px;
  padding: 7px;
  background: #FFF4DF;
  color: #FF9D00;
  border-radius: 5px;
}
.template-edit i.blog-icon {
  font-size: 16px;
  padding: 7px;
  background: #E1F0FF;
  color: #007bff;
  border-radius: 5px;
}
.template-edit i.web-icon {
  font-size: 16px;
  padding: 7px;
  background: rgba(0, 188, 126, 0.1);
  color: #00bc7e;
  border-radius: 5px;
}
.template-edit i.social-icon {
  font-size: 16px;
  padding: 7px;
  background: rgb(235, 232, 255);
  color: rgb(81, 56, 238);
  border-radius: 5px;
}
.template-edit i.other-icon {
  font-size: 16px;
  padding: 7px;
  background: #D1D3E0;
  color: #1e1e2d;
  border-radius: 5px;
}

/* ==========================================================================           
 *
 *    TEMPLATES
 *
 * ========================================================================== */
.template-view .favorite {
  border: 2px solid #FF9D00;
  background-color: #FEFCE8;
}
.template-view .template-icon i.main-icon {
  font-size: 12px;
  padding: 7px;
  background: #FFF4DF;
  color: #FF9D00;
  border-radius: 5px;
}
.template-view .template-icon i.green-icon {
  font-size: 12px;
  padding: 7px;
  background: rgba(0, 188, 126, 0.1);
  color: #00bc7e;
  border-radius: 5px;
}
.template-view .template-icon i.web-icon {
  font-size: 16px;
  padding: 7px;
  background: rgba(0, 188, 126, 0.1);
  color: #00bc7e;
  border-radius: 5px;
}
.template-view .template-icon i.blog-icon {
  font-size: 16px;
  padding: 7px;
  background: #E1F0FF;
  color: #007bff;
  border-radius: 5px;
}
.template-view .template-icon i.social-icon {
  font-size: 16px;
  padding: 7px;
  background: rgb(235, 232, 255);
  color: rgb(81, 56, 238);
  border-radius: 5px;
}
.template-view .template-icon i.other-icon {
  font-size: 16px;
  padding: 7px;
  background: #D1D3E0;
  color: #1e1e2d;
  border-radius: 5px;
}
.template-view .template-icon i.star {
  font-size: 14px;
  padding: 7px;
  color: #FF9D00;
}
.template-view .template-icon i.fa-stars {
  padding-top: 3px !important;
  font-size: 16px;
}
.template-view .template-icon a {
  position: absolute;
  right: 17px;
  cursor: pointer;
}
.template-view .btn-yellow {
  line-height: 1;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 35px;
  color: #fff !important;
  background-color: #FF9D00;
  border-color: #FF9D00;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}

.awselect-icon-style {
  width: auto !important;
}
.awselect-icon-style i.main-icon {
  font-size: 16px;
  padding: 7px;
  background: #FFF4DF;
  color: #FF9D00;
  border-radius: 5px;
}
.awselect-icon-style i.blog-icon {
  font-size: 16px;
  padding: 7px;
  background: #E1F0FF;
  color: #007bff;
  border-radius: 5px;
}
.awselect-icon-style i.web-icon {
  font-size: 16px;
  padding: 7px;
  background: rgba(0, 188, 126, 0.1);
  color: #00bc7e;
  border-radius: 5px;
}
.awselect-icon-style i.social-icon {
  font-size: 16px;
  padding: 7px;
  background: rgb(235, 232, 255);
  color: rgb(81, 56, 238);
  border-radius: 5px;
}
.awselect-icon-style i.other-icon {
  font-size: 16px;
  padding: 7px;
  background: #D1D3E0;
  color: #1e1e2d;
  border-radius: 5px;
}

#template-output {
  min-height: 700px;
}

#awselect_project {
  margin-bottom: 0;
}

#template-textarea .richText {
  border: none;
}
#template-textarea .richText .richText-editor {
  border: none !important;
  padding: 20px 10px;
  height: 500px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
}
#template-textarea .richText .richText-toolbar {
  border: none;
  background-color: #FFF;
}
#template-textarea .richText .richText-toolbar .richText-btn,
#template-textarea .richText .richText-toolbar .richText-undo,
#template-textarea .richText .richText-toolbar .richText-redo {
  border: none !important;
  font-size: 14px;
  padding: 10px;
}
#template-textarea .richText .richText-toolbar .richText-btn:hover, #template-textarea .richText .richText-toolbar .richText-btn:focus,
#template-textarea .richText .richText-toolbar .richText-undo:hover,
#template-textarea .richText .richText-toolbar .richText-undo:focus,
#template-textarea .richText .richText-toolbar .richText-redo:hover,
#template-textarea .richText .richText-toolbar .richText-redo:focus {
  color: #007BFF;
}
#template-textarea .richText .richText-toolbar .richText-help {
  display: none;
}

#template-input .input-box .form-control {
  font-weight: normal;
}
#template-input .awselect .front_face .placeholder,
#template-input .awselect .front_face .current_value {
  font-weight: normal !important;
}

.create-project {
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  border-radius: 0.5rem;
  background-color: #007BFF;
  color: #FFF;
}
.create-project:hover {
  cursor: pointer;
  background-color: #1e1e2d;
  color: #FFF;
}

.jQTAreaExt {
  margin-bottom: 0;
  height: 2px;
  background-color: #007BFF;
}

.jQTAreaCount,
.jQTAreaValue {
  font-style: normal;
}

.character-counter {
  font-style: normal;
  font-size: 10px;
  color: #728096;
  float: right;
}

.form-text {
  position: absolute;
  right: 1rem;
  top: 0;
  font-size: 10px;
  margin-top: 0;
}

.avoid-clicks {
  pointer-events: none;
  cursor: not-allowed !important;
  opacity: 0.4 !important;
}

#side-word-notification {
  background: #F5F9FC;
  padding: 5px 20px;
  border-radius: 15px;
}

#balance-status {
  background: #F5F9FC;
  padding: 8px;
  border-radius: 0.5rem;
}

/* ==========================================================================           
 *
 *    TEMPLATES
 *
 * ========================================================================== */
.template-view .template-icon i.email-icon {
  font-size: 12px;
  padding: 7px;
  background: #F8D7DA;
  color: #B02A37;
  border-radius: 5px;
}
.template-view .template-icon i.video-icon {
  font-size: 12px;
  padding: 7px;
  background: #CFF4FC;
  color: #087990;
  border-radius: 5px;
}

.template .template-icon i.email-icon {
  font-size: 12px;
  padding: 7px;
  background: #F8D7DA;
  color: #B02A37;
  border-radius: 5px;
}
.template .template-icon i.video-icon {
  font-size: 12px;
  padding: 7px;
  background: #CFF4FC;
  color: #087990;
  border-radius: 5px;
}

.awselect-icon-style i.email-icon {
  font-size: 16px;
  padding: 7px;
  background: #F8D7DA;
  color: #B02A37;
  border-radius: 5px;
}
.awselect-icon-style i.video-icon {
  font-size: 12px;
  padding: 7px;
  background: #CFF4FC;
  color: #087990;
  border-radius: 5px;
}

.mt-24 {
  margin-top: 2.4rem !important;
}

#awselect_template-selection {
  margin-top: 0.25rem !important;
  margin-bottom: 0 !important;
  box-shadow: none;
  width: 250px;
}
#awselect_template-selection .front_face .placeholder {
  font-weight: 400 !important;
  text-align: center;
}

#awselect_template-selection .front_face > .bg {
  background-color: #FFF !important;
}

.table-action-buttons-big {
  line-height: 2.4 !important;
  font-size: 16px !important;
  width: 40px !important;
  height: 40px !important;
}

#template-buttons-group #save-button i {
  background: #007BFF !important;
  color: #FFF !important;
}
#template-buttons-group #save-button i:hover, #template-buttons-group #save-button i:focus {
  background: #1e1e2d !important;
}

i.main-icon {
  font-size: 16px;
  padding: 7px;
  background: #FFF4DF;
  color: #FF9D00;
  border-radius: 5px;
}

i.blog-icon {
  font-size: 16px;
  padding: 7px;
  background: #E1F0FF;
  color: #007bff;
  border-radius: 5px;
}

i.web-icon {
  font-size: 16px;
  padding: 7px;
  background: rgba(0, 188, 126, 0.1);
  color: #00bc7e;
  border-radius: 5px;
}

i.social-icon {
  font-size: 16px;
  padding: 7px;
  background: rgb(235, 232, 255);
  color: rgb(81, 56, 238);
  border-radius: 5px;
}

i.other-icon {
  font-size: 16px;
  padding: 7px;
  background: #D1D3E0;
  color: #1e1e2d;
  border-radius: 5px;
}

i.email-icon {
  font-size: 12px;
  padding: 7px;
  background: #F8D7DA;
  color: #B02A37;
  border-radius: 5px;
}

i.video-icon {
  font-size: 12px;
  padding: 7px;
  background: #CFF4FC;
  color: #087990;
  border-radius: 5px;
}

.category-blog {
  background: #E1F0FF;
  color: #007bff;
}

.category-video {
  background: #CFF4FC;
  color: #087990;
}

.category-email {
  background: #F8D7DA;
  color: #B02A37;
}

.category-other {
  background: #D1D3E0;
  color: #1e1e2d;
}

.category-social {
  background: rgb(235, 232, 255);
  color: rgb(81, 56, 238);
}

.category-web {
  background: rgba(0, 188, 126, 0.1);
  color: #00bc7e;
}

.category-main {
  background: #FFF4DF;
  color: #FF9D00;
}

.favorite-templates-panel .nav-tabs {
  border-bottom: none;
}
.favorite-templates-panel .nav-tabs .nav-link {
  font-size: 12px;
  margin-right: 0.7rem;
  min-width: 80px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 35px;
  color: #1e1e2d !important;
  background-color: #FFF;
  border: none;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}
.favorite-templates-panel .nav-tabs .nav-link:hover {
  color: #fff !important;
  background-color: #1e1e2d;
  border-color: #1e1e2d;
}
.favorite-templates-panel .nav-tabs .nav-link:focus, .favorite-templates-panel .nav-tabs .nav-link.focus {
  background-color: #007bFF;
  border-color: #007bFF;
}
.favorite-templates-panel .nav-tabs .nav-link.active {
  color: #FFF !important;
  background-color: #1e1e2d;
}

/* ==========================================================================           
 *
 *    CUSTOM TEMPLATES
 *
 * ========================================================================== */
.field input, .field select {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #1e1e2d;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  appearance: none;
  background-color: #f5f9fc;
  border-color: transparent;
  border-radius: 0.5rem;
  border-width: 1px;
  font-weight: 400;
  line-height: 1rem;
  padding: 0.75rem 1rem;
}
.field input {
  margin-right: 1rem;
}
.field input:hover, .field input:focus, .field select:hover, .field select:focus {
  border-color: #007BFF;
  box-shadow: none;
  transition: all 0.2s;
}
.field span {
  border-top-right-radius: 35px !important;
  border-bottom-right-radius: 35px !important;
}
.field span i {
  line-height: 2.6;
}
.field span:last-child {
  display: none;
  padding: 12px;
}

/* ==========================================================================           
 *
 *    DOCUMENTS
 *
 * ========================================================================== */
.category-text {
  background: #FFF4DF;
  color: #FF9D00;
}

#createButton {
  position: absolute;
  right: 1rem;
}

/* ==========================================================================           
 *
 *    VOICEOVER
 *
 * ========================================================================== */
.special-buttons .btn-special {
  background: #f5f9fc;
  border-radius: 0.5rem;
  font-size: 12px;
  margin-right: 1rem;
  font-weight: 600;
  width: 100%;
  height: 38px;
  box-shadow: none;
}
.special-buttons .btn-special:hover, .special-buttons .btn-special:focus {
  background: #007BFF;
  color: #fff;
}
.special-buttons .dropdown-toggle::after {
  vertical-align: middle;
}
.special-buttons .dropdown-menu {
  border-radius: 0;
  font-size: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: none;
  margin-top: 3px;
  width: 100%;
}
.special-buttons .dropdown-item {
  padding: 0.5rem 1.5rem;
}
.special-buttons .dropdown-item:hover, .special-buttons .dropdown-item:focus {
  background-color: #f5f9fc;
}

#textarea-outer-box {
  border: 1px solid #ebecf1;
  position: relative;
}
#textarea-outer-box .input-label {
  position: absolute;
  top: 0;
  padding-left: 5px;
  font-size: 14px;
  color: #728096;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#textarea-outer-box .input-label::after {
  content: "";
  position: absolute;
}
#textarea-outer-box .input-label-content {
  position: relative;
  padding: 0 5px;
  color: #728096;
  background-color: #FFF;
  opacity: 1;
  display: inline-block;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  top: -7px;
  left: 15px;
  font-size: 10px;
}
#textarea-outer-box .input-label-main {
  top: -13px;
}
#textarea-outer-box #textarea-container {
  min-height: 300px;
  background: #f5f9fc;
  height: auto;
  padding-bottom: 0.7rem;
}
#textarea-outer-box #textarea-container .textarea-row {
  display: flex;
  padding: 10px 15px;
}
#textarea-outer-box #textarea-container .textarea-row .textarea-voice {
  padding-left: 1rem;
}
#textarea-outer-box #textarea-container .textarea-row .textarea-voice div {
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  height: 30px;
  width: 30px;
  clear: both;
  border-radius: 100%;
  position: relative;
}
#textarea-outer-box #textarea-container .textarea-row .textarea-voice div img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}
#textarea-outer-box #textarea-container .textarea-row .textarea-text {
  width: 100%;
  margin: 0 2rem;
}
#textarea-outer-box #textarea-container .textarea-row .textarea-text textarea {
  min-height: 37px;
}
#textarea-outer-box #textarea-container .textarea-row .textarea-text .form-control {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  border-radius: 0px;
  padding: 0.6rem 1rem;
  color: #1e1e2d;
  appearance: none;
  outline: none;
  box-shadow: none;
  border: 0 solid transparent;
}
#textarea-outer-box #textarea-container .textarea-row .textarea-text .form-control:hover, #textarea-outer-box #textarea-container .textarea-row .textarea-text .form-control:focus {
  background: #fff;
  border: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  border-color: transparent;
}
#textarea-outer-box #textarea-container .textarea-row .textarea-actions .textarea-buttons {
  display: flex;
  margin-right: 0.5rem;
}
#textarea-outer-box #textarea-container .textarea-row .textarea-actions .textarea-buttons .buttons {
  border: none;
  box-shadow: none;
  margin-right: 0.5rem;
  transition: all 0.1s ease;
  text-shadow: 0px 1px 2px rgba(50, 50, 50, 0.2);
}
#textarea-outer-box #textarea-container .textarea-row .textarea-actions .textarea-buttons .synthesizeText:hover, #textarea-outer-box #textarea-container .textarea-row .textarea-actions .textarea-buttons .synthesizeText:focus {
  background-color: #007BFF;
  color: white;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}
#textarea-outer-box #textarea-container .textarea-row .textarea-actions .textarea-buttons .addPause:hover, #textarea-outer-box #textarea-container .textarea-row .textarea-actions .textarea-buttons .addPause:focus {
  background-color: #FF9D00;
  color: white;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}
#textarea-outer-box #textarea-container .textarea-row .textarea-actions .textarea-buttons .deleteText:hover, #textarea-outer-box #textarea-container .textarea-row .textarea-actions .textarea-buttons .deleteText:focus {
  background-color: red;
  color: white;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}
#textarea-outer-box #textarea-container #addTextRow {
  transition: all 0.05s ease-in-out;
  text-shadow: 0px 1px 3px rgba(50, 50, 50, 0.2);
}
#textarea-outer-box #textarea-container #addTextRow:hover, #textarea-outer-box #textarea-container #addTextRow:focus {
  background: #007BFF;
  color: #FFF;
  border-radius: 50%;
  padding-bottom: 8px;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}

#upload-file button {
  background-color: #007BFF;
  color: #FFF;
}
#upload-file button:hover, #upload-file button:focus {
  background-color: #1e1e2d;
}

#textarea-box .form-control {
  font-size: 12px !important;
}

#textarea-box .input-label {
  position: absolute;
  top: 0;
  padding-left: 5px;
  font-size: 14px;
  color: #728096;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#textarea-box .input-label::after {
  content: "";
  position: absolute;
}

#textarea-box .input-label-content {
  position: relative;
  padding: 0 5px;
  color: #728096;
  background-color: #FFF;
  opacity: 1;
  display: inline-block;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  top: -13px;
  left: 15px;
  font-size: 10px;
}

.input-label-content-modal {
  top: -7px !important;
}

/* -------------------------------------------- */
/* 		Textarea Counter and Button
/* -------------------------------------------- */
.jQTAreaExt {
  margin-bottom: 0;
  height: 2px;
  background-color: #007BFF;
}

#textarea-settings {
  padding: 5px 0 14px;
}

#textarea-settings .character-counter {
  float: left;
}

#textarea-settings .jQTAreaCount,
#textarea-settings .jQTAreaValue {
  font-style: normal;
}

#textarea-settings .clear-button {
  float: right;
  font-size: 10px;
}

#textarea-settings .clear-button button {
  font-size: 10px;
  color: #728096;
  padding-right: 0;
  background: none;
  border: none;
  outline: none;
  text-decoration: none;
}

#textarea-settings .clear-button button:hover {
  color: #007BFF;
}

#textarea-settings .character-counter {
  font-style: normal;
  font-size: 10px;
  color: #728096;
}

.main-action-button {
  width: 142px;
  font-size: 12px !important;
}

#processing {
  display: none;
}

/* -------------------------------------------- */
/* 		Radio Button Checkboxes
/* -------------------------------------------- */
#audio-format,
#voice-types {
  display: inline-flex;
}
#audio-format .radio-control,
#voice-types .radio-control {
  display: inline-flex;
}
#audio-format .radio-control,
#voice-types .radio-control {
  display: inline-flex;
}
#audio-format .radio-control:hover > .label-control,
#voice-types .radio-control:hover > .label-control {
  color: #007BFF;
  cursor: pointer;
}
#audio-format .input-control,
#voice-types .input-control {
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #f5f9fc;
  color: #007BFF;
  top: 5px;
  height: 15px;
  width: 15px;
  border: 0;
  cursor: pointer;
  outline: none;
  transition: all 0.2s;
  border-radius: 3px;
}
#audio-format .input-control:checked::before,
#voice-types .input-control:checked::before {
  position: absolute;
  font: 9px/1 "Open Sans", sans-serif;
  left: 5px;
  top: 2px;
  content: "⅃";
  transform: rotate(40deg);
}
#audio-format .input-control:checked,
#voice-types .input-control:checked {
  background-color: #007BFF;
  color: #FFF;
  transition: all 0.2s;
}
#audio-format .input-control:checked + .label-control,
#voice-types .input-control:checked + .label-control {
  color: #007BFF;
}
#audio-format .label-control,
#voice-types .label-control {
  margin-left: 5px;
  margin-bottom: 0;
  margin-right: 1rem;
  padding-top: 3px;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.8;
}
#audio-format .label-control:hover,
#voice-types .label-control:hover {
  color: #007BFF;
}

#voice-types .label-control {
  padding-top: 2px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

#mp3-format,
#ogg-format,
#webm-format,
#wav-format {
  line-height: 1;
}
#mp3-format .label-control,
#ogg-format .label-control,
#webm-format .label-control,
#wav-format .label-control {
  line-height: 1.8 !important;
}

.block-radio {
  cursor: not-allowed !important;
}

.block-label {
  color: #708096 !important;
}
.block-label:hover, .block-label:focus {
  cursor: not-allowed !important;
}

#tts-awselect .awselect {
  margin-bottom: 0.7rem;
}

/* ==========================================================================           
 *
 *    GREEN AUDIO PLAYER
 *
 * ========================================================================== */
.voice-player {
  position: relative;
}
.voice-player img {
  position: absolute;
  padding: 0 1.5rem;
  border: 1px solid #EBECF2;
  border-radius: 20px;
  background: #fff;
  top: -15px;
  left: 75px;
}
.voice-player i {
  position: absolute;
  top: 27px;
  right: 21px;
}

.green-audio-player {
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  padding-left: 100px;
  padding-right: 25px;
  width: auto;
  min-width: auto;
  height: 70px;
}
.green-audio-player .slider .progress .pin {
  background-color: #1e1e2d;
}
.green-audio-player .controls span {
  color: #1e1e2d;
}
.green-audio-player .controls {
  color: #1e1e2d;
  font-size: 10px;
  margin-left: 20px;
}
.green-audio-player .controls a {
  cursor: pointer;
}
.green-audio-player .controls a:hover, .green-audio-player .controls a:focus {
  color: #007BFF;
}
.green-audio-player .slider .gap-progress .pin {
  background: #1e1e2d;
}
.green-audio-player .slider .gap-progress {
  background: #1e1e2d;
}

#controls a {
  cursor: pointer;
}
#controls a:hover, #controls a:focus {
  color: #007BFF;
}
#controls button {
  cursor: pointer;
}
#controls button:hover, #controls button:focus {
  color: #007BFF;
}

#user-result .green-audio-player {
  padding-left: 24px;
  padding-right: 24px;
  height: 50px;
}

.green-audio-player .play-pause-btn svg {
  width: 12px;
  margin-left: 0.5rem;
  margin-right: 0.2rem;
}

.green-audio-player .volume .volume__button svg {
  width: 16px;
}

.green-audio-player .download .download__link svg {
  width: 16px;
  margin-right: 0.3rem;
}

.green-audio-player .holder .play-pause-btn path {
  fill: #1e1e2d;
}

.green-audio-player .volume .volume__button path {
  fill: #1e1e2d;
}

.green-audio-player .volume .volume__button.open path {
  fill: #1e1e2d;
}

.green-audio-player .volume .volume-btn.open path {
  fill: #1e1e2d;
}

.green-audio-player .download .download__link path {
  fill: #1e1e2d;
}

.tooltip {
  font-size: 11px;
}

.tooltip-inner {
  padding: 5px 15px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 15px;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
}

.info-notification {
  padding: 3px 6.5px 3px 6.4px;
  background: #1e1e2d;
  color: white;
  font-size: 9.5px;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  cursor: pointer;
  margin-bottom: -7px;
}

.green-color {
  color: #007e08;
}

#listen-result-player .green-audio-player {
  padding-left: 10px !important;
  padding-right: 10px !important;
  height: 56px !important;
  box-shadow: none !important;
}

#listenModal .modal-footer {
  justify-content: center;
}

#listenModal .modal-body {
  padding-bottom: 10px;
}

#notificationModal .modal-title {
  line-height: 1.7;
  text-transform: none;
}

#waveform-box {
  display: none;
}

#return-sound {
  position: absolute;
  right: 0;
  margin-right: 1rem;
}

#list-music {
  line-height: 2;
}

.voice-neural {
  background: #E1F0FF;
  color: #007BFF;
}

.rounded-circle {
  border-radius: 0 !important;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

#template-output-transcript {
  min-height: 400px;
}

.result-play {
  color: #1e1e2d;
}
.result-play:hover {
  color: #007BFF;
}

.task-transcribe {
  background: #E1F0FF;
  color: #007bff;
}

.task-translate {
  background: #FFF4DF;
  color: #FF9D00;
}

/* ==========================================================================           
 *
 *    VIEW TRANSCRIBE RESULT
 *
 * ========================================================================== */
.control-button {
  height: 30px;
  cursor: pointer;
  filter: drop-shadow(1px 1px 1px #7B7B7B);
}

.control-button-left {
  float: left !important;
  cursor: pointer;
  filter: drop-shadow(1px 1px 1px #7B7B7B);
}

.control-button-right {
  float: right !important;
}

#transcript-table .transcript-table-column {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
}

#transcript {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #f5f9fc;
}

.card-title-inner {
  font-weight: 700;
  font-size: 14px;
}

#create-category {
  position: absolute;
  right: 1.5rem;
}

/* ==========================================================================           
 *
 *    CHAT SYSTEM
 *
 * ========================================================================== */
#chat-system {
  min-height: 400px;
  font-size: 13px;
}
#chat-system .chat-button {
  padding: 0.75rem 15px !important;
  z-index: 100;
}
#chat-system #chat-container {
  max-height: 550px;
  height: 550px;
  overflow-y: scroll;
  padding-left: 1rem;
  padding-right: 1rem;
}
#chat-system .msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
}
#chat-system .msg:last-of-type {
  margin: 0;
}
#chat-system .message-img {
  min-width: 40px;
  min-height: 40px;
  margin-right: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin-bottom: auto;
}
#chat-system .message-bubble {
  padding: 10px 15px;
  border-radius: 10px;
  background: #f5f9fc;
  position: relative;
  height: auto;
}
#chat-system .left-msg .message-bubble:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #f5f9fc;
  left: -6px;
  right: auto;
  top: 15px;
  position: absolute;
}
#chat-system .right-msg .message-bubble:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #1e1e2d;
  left: auto;
  right: -6px;
  transform: rotate(180deg);
  top: 15px;
  position: absolute;
}
#chat-system .msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
#chat-system .msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
#chat-system .msg-info-time {
  font-size: 0.85em;
}
#chat-system .right-msg {
  flex-direction: row-reverse;
}
#chat-system .right-msg .message-bubble {
  background: #16171c;
  color: #fff;
}
#chat-system .right-msg .message-img {
  margin: 0 0 0 15px;
  margin-bottom: auto;
}
#chat-system #clear-button {
  position: absolute;
  right: 0;
  margin-right: 1rem;
}

.mt-12 {
  margin-top: 1.2rem !important;
}

/* ==========================================================================           
 *
 *    PROMOCODE
 *
 * ========================================================================== */
.promocode-field {
  padding: 0.55rem 1rem !important;
  margin-right: 80px;
}

.payment-lifetime {
  background: rgba(0, 188, 126, 0.1);
  color: #00bc7e;
}

#total_discount {
  color: #00bc7e;
}

/* ==========================================================================           
 *
 *    CODE
 *
 * ========================================================================== */
#code-textarea {
  display: none;
}

#code-textarea,
#code-result,
#code {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
#code-textarea p,
#code-result p,
#code p {
  font-size: 14px;
}
#code-textarea pre,
#code-result pre,
#code pre {
  padding: 1.5rem;
  border-radius: 10px;
  font-size: 14px;
}
#code-textarea pre code,
#code-result pre code,
#code pre code {
  font-family: source-code-pro, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
  font-size: 14px;
  line-height: 1.3rem;
}

#code {
  padding-top: 0;
  padding-bottom: 0;
}

#generating-status,
#generating-message {
  position: absolute;
  top: 41%;
  left: 41%;
  font-size: 14px;
}

#generating-status {
  display: none;
}

.copy-code {
  float: right;
  background: none;
  border: none;
  color: white;
  font-size: 12px;
}
.copy-code:hover, .copy-code:focus {
  color: #007BFF;
}

/* ==========================================================================           
*
*    CHAT
*
* ========================================================================== */
.chat-boxes {
  position: relative;
  margin-top: -1.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.chat-boxes .card-body {
  min-height: 155px;
}
.chat-boxes:hover, .chat-boxes:focus {
  transform: scale(1.05);
  z-index: 100;
}
.chat-boxes .favorite {
  border: 2px solid #FF9D00;
  background-color: #FEFCE8;
}
.chat-boxes .professional {
  border: 2px solid #AB54EB;
  background-color: #F6E9FF;
}
.chat-boxes a {
  position: relative;
  top: 45px;
  margin-left: 1rem;
  float: left;
  z-index: 200;
}
.chat-boxes a i.star {
  font-size: 16px;
  padding-top: 7px;
  color: #FF9D00;
}
.chat-boxes a i.fa-stars {
  font-size: 18px;
}
.chat-boxes .btn-yellow {
  line-height: 1;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 35px;
  color: #fff !important;
  background-color: #FF9D00;
  border-color: #FF9D00;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  position: absolute;
  right: 20px;
  top: 50px;
  z-index: 10;
}
.chat-boxes .btn-free {
  line-height: 1;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 35px;
  color: #fff !important;
  background-color: #1e1e2d;
  border-color: #1e1e2d;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  position: absolute;
  right: 20px;
  top: 50px;
  z-index: 10;
}
.chat-boxes .btn-premium {
  line-height: 1;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 35px;
  color: #fff !important;
  background-color: #1e1e2d;
  border-color: #1e1e2d;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  position: absolute;
  right: 20px;
  top: 50px;
  z-index: 10;
}
.chat-boxes .btn-pro {
  line-height: 1;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 35px;
  color: #fff !important;
  background-color: #007BFF;
  border-color: #007BFF;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  position: absolute;
  right: 20px;
  top: 50px;
  z-index: 10;
}
.chat-boxes .premium {
  border: 2px solid #AB54EB;
  background-color: #F6E9FF;
}
.chat-boxes .professional {
  border: 2px solid #007BFF;
  background-color: #E1F0FF;
}
.chat-boxes .widget-user-image {
  height: 130px;
  width: 130px;
}

:root {
  --sidebar-width: 300px;
  --window-content-width: calc(100% - var(--sidebar-width));
}

.chat-main-container {
  min-width: 800px;
  min-height: 500px;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  width: 100vw;
  height: auto;
  font-family: "Lato", sans-serif;
  position: relative;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding-left: 0;
  padding-right: 0;
}
.chat-main-container #expand {
  display: none;
}
.chat-main-container .chat-sidebar-container {
  top: 0;
  width: var(--sidebar-width);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ebecf1;
  position: relative;
  transition: width 0.05s ease;
}
.chat-main-container .chat-sidebar-container .chat-sidebar-messages {
  flex: 1 1;
  overflow: auto;
  overflow-x: hidden;
  padding: 20px;
}
.chat-main-container .chat-sidebar-container .chat-sidebar-messages .chat-sidebar-message {
  padding: 10px 14px;
  background-color: #F5F9FC;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid #F5F9FC;
  transition: background-color 0.3s ease;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  position: relative;
}
.chat-main-container .chat-sidebar-container .chat-sidebar-messages .chat-sidebar-message .chat-title {
  font-size: 13px;
  font-weight: 600;
  display: block;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat-main-container .chat-sidebar-container .chat-sidebar-messages .chat-sidebar-message .chat-info {
  display: flex;
  justify-content: space-between;
  color: #a6a6a6;
  font-size: 12px;
  margin-top: 8px;
}
.chat-main-container .chat-sidebar-container .chat-sidebar-messages .chat-sidebar-message .chat-actions {
  position: absolute;
  top: 10px;
  right: -20px;
  transition: all 0.3s ease;
  opacity: 0;
  cursor: pointer;
  z-index: 100;
  color: black;
}
.chat-main-container .chat-sidebar-container .chat-sidebar-messages .chat-sidebar-message:hover, .chat-main-container .chat-sidebar-container .chat-sidebar-messages .chat-sidebar-message:focus {
  border: 1px solid #EDF8FD;
  background-color: #FFF;
}
.chat-main-container .chat-sidebar-container .chat-sidebar-messages .chat-sidebar-message:hover .chat-actions, .chat-main-container .chat-sidebar-container .chat-sidebar-messages .chat-sidebar-message:focus .chat-actions {
  opacity: 0.5;
  right: 10px;
}
.chat-main-container .chat-sidebar-container .chat-sidebar-messages .chat-sidebar-message:hover .chat-actions:hover, .chat-main-container .chat-sidebar-container .chat-sidebar-messages .chat-sidebar-message:hover .chat-actions:focus, .chat-main-container .chat-sidebar-container .chat-sidebar-messages .chat-sidebar-message:focus .chat-actions:hover, .chat-main-container .chat-sidebar-container .chat-sidebar-messages .chat-sidebar-message:focus .chat-actions:focus {
  opacity: 1;
}
.chat-main-container .chat-sidebar-container .chat-sidebar-messages .selected-message {
  border: 1px solid #EDF8FD;
  background-color: #FFF;
}
.chat-main-container .chat-message-container {
  width: calc(100% - var(--sidebar-width));
  height: 100%;
  display: flex;
  flex-direction: column;
}
.chat-main-container .chat-message-container .chat-avatar {
  border-radius: 50%;
  height: 44px;
  width: 44px;
  clear: both;
  display: block;
  background: #E1F0FF;
  position: relative;
}
.chat-main-container .card-footer {
  border-top: 1px solid #ebecf1 !important;
  min-height: 75px;
  height: 75px;
}

::-webkit-scrollbar {
  width: 2px !important;
  border: 1px solid #EDF8FD !important;
}

.template .btn-free {
  line-height: 1;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 35px;
  color: #fff !important;
  background-color: #1e1e2d;
  border-color: #1e1e2d;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 10;
}
.template .btn-premium {
  line-height: 1;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 35px;
  color: #fff !important;
  background-color: #1e1e2d;
  border-color: #1e1e2d;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 10;
}
.template .btn-pro {
  line-height: 1;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 35px;
  color: #fff !important;
  background-color: #007BFF;
  border-color: #007BFF;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 10;
}
.template .premium {
  border: 2px solid #AB54EB;
  background-color: #F6E9FF;
}
.template .professional {
  border: 2px solid #007BFF;
  background-color: #E1F0FF;
}

.plan-premium {
  color: #AB54EB;
  background: #F6E9FF;
}

.extend {
  left: 0 !important;
  transition: all 0.5s ease !important;
}

.chat-logo-image {
  height: 80px;
  width: 80px;
  margin: 0 auto;
  display: block;
  border-radius: 100%;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  position: relative;
}

.modal-header .btn-close {
  font-size: 12px;
}

.modal-body .browse-file,
#create-new-chat .browse-file {
  margin-right: 80px;
}

/* ==========================================================================           
 *
 *    BACKEND RESPONSIVE STYLES
 *
 * ========================================================================== */
@media (max-width: 768px) {
  .header-brand-img {
    display: none;
  }
  .header-brand {
    min-width: 30px !important;
  }
  .app-header .header-brand-img.mobile-logo {
    display: block !important;
    height: 3rem;
  }
  #search-bar {
    display: none;
  }
  .header-notify {
    display: none;
  }
  .header-expand {
    display: none;
  }
  #special-buttons {
    display: inline-block !important;
  }
  #special-buttons .dropdown {
    margin-bottom: 1rem;
  }
  .pl-minify {
    padding-left: 12px !important;
  }
  .pr-minify {
    padding-right: 12px !important;
  }
  #card-minify {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  #card-body-minify {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  #database-backup {
    overflow-x: scroll;
  }
  #database-backup th, #database-backup td {
    min-width: 200px;
  }
  .prices-responsive {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  #openai-form > div.row {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-top: 2rem !important;
  }
  #openai-form > div.row > div {
    padding-left: 0;
    padding-right: 0;
  }
  #openai-form .create-project {
    display: none;
  }
  #openai-form #template-output .card-title {
    font-size: 12px;
  }
  #awselect_template-selection {
    display: none !important;
  }
  .favorite-templates-panel .nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    padding-bottom: 10px;
  }
  .favorite-templates-panel .nav-tabs .nav-link {
    white-space: nowrap;
  }
  .message-img {
    display: none !important;
  }
  #generating-message {
    left: 18%;
  }
  #generating-status {
    left: 12%;
  }
  #openai-form > div.row {
    padding-top: 0 !important;
  }
  #balance-status span {
    padding-left: 0 !important;
  }
  .chat-main-container .chat-sidebar-container {
    position: absolute;
    left: -100%;
    z-index: 1000;
    height: 100%;
    max-height: 100%;
    background-color: white;
    transition: all 0.5s ease;
  }
  .chat-main-container .chat-message-container {
    width: inherit !important;
  }
  .chat-main-container {
    min-width: inherit !important;
  }
  .chat-main-container #expand {
    display: block !important;
  }
  #export-txt,
#export-word,
#export-pdf {
    display: none;
  }
  .chat-sidebar-container .extend {
    left: 0 !important;
    transition: all 0.5s ease !important;
  }
}
/* ==========================================================================           
 *
 *    FRONTEND RESPONSIVE STYLES
 *
 * ========================================================================== */
@media (max-width: 768px) {
  html, body {
    width: auto !important;
    overflow-x: hidden !important;
  }
  .fixed-top {
    position: relative !important;
  }
  form {
    width: 100% !important;
  }
  .upload-responsive {
    padding-left: auto !important;
    margin-left: auto !important;
  }
  .pl-8 {
    padding-left: 1rem !important;
  }
  #main-wrapper .text-container h1 {
    font-size: 30px !important;
  }
  #main-wrapper .text-container p {
    display: none !important;
    font-size: 14px !important;
  }
  #main-wrapper .text-container a {
    margin-top: 1.5rem;
  }
  #main-wrapper #special-image-margin {
    margin-top: 3rem !important;
  }
  #main-wrapper .frontend-buttons {
    margin-left: 0rem !important;
  }
  #features-wrapper {
    padding-top: 64px !important;
  }
  #solutions-list {
    text-align: center;
  }
  #contact-wrapper img {
    display: none;
  }
  #contact-wrapper form {
    width: 100%;
  }
  .central-banner {
    position: relative !important;
  }
  .copyright-left {
    float: none !important;
    text-align: center;
  }
  .copyright-right {
    display: none !important;
  }
  #back-to-top {
    display: none !important;
  }
  #footer-logo {
    margin-bottom: 2rem;
  }
  .central-banner .pl-9 {
    padding-left: 0 !important;
  }
  .central-banner .pr-9 {
    padding-right: 0 !important;
  }
  .central-banner h2 {
    font-size: 1.5rem;
  }
  .central-banner p {
    font-size: 12px;
  }
  #login-responsive .card-body {
    padding: 5rem 1.5rem !important;
  }
  #login-responsive #login-footer {
    position: relative;
    left: auto !important;
  }
  #login-background {
    display: none;
  }
  .nav-item {
    width: 100% !important;
  }
  #footer {
    padding-left: 3rem !important;
  }
  .frontend-body .navbar {
    padding: 0;
  }
  .frontend-body .navbar-collapse {
    background: #FFF;
    padding: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .frontend-body .navbar-nav li.nav-item a.nav-link {
    color: #1e1e2d !important;
  }
  .policy .card-body {
    padding: 4rem 1rem !important;
  }
  #blog-view-mobile .richText-editor {
    height: auto !important;
  }
  #voice-minify {
    padding-right: 13px !important;
  }
  .special-buttons {
    margin-bottom: 1rem;
  }
  #textarea-box {
    margin-bottom: 1rem !important;
  }
  .textarea-voice,
.textarea-actions {
    display: none !important;
  }
  .textarea-text {
    margin: 0 !important;
  }
  .textarea-row {
    padding: 10px 8px !important;
  }
  #tts-body-minify {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 1.5rem !important;
  }
  #synthesize-text-form {
    width: 100%;
  }
  #preview-box {
    display: none;
  }
  #special-buttons {
    display: inline-block !important;
  }
  #special-buttons .dropdown {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1480px) {
  #login-responsive .card-body {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}